import "./App.css";
import Objects from "./pages/Objects";

function App() {
  return (
    <>
      <Objects></Objects>
    </>
  );
}

export default App;
