import { IoCloseOutline } from "react-icons/io5";
import { BiZoomIn } from "react-icons/bi";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { useEffect, useState } from "react";

export default function DetailView({ selectedObj, closeModal }) {
  const [showZoomHint, setShowZoomHint] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [delay, setDelay] = useState(false);
  //console.log("selectedObj ", selectedObj)

  useEffect(() => {
    // needed to refresh InnerImageZoom component after object changed
    resetImageZoom();
  }, [selectedObj]);

  function OneInfoElement({ desc, content }) {
    return content !== "" ? (
      <div className="flex py-2">
        {desc}
        <div className="font-semibold pl-1">{content}</div>
      </div>
    ) : (
      ""
    );
  }

  const resetImageZoom = () => {
    setIsZoomed(false);
    setDelay(true);
    setTimeout(() => setDelay(false), 1);
  };

  const onZoomIn = (e) => {
    setIsZoomed(true);
    setShowZoomHint(false);
  };

  const onZoomOut = (e) => {
    setIsZoomed(false);
  };

  const onZoomIconClick = (e) => {
    console.log("ok zoom clicked");
    setShowZoomHint(true);
    e.preventDefault();
  };

  return (
    <div className="bg-white border-l-8 overflow-scroll relative w-1/2">
      <div className="sticky top-0 text-4xl cursor-pointer">
        <IoCloseOutline onClick={() => closeModal(selectedObj)} />
      </div>
      <div className="flex w-full place-items-center">
        <div className="pl-10 text-sm font-light w-1/2">
          <OneInfoElement
            desc="Raumverweis:"
            content={selectedObj.Raumverweis}
          />
          <OneInfoElement
            desc="Objektteppich Nr "
            content={selectedObj.NummerObjektteppich}
          />
          <OneInfoElement desc="Person: " content={selectedObj.Person} />
          <OneInfoElement
            desc="Lebensdaten: "
            content={selectedObj.Lebensdaten}
          />
        </div>
        <div className="relative w-1/2 h-1/4 pl-2">
          {!delay ? (
            <InnerImageZoom
              src={"./objects/" + selectedObj.DateinameBild}
              hideHint={true}
              afterZoomIn={onZoomIn}
              afterZoomOut={onZoomOut}
              hideCloseButton={true}
            />
          ) : (
            <img
              className="m-1"
              src={"./objects/" + selectedObj.DateinameBild}
              alt="objekt"
            />
          )}

          {!isZoomed && (
            <BiZoomIn
              className="absolute bottom-0 top-auto left-auto right-0 text-2xl bg-white"
              onClick={onZoomIconClick}
            />
          )}
          {isZoomed && (
            <IoCloseOutline
              className="absolute bottom-auto top-0 left-auto right-0 text-2xl bg-white"
              onClick={resetImageZoom}
            />
          )}

          {showZoomHint && (
            <div className="absolute bottom-0 top-auto left-0 right-auto text-sm font-light bg-white px-1 pt-1">
              Klicke zum Vergrössern direkt auf das Bild
            </div>
          )}
        </div>
      </div>
      <div className="pl-10 text-sm font-light ">
        {selectedObj.Infotext !== "" ? (
          <div className="py-4">{selectedObj.Infotext}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
