import { useState } from "react";
import DetailView from "../components/DetailView";
import ObjectList from "../components/ObjectList";

export default function Objects() {
  const [openmodal, setOpenmodal] = useState(false);
  const [detailViewImage, setDetailViewImage] = useState(false);
  const [selectedObj, setSelectedObj] = useState(false);

  const closeModal = (obj) => {
    (obj.DateinameBild === detailViewImage || !detailViewImage || !openmodal) &&
      setOpenmodal(!openmodal);
    setDetailViewImage(obj.DateinameBild);
    setSelectedObj(obj);
  };

  return (
    <div className="p-4 relative h-screen w-screen max-h-screen flex flex-col">
      {/*  <div className="text-3xl pb-6 w-full">Objektteppich</div> */}
      {/* <div className="py-6 w-full">Untertitel / Beschreibung</div>  */}
      <div className="flex overflow-y-scroll w-full">
        <ObjectList
          click={(obj) => {
            //   console.log("detailViewImage", detailViewImage)
            //   console.log("obj.DateinameBild", obj.DateinameBild)
            closeModal(obj);
          }}
          openmodal={openmodal}
          selectedObj={selectedObj}
        />
        {openmodal && selectedObj && (
          <DetailView selectedObj={selectedObj} closeModal={closeModal} />
        )}
      </div>
    </div>
  );
}
