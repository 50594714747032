import { createRef, useEffect, useRef } from "react";
import { csvData } from "../Helper/csvData";

export default function ObjectList({ click, openmodal, selectedObj }) {
  const selectedObjRef = useRef();
  const stylingAddon = openmodal
    ? "grid-cols-4 w-1/2 pr-2"
    : "grid-cols-8 w-full";

  useEffect(() => {
    selectedObjRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    if (!openmodal) {
      selectedObjRef.current = null;
    }
  }, [openmodal, selectedObjRef]);

  return (
    <ul
      className={
        "grid " +
        stylingAddon +
        " gap-4 place-items-center overflow-y-scroll overflow-x-hidden flex "
      }
    >
      {csvData.map((obj) => {
        const ref = createRef();

        const handleClick = () => {
          selectedObjRef.current = ref.current;
          console.log("scroll to ", ref);
        };
        return (
          <li key={obj.DateinameBild} ref={ref} className="p-1">
            <button
              className={
                " cursor-pointer transition transform hover:scale-110 " +
                (openmodal && selectedObj === obj ? "border-8 " : "")
              }
              onClick={() => {
                click(obj);
                handleClick(ref);
              }}
              key={obj.DateinameBild}
            >
              <img
                alt={obj.DateinameBild}
                className=""
                src={"./objects/" + obj.DateinameBild}
              />
            </button>
          </li>
        );
      })}
    </ul>
  );
}
