
export const csvData = [
    {
        "DateinameBild": "Herzog_Johannes.jpg",
        "Inventar Nr": "1938.06.16.S100",
        "Ref_ Nr_": "16.VI.38/7",
        "Künstler": "Unbekannt",
        "Bezeichnung": "\"Nebelspalter\", Zweispitz von Bürgermeister Johannes Herzog von Effingen (1773-1840)",
        "Zusatz": "Getragen von Joh. Herzog als Adjudant des Aargauer Jäger-Bataillons Anfang 19 Jh. S. auch 1938.06.16.S101",
        "Datierung": "1818 - 1831",
        "Signatur": "",
        "Beschriftung": "Tinteneintrag im Hutinnern",
        "Material": "Wahrscheinlich Biberfell, Stoff: Seide, Leder",
        "Systematik": "Kopfbedeckung, Hut, Zweispitz, Nebelspalter",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "42 x 55 x 4 cm Objektmass",
        "Beschreibung": "Zweispitz mit Aargauerkokarde, Goldkordel und schwarzer Masche.  Johannes Herzog war als Politiker 13 Mal Präsident des Grossen Rates. Als Fabrikant eröffnete er die erste mechanische Baumwollspinnerei im Aargau.",
        "Erwerbsart": "Geschenk von Herrn Matter-Bally, Kölliken (Enkel des Generals, Ururenkel von Johann Herzog von Effingen) und Dr. Hüssy-Bally, Zürich",
        "Herkunft": "Aus der Erbschaft Herzog. Nachlass des Bürgermeisters.",
        "Raumverweis": "",
        "NummerObjektteppich": "59",
        "Person": "Johannes Herzog (von Effingen)",
        "Lebensdaten": "1773-1840",
        "Infotext": "Bereits als Fabrikant galt Johannes Herzog, der in Effingen in einfachen Verhältnissen aufwuchs, als innovativer Kopf. 1810 mechanisierte er als einer der Ersten in der Schweiz seine Baumwollspinnerei und beschäftigte 700 Mitarbeitende. Gleichzeitig übernahm Herzog Funktionen im noch jungen Staat. Allein den Grossen Rat des Kantons Aargau präsidierte er 13 Mal. Seinen grössten politischen Einfluss übte er zwischen 1807 und 1830 in der Aargauer Regierung aus, der er fünf Mal als Bürgermeister vorstand. Unter seiner Führung wurde der Regierungsstil zunehmend autoritär, was zum „Freiämtersturm“ führte. Die Bürgerrevolte forderte Reformen und 1830 den Rücktritt der gesamten Regierung. Aufgrund seines politischen Einflusses und seiner öffentlichen Auftritte wurde Herzog als „Roi de l’Argovie“, als König des Aargaus, bezeichnet. Gerne liess er sich mit Orden porträtieren und nannte sich „Herzog von Effingen“, ohne dass er adliger Abstammung gewesen wäre.\r"
    },
    {
        "DateinameBild": "Frey-Herose_Friedrich.jpg",
        "Inventar Nr": "1966.11.18.S001",
        "Ref_ Nr_": "18.11.66",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Gänsekiele, Federkiele von Bundesrat Friedrich Frey-Herosé (1801-1873)",
        "Zusatz": "mit persönlichen Notizen, wofür die Federn verwendet wurden",
        "Datierung": "1848",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Gänsefedern",
        "Systematik": "Büro, Schreibutensilien, Gänsekiele, Federkiele",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "bis zu 34 cm lang Objektmass",
        "Beschreibung": "Bundesrat in der ersten Landesregierung nach der Bundesstaatsgründung 1848. Wichtige Verträge unterzeichnete Frey-Herosé mit Gänsekielen, die er nur ein einziges Mal benutzte.",
        "Erwerbsart": "Schenkung von Dr. G. A. Frey-Bally, Laurenzenvorstadt 107, Aarau",
        "Herkunft": "Gemäss Inventar vom 10.12.1967 in den Nachweisakten handelt es sich um eine Leihgabe.",
        "Raumverweis": "",
        "NummerObjektteppich": "44",
        "Person": "Freidrich Frey-Herosé",
        "Lebensdaten": "1801-1873",
        "Infotext": "Der studierte Chemiker Friedrich Frey-Herosé übernahm in Aarau die chemische Fabrik seines Vaters und erweiterte sie um eine Baumwollspinnerei. Mit der Wahl in die erste Schweizer Bundesregierung von 1848 krönte er seine politische Laufbahn. Zuvor war Frey-Herosé im Grossen und im Kleinen Rat des Kantons Aargau und im Nationalrat. Während seiner achtzehnjährigen Amtszeit als Bundesrat war er zwei Mal Bundespräsident. Wichtige Verträge unterzeichnete Frey-Herosé mit Gänsekielen, die er nur ein einziges Mal benutzte. Mit einer solchen Feder unterschrieb er 1850 auch den „Allgemeinen Vertrag der Freundschaft, der gegenseitigen Niederlassung, des Handels und der Auslieferung der Verbrecher“ zwischen der Schweiz und den USA. Der Vertrag gilt als einer der ersten Handelsverträge der modernen Schweiz.\r"
    },
    {
        "DateinameBild": "Herzog_Hans.jpg",
        "Inventar Nr": "1970.03.21.S001",
        "Ref_ Nr_": "21.3.70",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Ehrendegen von General Hans Herzog (1819-1894)",
        "Zusatz": "General der Schweizer Armee 1870-1871, Waffenchef der Schweizer Artillerie.",
        "Datierung": "1872",
        "Signatur": "",
        "Beschriftung": "oben auf Klinge auf beiden Seiten: \"Potzdam\"",
        "Material": "Metall; Scheide: Leder",
        "Systematik": "Hieb- und Stichwaffen, Ehrendegen, General Herzog, Offiziersdegen, Bismarck, Kaiser Wilhelm, Friedrich der Grosse",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "104.5 x 10.5 x 7 cm Objektmass",
        "Beschreibung": "Hans Herzog war General der Schweizer Armee während des Deutsch-Französichen Krieges und verantwortete als solcher den Übertritt der Bourbaki-Armee.  Der Degen wurde General Herzog in Anerkennung für die Entwaffnung der Bourbaki-Armee im Schloss Potsdam durch Bismarck überreicht. Es ist eine Kopie des Degens von Friedrich dem Grossen, hergestellt in der königlichen Waffenschmiede in Potsdam.",
        "Erwerbsart": "Schenkung von Max Bally-Hünerwadel, C.F. Ballystrasse, Schönenwerd",
        "Herkunft": "Frau Bally-Hünerwadel war eine Tochter von General Herzog.",
        "Raumverweis": "",
        "NummerObjektteppich": "26",
        "Person": "Hans Herzog",
        "Lebensdaten": "1819-1894",
        "Infotext": "Die militärische Laufbahn von Hans Herzog nahm im Aarauer Schlössli seine wohl bedeutendste Wendung. Hier trafen sich am 17. Juli 1870 Oberst Herzog und Bundesrat Emil Welti. Welti überzeugte Herzog davon, den Oberbefehl über die Schweizer Truppen zu übernehmen, die wegen des soeben ausgebrochenen Deutsch-Französischen Krieges an die Grenze befördert wurden. Zwei Tage später wählte die Bundesversammlung Herzog zum General. Im Winter 1870/71 regelte er die Entwaffnung und den Übertritt der vom Feind verfolgten französischen Armee unter General Bourbaki in die neutrale Schweiz. Dieser militärische Coup brachte Herzog viel Lob ein. Der deutsche Reichskanzler von Bismarck persönlich überreichte ihm 1872 in Berlin einen Ehrendegen.\r"
    },
    {
        "DateinameBild": "Hirsbrunner_jakob_02.jpg",
        "Inventar Nr": "1973.12.22.S005",
        "Ref_ Nr_": "22.12.73",
        "Künstler": "Musikinstrumentenfabrik Hirsbrunner (Aarau 1870 - 1965)",
        "Bezeichnung": "Buccin, Tenorposaune mit Drachenkopf, Hirsbrunner",
        "Zusatz": "",
        "Datierung": "1820 - 1870",
        "Signatur": "Gravur: \"HIRSBRUNNER J. A. SUMISWALD\" (vom Spieler her gelesen); Plakette: \"G. Hirsbrunner/Musikinstrumentenfabrik/1 AARAU 1\"",
        "Beschriftung": "",
        "Material": "Blech, bemalt",
        "Systematik": "Blechblasinstrumente, Tenorposaune, Buccin, Bucina, Drachenkopfposaune",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "96 x 11 x 60 cm Objektmass",
        "Beschreibung": "in H (Zug wohl nicht original)  Jakob Hirsbrunner (1836-1900) stammte aus einer Instrumentenbauer-Dynastie in Sumiswald im Emmental und eröffnete in Aarau eine eigene Werkstatt für Blechblasinstrumente.",
        "Erwerbsart": "Schenkung einer vorherigen Leihgabe von Herrn und Frau Hirsbrunner-Wüthrich, Pestalozzistrasse 3, Aarau",
        "Herkunft": "Wurde zusammen mit zwei Hörnern, zwei Trompeten und einem Klappenflügelhorn geschenkt.",
        "Raumverweis": "",
        "NummerObjektteppich": "84",
        "Person": "Jakob Hirsbrunner",
        "Lebensdaten": "1836-1900",
        "Infotext": "Jakob Hirsbrunner wurde in eine Familie hineingeboren, die im Emmental Holz- und Blechblasinstrumente herstellte. Während sein Bruder den väterlichen Betrieb übernahm, gründete Jakob in Aarau beim Haldentor eine eigene Instrumentenbauerfabrik. Zunächst alleine, später mit seinem Sohn Gottfried als Lehrling, produzierte er rund 20 Instrumente im Jahr. Er rüstete ganze Feld- und Blasmusiken aus, so etwa die Stadtmusik Aarau, in der er selber Bass spielte. „Solide Arbeit, Selbstfabrikation aller Bestandteile seiner Blechinstrumente.“ So lautete das Urteil der Jury an der Landesausstellung 1883 in Zürich. Die Aarauer Fabrik wurde 1965 geschlossen. Im Emmental hingegen werden noch immer Hirsbrunner-Instrumente produziert. Seit der Gründung der ersten Firma im späten 18. Jahrhundert hat die Familie 18 Instrumentenbauer hervorgebracht. Ein Prunkstück aus Hirsbrunners Werkstatt ist das Buccin, eine seltene Form einer Posaune mit Drachenkopf.\r"
    },
    {
        "DateinameBild": "Kieser_Jakob_01.jpg",
        "Inventar Nr": "1979.05.31.S102",
        "Ref_ Nr_": "5.9.59/b (?)",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Aushängeschild, Schaufensterdekoration des Aarauer Kammmachers Jakob Kieser (1798-1880)",
        "Zusatz": "",
        "Datierung": "1831",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Farbe, Holz, Glas, Metall",
        "Systematik": "Helvetik, Bundesstaat, Patriotismus, Politik, Gewerbe, Werkstück, Aushängeschild, Firmenschild",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Malerei auf Metall, in holzgefasstem Glaskästchen",
        "Massangaben": "58.5 x 38 x 13 cm Objektmass",
        "Beschreibung": "Das Werkstück von 1831 diente dem Aarauer Kammmacher Jakob Kieser als Aushängeschild. Sämtliche Kantonswappen, bekrönt von einem speziellen Stadtadler, hat Kieser gezeichnet, mitsamt seinen Wahlsprüchen, \"Dieu et ma Patrie\" sowie \"Eintracht macht unser Glück\". Das kennzeichnete Kieser als Helvetiker und Anhänger des zu schaffenden Bundesstaates. Der Stadtadler sitzt, ohne Blutbalken, zuoberst. Den ersehnten Staat Schweiz erlebte Kieser zweimal, 1798 bis 1802 und ab 1848.  Aarau amtierte nach 1798 sozusagen als heimliche Hauptstadt: Hier wurden der Schweiz. Schützenverein, Eidg. Turnverein, Schweiz. Sängerverein gegründet, Organe, die tatkräftig und festfreudig für den Bundesstaat Schweiz wirkten.  Beschriftung oben im \"Dach\":  \"Hl. Schrif(t)   Die Werke des Teufels, od. der Weltwender sind:  Lüge Torheit Falsch heit, Betrug, Dieb= stal, Geiz od. Abgöttereÿ, Meineid, Ungerechtigkeit heimlicher Todschlag, Heucheleÿ, Neid, Fressen, Saufen, Unzucht, Ehe= bruch, falsches Zeugniss, Lästerung, Ver= läumdung Afterreden, u.d.gl. Diese Obrigkeiten, weil sie solche Greuel treiben, werden das Reich Gottes nicht ererben, sondern doppelte   Verdammnis empfangen, weil sie das Recht wissen & thun sollen. Den Geringen wiederfährt Gnade, aber die Ge= waltigen werden gewaltig gestraft werden sagt d. hl. Schrift. Sie haben gethan was sie wollten, sie haben verfolget, verlästert die gu= ten Namen, davon ihr gemeinet seid. Daher der Herr am Gerichtstage zu ihnen sagen wird: Weichet von mir, – in das ewige Feuer. u.s.w.\"  (?letzte Zeile nicht lesbar?)",
        "Erwerbsart": "Geschenk von Alice Kieser, Bachstr., Aarau",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "63",
        "Person": "Jakob Kieser",
        "Lebensdaten": "1798-1880",
        "Infotext": "Jakob Kieser war einer von rund zehn Kammmachern, die im frühen 19. Jahrhundert in Aarau ihrem Handwerk nachgingen. Kiesers Schaufensterdekoration ist deswegen so bemerkenswert, weil sie eine politische Botschaft enthält. „Einigkeit macht unser Glück“ und „Dieu et ma patrie“ stehen darauf geschrieben und weisen – zusammen mit den Kantonswappen – den Kammmacher als Anhänger eines nationalen Einheitsgedankens aus. Dass sich ein einfacher Handwerker in jener spannungsgeladenen Zeit um 1831, in der sich konservative und liberale Kräfte im Ringen um die Ausrichtung der Schweiz gegenüberstanden, politisch äussert, verweist auf die liberale Grundstimmung, die sich seit der Helvetik in Aarau halten konnte.\r"
    },
    {
        "DateinameBild": "Feer_Herzog_Caroline.jpg",
        "Inventar Nr": "1986.11.11.S001",
        "Ref_ Nr_": "11.11.86",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Armband von Caroline Feer-Herzog (1821-1890)",
        "Zusatz": "",
        "Datierung": "19.Jh. 2.H.",
        "Signatur": "",
        "Beschriftung": "\"26 Février 1849\" (Hochzeitsdatum), \"Henri/19 Décembre/1857\", \"Charles/17 Mars/1854\", \"James/21 Janvier/1851\", \"Sophie/23 Fevrier/1860\", \"Gaston/31 Juillet/1864\"",
        "Material": "Perlen, Metall vergoldet, Haar, Papier",
        "Systematik": "Schmuck, Armband, Caroline Feer-Herzog, Familie, Kinder, Hochzeit",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "0.8 x 20.5 x 4.8 cm Objektmass",
        "Beschreibung": "Vergoldetes und mit Perlen besetztes Armband, zusammengefügt aus zwölf Gliedern und einer Schliesse. Sechs Medaillons, fünf davon enthalten Haare von Caroline Feer und von vier Kindern. Auf der Rückseite der Medaillons sind das Hochzeitsdatum und die Geburtsdaten ihrer fünf Kinder eingraviert.  Lebensdaten der Kinder:  James (1851-1913) Carl (1854-1923) Henri (1857-1892) Sophie (1860-1946) Gaston (1864-1936)  Caroline Feer Herzog war die Gattin von Nationalrat Feer-Herzog und Enkelin von Johannes Herzog von Effingen.",
        "Erwerbsart": "Schenkung aus dem Nachlass von Minister Dr. Eduard Feer (1894-1983) geschenkt durch dessen Gattin Margrit Feer-Peteln, durch Frau und Herrn Dr. Paul Ammann, Gotthelfstr., Aarau überbracht.",
        "Herkunft": "Es gehörte Caroline Feer-Herzog (1821-1890), Gattin von Nationalrat Feer-Herzog, Enkelin von Bürgermeister Johannes Herzog von Effingen.",
        "Raumverweis": "",
        "NummerObjektteppich": "23",
        "Person": "Caroline Feer-Herzog",
        "Lebensdaten": "1821-1890",
        "Infotext": "Caroline Herzog stammte aus einer angesehenen Aarauer Familie. Am 26. Februar 1849 heiratete sie Carl Feer. Er war Unternehmer und Politiker und einer der einflussreichsten Aarauer seiner Zeit. Seine Ehe beschreibt er als „eine Verbindung, die nicht nur meinen Wünschen, sondern auch denjenigen unserer Väter entsprach und die mir eine neue, angenehmere und bewegtere Existenz schuf, als das etwas einsame, väterliche Haus sie zu bieten im Stande war.“ Caroline kam ihrer Rolle als repräsentative Ehegattin und als Mutter gewissenhaft nach. Auf ihrem Armband sind neben dem Hochzeitsdatum die Geburtsdaten ihrer Kinder Johann Jakob Friedrich, Carl, Henri, Sophie und Gaston eingraviert. Auch Haare ihrer Kinder wurden in das wertvolle Schmuckstück eingeflochten.\r"
    },
    {
        "DateinameBild": "Freudiger_Elisabeth_01.jpg",
        "Inventar Nr": "1990.10.10.S015",
        "Ref_ Nr_": "10.10.90/15",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Coque (Federbündel), Hutputz",
        "Zusatz": "",
        "Datierung": "1930 - 1990",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Hahnenfedern",
        "Systematik": "Mode, Hutmacherin, Modistin, Kopfbedeckung, Damenhut, Hahnenfedern, Coque, Federbündel, Hutschmuck",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "3.5 x 22 x 14 cm Objektmass",
        "Beschreibung": "Bündel mit fünf Hahnenfedern  Elisabeth Freudiger (1921-1993) war Modistin und Besitzerin von \"Modes Elisabeth\".",
        "Erwerbsart": "Ankauf",
        "Herkunft": "Aus Liquidation von \"Modes Elisabeth\"",
        "Raumverweis": "",
        "NummerObjektteppich": "61",
        "Person": "Elisabeth Freudiger",
        "Lebensdaten": "1921-1993",
        "Infotext": "Als unter ihrer Wohnung an der Laurenzenvorstadt ein Ladenlokal frei wurde, ergriff Elisabeth Freudiger ihre Chance. Die gelernte Modistin eröffnete 1952 ihr eigenes Hutgeschäft – neben sechs Konkurrenzgeschäften in Aarau. Von den 15 Hüten im Sortiment wurden zwei gleich am ersten Tag verkauft. Bereits nach einigen Monaten wurde das Ladenlokal gekündigt und «Modes Elisabeth» musste erstmals umziehen. Die Witwe und alleinerziehende Mutter brauchte Durchhaltevermögen. Aber eine treue Stammkundschaft schätzte ihre kompetente Beratung und schaute manchmal auch nur auf einen Schwatz vorbei. Mit der Mode der 1960er-Jahre verschwand der elegante Hut allmählich aus der Alltagsgarderobe. Die Frauen wollten auf ihre toupierten Frisuren keine Hüte setzen. Daraufhin vergrösserte Elisabeth Freudiger ihr Sortiment um Lederwaren, Pelz- und Damenbekleidung. 1990 – nach fast vierzig Jahren – verschwand das damals letzte Hutgeschäft aus Aarau.\r"
    },
    {
        "DateinameBild": "Businger_Hans_01.jpg",
        "Inventar Nr": "1995.11.30.S001",
        "Ref_ Nr_": "30.11.95",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Papiertragtasche Businger Teigwarenfabrik",
        "Zusatz": "",
        "Datierung": "Mitte 20. Jh.",
        "Signatur": "",
        "Beschriftung": "Aufdruck: \"Businger/4 Frisch-Eier/Spezial-Hartweizengriess mit 4 frischen Eiern per kg/H. Businger Teigwarenfabrik Aarau; andere Seite: \"Jede Woche 2 x/Businger/Teigwaren\"",
        "Material": "Papier, rotweisse Kordel",
        "Systematik": "Werbung, Papiertragtasche, Papiertüte, Nahrungsmittel, Teigwaren, Businger",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "26.3 x 21.8 cm Objektmass",
        "Beschreibung": "Hans Businger (1897-1963) versorgte mit seiner Teigwarenfabrik bis in die 1980er-Jahre die Umgebung von Aarau mit Teigwaren.",
        "Erwerbsart": "Schenkung von Herrn Lüscher, Römerstr. 27, 5004 Aarau",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "7",
        "Person": "Hans Businger",
        "Lebensdaten": "1897-1963",
        "Infotext": "Im Betrieb seiner Eltern absolvierte Hans Businger eine Bäckerlehre und gründete 1929 in Aarau seinen eigenen Lebensmittelbetrieb. Fünf Jahre später zog er in ein grösseres Fabrikgebäude an der Bachstrasse. Heute befindet sich das „Töpferhaus“ darin. Indem er dem Hartweizengriess nicht drei, sondern vier frische Eier pro Kilo hinzugab, steigerte Businger die Festigkeit der Teigwaren. Das Geheimrezept sicherte ihm das Überleben. Die 6800 kg Teigwaren, welche die Firma pro Monat produzierte, wurden in Papiertüten verpackt und per Veloanhänger zu Lebensmittelläden und Restaurants gefahren. Bald erstreckte sich Busingers Absatzgebiet bis ins Luzernische und ins Baselbiet. 1939 betrug die Produktion der Teigwaren 65 000 kg pro Monat. Mit der preisgünstigen italienischen Konkurrenz konnten die Businger-Teigwaren allerdings nicht mithalten. Der Betrieb musste 1982 schliessen und die Marke Businger wurde verkauft.\r"
    },
    {
        "DateinameBild": "Bircher-Benner_Max.jpg",
        "Inventar Nr": "2004.01.26.S006",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Bircherraffel",
        "Zusatz": "",
        "Datierung": "1950er-Jahre",
        "Signatur": "",
        "Beschriftung": "Firmenloge Merker Baden u. \"Original Dr. Bircher\"",
        "Material": "Chromstahl",
        "Systematik": "Bircherraffel",
        "Geogr_ Bezug": "",
        "Technik": "",
        "Massangaben": "29.2 x 12 x 0.5 cm Objektmass",
        "Beschreibung": "Max Bircher-Benner (1867-1939), Arzt und \"Erfinder\" des Birchermüsli, wuchs in Aarau in der Nähe der Glockengiesserei Rüetschi auf.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "17",
        "Person": "Max Bircher-Benner",
        "Lebensdaten": "1867-1939",
        "Infotext": "Max Bircher wuchs in Aarau in der Nähe der Glockengiesserei Rüetschi auf. Er besuchte das Gymnasium und wirkte bei den Kadetten mit. Nach dem Medizinstudium eröffnete er mit 24 Jahren eine eigene Praxis in Zürich. 1904 folgte die Gründung des Sanatoriums „Lebendige Kraft“ am Zürichberg. Seine Klinik, die illustre Kurgäste wie Thomas Mann empfing, war bekannt für einen strengen Tagesablauf und Vollwertkost. Inspiriert von Hirtenmahlzeiten stand auf dem Diätplan eine Speise, die als Birchermüesli bekannt geworden ist. Im Originalrezept wurde ein Apfel mit der Raffel samt Haut und „Bütschgi“ in die vorgeweichten Haferflocken gerieben. Zunächst belächelt für seine Ernährungslehre, gilt Max Bircher-Benner heute als Pionier der Lebensreformbewegung. Er war mit der Elsässerin Elisabeth Benner verheiratet. Das Paar hatte sieben Kinder.\r"
    },
    {
        "DateinameBild": "Gottschalk_Johann_Wilhelm_032.jpg",
        "Inventar Nr": "2004.01.26.Z145.41",
        "Ref_ Nr_": "",
        "Künstler": "Johann Wilhelm Gottschalk (Königsberg 1768 - 1843 Aarau)",
        "Bezeichnung": "Zinnfiguren: Jäger, Jagd- und Haustiere",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Zinn blank davon 8 Stk. in Originalbemalung",
        "Systematik": "Zinnfiguren",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "ca. 1 cm (kleinste) Objektmass ca. 6.5 cm (grösste) Objektmass",
        "Beschreibung": "41 Stk. Jäger, Jagd- und Haustiere:  5 Jäger, grosse Jagd 14 verschiedene Tiere (Wildschweine, grosser Hahn, Hunde, kleiner Bär, Feldhase, Hirsche und Kondor) 1 Bäuerin 21 Haustiere (Kühe, Ochsen, Kalb, 1 Gruppe Mutterkuh mit Kalb, Pferde, Esel, Gänse, Enten und Hühner)  Der Zinngiesser Johann Wilhelm Gottschalk (1768-1843) aus Preussen brachte die Technik des Zinnfigurengiessens nach Aarau. Er uns sein ehemaliger Lehrling Wehrli blieben die einzigen Zinnfigurenhersteller der Schweiz.",
        "Erwerbsart": "Alt-Aarau",
        "Herkunft": "",
        "Raumverweis": "2. OG",
        "NummerObjektteppich": "19",
        "Person": "Johann Wilhelm Gottschalk",
        "Lebensdaten": "1768-1843",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "De-Maddalena_Roesli.jpg",
        "Inventar Nr": "2004.04.30.S026",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Ausgehhaube von Schwester Rosa (Rösli) de Maddalena (1906-2000)",
        "Zusatz": "",
        "Datierung": "1950",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Seide schwarz, mit hellbrauner Borte und 2 Knöpfen",
        "Systematik": "Textilien, Berufsbekleidung, Schwesternhaube",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "30 x 69 cm Objektmass",
        "Beschreibung": "Ausgehhaube aus schwarzer Seide, mit hellbrauner Borte und zwei Knöpfen.  Rosa de Maddalena wuchs in Aarau auf, wo die Familie 1917 eingebürgert wurde. Rosa war schon früh beseelt vom Wunsch, als Pflegerin in der christlichen Mission zu arbeiten. Nach einer Lehre als Damenschneiderin liess sie sich in der Kranken- und Diakonissenanstalt Neumünster in Zürich zur Krankenpflegerin ausbilden (1928-1933).",
        "Erwerbsart": "Geschenk von Leonie de Maddalena",
        "Herkunft": "Rösli de Maddalena",
        "Raumverweis": "",
        "NummerObjektteppich": "8",
        "Person": "Rosa De Maddalena",
        "Lebensdaten": "1906-2000",
        "Infotext": "Rosa de Maddalena wuchs in Aarau auf, wo die Familie 1917 eingebürgert wurde. Ihr Vater Luigi de Maddalena, der in Aarau ein Baugeschäft führte, war aus Italien eingewandert, ihre Mutter Bertha Müller war Aargauerin. Rosa war schon früh beseelt vom Wunsch, als Pflegerin in der christlichen Mission zu arbeiten. Nach einer Lehre als Damenschneiderin liess sie sich in der Kranken- und Diakonissenanstalt Neumünster in Zürich zur Krankenpflegerin ausbilden (1928-1933). „Ich bleibe wahrscheinlich ledig, mein Beruf ist ja so schön und von den Patienten geniesse ich nur Liebe.“ Als sie dies 1934 in ihr Tagebuch schrieb, war die Krankenschwesternkleidung für Rosa de Maddalena bereits mehr als eine Uniform: Sie hatte ihre Berufung gefunden und blieb unverheiratet bis zur Pensionierung als Krankenpflegerin in Zürich. In den 1950er-Jahren kehrte sie nach Aarau in das Elternhaus an der Tellistrasse zurück.\r"
    },
    {
        "DateinameBild": "Von_Wattenwyl-Rothpletz_Salome.jpg",
        "Inventar Nr": "2004.10.06.S008",
        "Ref_ Nr_": "Nov 83",
        "Künstler": "Emanuel Handmann (Basel 1718 - 1781 Bern)",
        "Bezeichnung": "Portrait von Salome von Wattenwyl-Rothpletz (1730-1798)",
        "Zusatz": "",
        "Datierung": "1749",
        "Signatur": "Hinten u. r. (Pinsel): \"E Handmann/ Pinx: A° 1749\"",
        "Beschriftung": "Hinten u. r. (Etikette): \"Restauriert/1983/ A. Flory Rest. Baden\"",
        "Material": "Ölfarbe, Textil, Holz",
        "Systematik": "Kunst, Malerei, Frauenportrait, Hüftbildnis, Rokoko, Rothpletz, Hunziker, Familien, Wohnen",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Öl auf Textilbildträger, doubliert. Zierrahmen Holz, reliefiert, profiliert u. vergoldet",
        "Massangaben": "83.5 x 65.1 cm Keilrahmenmass 82.4 x 64 cm Lichtmass 92 x 74 cm Zierrahmenmass",
        "Beschreibung": "Hüftbildnis von Salome von Wattenwyl-Rothpletz (1731-1798) in Frontalansicht mit leicht nach rechts gewendetem Kopf u. Blick aus dem Bild im Alter von 18 Jahren. Die Dargestellte trägt ein schwarzes Kleid mit Goldbordüren, oben bestehend aus einer engen Corsage mit weitem Dekolleté u. enger Schneppentaille, unten aus einem weiten Rock. An der Corsage drei rhombenförmige Agraffen. Aus den langen, an den Oberarmen weiss geschlitzten Ärmeln u. aus dem Ausschnitt schauen Spitzen hervor. Um den Hals trägt sie eine weisse Spitzenkrause. Auf dem gelockten gepuderten Haar sitzt schräg ein schwarzes Hütchen mit weisser Feder u. Agraffe. Die linke Hand hält sie hinter dem Rücken, der rechte Arm ist leicht vom Körper gestreckt. In der Bildhälfte links eine grüne Draperie, im Hintergrund rechts eine Wand mit Pilaster.  Keilrahmen in profiliertem, an den Ecken reliefiertem u. vergoldetem Zierrahmen aus Holz.  Salome von Wattenwyl-Rothpletz heiratete in den innersten Berner Patrizierzirkel ein, wie ihre Schwester Elisabeth von Steiger-Rothpletz auch.",
        "Erwerbsart": "Zuerst dem Schlössli von den Familien Rothpletz als Dauerleihgabe überlassen. 1983 Beschluss der Rothpletzversammlung, das Bild dem Stadtmuseum als Geschenk zu überlassen (vgl. Brief vom 30.11.1983 von Pierre Rothpletz).",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "74",
        "Person": "Salomé von Wattenwyl Rothpletz",
        "Lebensdaten": "1730-1798",
        "Infotext": "Die Aarauer Seidenbandfabrikanten Abraham Rothpletz und seine Frau Maria Salomé verheirateten ihre Tochter Salomé im Alter von vierzehn Jahren mit dem Oberst und Berner Grossrat Gabriel von Wattenwyl. Er gehörte einer einflussreichen Patrizierfamilie an. Salomés Schwester Elisabeth heiratete in die nicht minder angesehene Familie von Steiger ein. In Bern verkehrten die Schwestern in Salons aufgeklärter Bürgerinnen und Bürger um Julie Bondeli und beteiligten sich an deren Debatten. Bondeli beschrieb Salomé als eine Frau mit scharfem Verstand und philosophischem Gespür. Zum Selbstverständnis der Patrizierfamilien gehörte es, Porträtbilder anfertigen zu lassen, um sie in repräsentativen Räumen zu zeigen. Das Porträt von Salomé von Wattenwyl-Rothpletz malte im Jahre 1749 der in Bern lebende Basler Emanuel Handmann.\r"
    },
    {
        "DateinameBild": "Zschokke_Achilles.jpg",
        "Inventar Nr": "2004.10.07.S012",
        "Ref_ Nr_": "125; 2",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Pergamentspitzenbild: Heiliger Thomas in ovalem Medaillon, aus Blütenstengel herauswachsend",
        "Zusatz": "Nr. 125 aus der Spitzenbildsammlung, vgl. SM 2012-0087",
        "Datierung": "1. Hälfte 18. Jh.",
        "Signatur": "",
        "Beschriftung": "Vorne u. M. in Spruchband: \"S. Thomas\". Hinten (Bleistift): \"125\"",
        "Material": "Papier, Wasserfarbe",
        "Systematik": "Spitzenbild, Andachtsbild, Aquarell, Kunst, Religion, Heilige, Spitzenbilder, Christentum",
        "Geogr_ Bezug": "Schweiz",
        "Technik": "Scherenschnitt aus weissem Papier. Aquarell auf Papier.",
        "Massangaben": "19 x 12.4 cm Blattmass 17.3 x 10.6 cm Bildmass",
        "Beschreibung": "Andachtsbild aus weissem Papier, handbemalt. Aus Blütenstengel mit Blättern herauswachsend ein ovales Medaillon mit der Darstellung des Heiligen Thomas (Aquarell). Unten halbrundes Spruchband: \"S. Thomas\".  Die Spitzenbildsammlung des Stadtmuseums stammt laut einer mündlichen Überlieferung von Achilles Zschokke, Pfarrer in Gontenschwil und zehnter Sohn von Heinrich Zschokke.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "55",
        "Person": "Achilles Zschokke1823-1896",
        "Lebensdaten": "",
        "Infotext": "Achilles Zschokke wuchs als zehnter Sohn von Heinrich und Nanny Zschokke in der Blumenhalde in Aarau auf. Er studierte Theologie in Berlin und Bonn. 1848 nahm er in der Aargauer Gemeinde Gontenschwil eine Stelle als Pfarrer an. Neben seiner Tätigkeit als Seelsorger kümmerte er sich um das Schul- und das Armenwesen. Zudem war er wesentlich an der Anbindung des oberen Wynentals an das Eisenbahnnetz beteiligt, die erst 1904 – nach seinem Tod – erfolgte. Ab 1890 sass Zschokke auch im Grossen Rat des Kantons Aargau, wo er sich insbesondere für die Bildung engagierte. Zusammen mit seiner Frau Susanna Gautschi hatte er 15 Kinder. Das Geld reichte für die grosse Familie nur knapp. Sie galten denn auch als die ‚armen Zschokke’, verglichen mit den Aarauer Zschokke. Gemäss mündlicher Überlieferung legte der Dorfpfarrer eine Sammlung an Spitzenbildern an, die heute im Besitz des Stadtmuseums ist. Unbestritten war er eine Sammlernatur, besass er doch eine der grössten Käfersammlungen der Schweiz.\r"
    },
    {
        "DateinameBild": "Meyer_Johann_Rudolf.jpg",
        "Inventar Nr": "2004.10.29.L010",
        "Ref_ Nr_": "428; 15; 3",
        "Künstler": "Alfons Alois Josef Reinhard (Reinhart) (Horw 1749-1824 Luzern)",
        "Bezeichnung": "Portrait von Jakob Spilmann",
        "Zusatz": "Knecht bzw. Diener von Johann Rudolf Meyer, Vater (1739-1813)",
        "Datierung": "um 1800",
        "Signatur": "",
        "Beschriftung": "Hinten (Etikette): \"Aargauer/Kunsthaus/Aarau/428/Künstler: Reinhart Joseph/Titel: Jakob Spilmann/Eigentum: Aarg. Staat\" u. (Etikette): \"Einrahmungen/S. Sch....nn u. Sohn/Aarau ...hausstrasse\" u. (Etikette): \"15\" u. (Bleistift): \"3\"",
        "Material": "Ölfarbe, Holz",
        "Systematik": "Männerportrait, Brustbildnis, Kunst, Malerei, Helvetik, Meyer, Arbeit, Knecht, Diener, Familien, Schlössli",
        "Geogr_ Bezug": "Aarau",
        "Technik": "5Öl auf Holz. Zierrahmen Holz, profiliert u. vergoldet",
        "Massangaben": "47,3 x 32,6 cm Lichtmass 59,1 x 44,6 cm Zierrahmenmass 48,7 x 34,8 cm Kartonmass",
        "Beschreibung": "Brustbildnis von Jakob Spilmann im Dreiviertelprofil nach rechts mit Blick aus dem Bild vor dunklem Hintergrund. Der Dargestellte trägt über dem schulterlangen gewellten Haar eine helle Zipfelmütze. Dazu einen hochgeschlossenen Rock und eine weisse Halsbinde.   Der Seidenbandfabrikant Johann Rudolf Meyer Vater (1739-1813) war der vermögendste und wohl einflussreichste Aarauer seiner Zeit. Das Portrait seines Hausknechts Jakob Spilmann entstand im Zusammenhang mit dem Auftrag an den Maler Maler Joseph Reinhard, die Landbevölkerung aus allen Regionen der Schweiz zu portraitieren (die so genannten Trachtenbilder).  Leihgabe des Aargauer Kunsthauses",
        "Erwerbsart": "Leihgabe des Aargauer Kunsthauses, Aarau, Eigentum Aarg. Staat",
        "Herkunft": "",
        "Raumverweis": "2. OG",
        "NummerObjektteppich": "9",
        "Person": "Johann Rudolf Meyer",
        "Lebensdaten": "1739-1813",
        "Infotext": "\r"
    },
    {
        "DateinameBild": "Osterrieth_Johann_Daniel2.jpg",
        "Inventar Nr": "2004.10.29.S009",
        "Ref_ Nr_": "",
        "Künstler": "Johann Daniel Osterrieth (Strassburg 1768 - 1839)",
        "Bezeichnung": "Reproduktion: Stadtplan von Aarau, \"Projet d'Agrandissement de la Comune d'Aarau\"",
        "Zusatz": "Original s. Inv.-Nr. 2005.09.21.S017",
        "Datierung": "1798",
        "Signatur": "",
        "Beschriftung": "Vorne: \"5\", \"Projet d'Agrandissement de la Comune d'Aarau / 26.4.1798\"",
        "Material": "",
        "Systematik": "Städtebau, Stadtentwicklung, Stadterweiterung, Architektur, Hauptstadt, Helvetik",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Druck, Zierrahmen Holz",
        "Massangaben": "71.8 x 89 x 4.1 cm Rahmenmass 49 x 67 cm Blattmass",
        "Beschreibung": "Mit Masstab Berner Füsse (1000 Füsse = 293.26 m)  Der Strassburger Architekt Johann Daniel Osterrieth plante den Ausbau der Stadt nach der Wahl zur provisorischen Hauptstadt der helvetischen Republik. Realisiert wurde nur die Häuserzeile an der Laurenzenvorstadt, da im Herbst 1798 Luzern zur definitiven Hauptstadt erkoren wurde.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "50",
        "Person": "Johann Daniel Osterrieth",
        "Lebensdaten": "1768-1839",
        "Infotext": "Als Aarau 1798 Hauptstadt der Helvetischen Republik wurde, beauftragte der Stadtrat den Strassburger Architekten Daniel Osterrieth mit der Planung neuer Wohnhäuser und eines Parlamentsgebäudes vor den Toren der Altstadt. Zuvor hatte Osterrieth in Aarau die Villa von Johann Rudolf Meyer entworfen. Nach nur drei Wochen legte er das „Projet d’Agrandissement de la Comune d’Aarau“ vor. Der Plan sah auch eine neue Kirche, militärische Einrichtungen, Parkanlagen und ein Theater vor. Noch ehe die Pläne einer Prüfung unterzogen werden konnten, war Aaraus Hauptstadttraum ausgeträumt. Die Abgeordneten siedelten nach Luzern über und Osterrieths Projekt verschwand in der Schublade. Einziges bauliches Zeugnis seiner Ideen ist die Häuserzeile der Laurenzenvorstadt, die bereits im Mai 1798 gebaut wurde.\r"
    },
    {
        "DateinameBild": "Rothpletz_August.jpg",
        "Inventar Nr": "2004.11.02.S036",
        "Ref_ Nr_": "177",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Bierkrug mit Zinndeckel, darauf zwei Zwerge",
        "Zusatz": "Neffe von Christian Emil Rothpletz (1824-1897)",
        "Datierung": "1873",
        "Signatur": "",
        "Beschriftung": "Deckel (Gravur): \"z. f. E.\", \"Fr. M. Wolff s/m l. A. Rothpletz 1873\"",
        "Material": "Glas, Zinn",
        "Systematik": "Gefässe, Humpen, Bierkrüge",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "Höhe 29.5cm Objektmass 10 cm Durchmesser",
        "Beschreibung": "Der Bierkrug ist wohl Zeuge einer Männerfreundschaft. Anfertigen liess ihn ein Friedrich Wolff für «A. Rothpletz», wie die Gravur verrät.  Friedrich August Rothpletz (1852-1918) lehrte als Professor für Geologie an der Universität München. In der Pfalz aufgewachsen, besuchte er in Aarau einige Jahre die Schule, bevor er in Heidelberg Geologie studierte und zu einem der bedeutendsten Geologen seiner Zeit wurde.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "65",
        "Person": "August Rothpletz",
        "Lebensdaten": "1853-1918",
        "Infotext": "August Rothpletz verbrachte seine Kindheit in der Pfalz, wo er von einem Hauslehrer unterrichtet wurde. Erst mit fünfzehn Jahren wurde er in Aarau eingeschult. Vermutlich wohnte er bei seinem Onkel, dem Schlössliherrn Christian Emil Rothpletz. Das Gymnasium schloss er in Zürich ab. Ab 1871 studierte er Geologie in Heidelberg, Zürich und Leipzig und promovierte 1882. Bereits zwei Jahre danach folgte die Habilitation. Während vierzehn Jahren lehrte August Rothpletz an der Universität München. Er betrieb Alpengeologie, schuf geologische Karten und führte tektonische Untersuchungen zum Bau der Alpen durch. Neben den Nordtiroler Alpen galt sein Interesse den Schweizer Alpen. So regte ihn etwa der Bergsturz von Elm 1881 zu einer Studie über das Bergsturzgebiet an. Der Bierkrug mit Zinndeckel stammt aus der Studienzeit von August Rothpletz. Anfertigen liess ihn ein Friedrich Wolff im Jahr 1873. Das Objekt ist wohl Zeuge einer Männerfreundschaft.\r"
    },
    {
        "DateinameBild": "Kummler_Sauerlaender_Hermann_01.jpg",
        "Inventar Nr": "2005.01.23.S001",
        "Ref_ Nr_": "",
        "Künstler": "Hermann Kummler-Sauerländer (1863 - 1949 Aarau)",
        "Bezeichnung": "Dienstboten-Rufanlage, H. Kummler & Co., Aarau, mit zwölf elektromechanischen Anzeigefeldern",
        "Zusatz": "",
        "Datierung": "1899 - 1914",
        "Signatur": "",
        "Beschriftung": "\"H. Kummler & Co., Aarau.\"",
        "Material": "Holz, Glas, Karton, Metall, Isolierstoffe, Porzellan",
        "Systematik": "Elektrogeräte, Dienstboten-Rufanlage, Sonnerie",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "10 x 55.4 x 27.8 cm (liegend, d. h. auf den Füssen stehend) Objektmass",
        "Beschreibung": "Dienstboten-Rufanlage mit 12 Rufstellen mittels Relais, die die Klappe für den betreffenden Raum zu Fall bringt und den Dienstboten anzeigt, wohin sie eilen müssen. Hier: Wohn-Zimmer, Salon, Veranda, Bureau, Bade-Zimmer, Schlaf-Zimmer, Diensten-Zimmer, Gast-Zimmer, Schlafz. Süd, Gastz. II. Stock. Kann durch Einlegen anderer Blättchen jederzeit verändert werden. Handrückstellung, vier Porzellanfüsse (nachträglich?) angeschraubt.  Mit seiner Firma Kummler & Co. (später Kummler & Matter AG) spezialisierte sich Hermann Kummler-Sauerländer auf elektrische Linienwahl- und Kontrollanlagen wie etwa die Dienstboten-Rufanlage. Den grossen Markt fand die Firma aber dann in der Elektrifizierung der Eisenbahn.",
        "Erwerbsart": "Kauf Hunziker, Küttigerstrasse",
        "Herkunft": "unbekannt",
        "Raumverweis": "",
        "NummerObjektteppich": "54",
        "Person": "Hermann Kummler-Sauerländer",
        "Lebensdaten": "1863-1949",
        "Infotext": "Nach einer kaufmännischen Ausbildung wanderte Hermann Kummler-Sauerländer nach Brasilien aus, wo er als Handelskaufmann tätig war. Eine Gelbfiebererkrankung zwang ihn vier Jahre später zur Rückkehr. Kummler widmete sich dem Studium der Elektrizitätslehre und beteiligte sich an der Firma von Hermann Bäurlin, der am Aarauer Stadtbach ein Elektrizitätswerk betrieb. Mit seiner eigenen Firma Kummler & Cie. (später Kummler & Matter) konzentrierte er sich auf Elektroinstallationen von Linienwahl- und Kontrollanlagen wie etwa die Dienstboten-Rufanlage. Zudem begann er Fahrleitungen zu verlegen. Mit der Elektrifizierung der Eisenbahn eröffnete sich ihm ein grosser Markt. 1910 installierte er den Versuchsbetrieb zur Elektrifizierung der Rhätischen Bahn. Kummler & Matter verlegte 5500 km Hochspannungsleitungen in der Schweiz und 11800 km im Ausland. Auch die Elektrifizierung der Stadt Aarau und des Simplontunnels geht auf Kummler zurück. Kummler & Matter verlegte insgesamt 900 km Fahrleitungen in der Schweiz, 445 km für die SBB.\r"
    },
    {
        "DateinameBild": "Zschokke_Heinrich.jpg",
        "Inventar Nr": "2005.02.07.S016",
        "Ref_ Nr_": "17.12.57/a; 287",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Knotenstock von Heinrich Zschokke (1771-1848), mit Lederschlaufe",
        "Zusatz": "",
        "Datierung": "1795",
        "Signatur": "",
        "Beschriftung": "(Etikette): \"Mit diesem Stock überschritt Heinrich Zschokke am 3.9.1795 bei Schaffhausen die Schweizer Grenze\"",
        "Material": "Holz, Leder",
        "Systematik": "Accessoires, Gehhilfe, Handstock, Spazierstock, Wanderstab",
        "Geogr_ Bezug": "Schweiz",
        "Technik": "",
        "Massangaben": "Länge 90 cm Objektmass",
        "Beschreibung": "Heinrich Zschokke, Politiker und Schriftsteller, gilt mit seinen politischen Schriften zu den massgeblichen Wegbereitern des Schweizer Bundesstaates. Ursprünglich stammte er aus Magdeburg, von seiner Einwanderung ist der Knotenstock erhalten geblieben.  Beschriftung (Etikette): \"Mit diesem Stock überschritt Heinrich Zschokke am 3.9.1795 bei Schaffhausen die Schweizer Grenze\"",
        "Erwerbsart": "Geschenk von Herrn M. E. Zschokke, Ing., Zürich",
        "Herkunft": "",
        "Raumverweis": "2. OG",
        "NummerObjektteppich": "24",
        "Person": "Heinrich Zschokke",
        "Lebensdaten": "1771-1848",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Zehnder_Adolf_02.jpg",
        "Inventar Nr": "2005.02.08.S009",
        "Ref_ Nr_": "28.VII.1949; 220",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Aarauer Schützenwiege",
        "Zusatz": "",
        "Datierung": "1849",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Holz: Nussbaum, Stoff",
        "Systematik": "Liegemöbel, Wiege",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "174 x 104.4 x 76 cm Objektmass",
        "Beschreibung": "Schützenwiege, Nussbaum, Korb mit Lyren verziert, vier Füsse, darauf zwei geschwungene Träger.  Adolf Zehnder (1823-X) war der Wirt des „Storchen“ in Aarau. Zusammen mit 112 Aarauern aus der \"Zunft der Junggesellen\" stiftete er am Eidgenössischen Schützenfest 1849 in Aarau eine Wiege als Ehrengabe. Als der Gewinner aus Chur ein Jahr später Vater eines Sohnes wurde, reiste eine Delegation Aarauer Junggesellen als Taufpaten des kleinen Johann Anton Stäger nach Chur.",
        "Erwerbsart": "Leihgabe der Schützengesellschaft Aarau",
        "Herkunft": "Familie Stäger, Chur",
        "Raumverweis": "",
        "NummerObjektteppich": "10",
        "Person": "Adolf Zehnder",
        "Lebensdaten": "1823-X",
        "Infotext": "Adolf Zehnder war der Wirt des „Storchen“ in Aarau. Zusammen mit 112 Aarauern aus der „Zunft der Junggesellen“ stiftete er am Eidgenössischen Schützenfest 1849 in Aarau eine Wiege für den Gewinner. Das Geschenk veranschaulicht die Aufbruchsstimmung kurz nach der Gründung des Schweizer Bundesstaates. Die Wiege ist mit einem Schweizerkreuz und einem patriotischen Vers verziert. Laut einer Erinnerungsschrift hofften die Junggesellen, dass ein echter „Eidgenosse“ die „leeren Räume der Wiege“ ausfüllen möge. Tatsächlich wurde der Gewinner des Schützenfestes – Anton Stäger, ein Postsekretär aus Chur – „theils aus eigenem Pflichtgefühl, und anderntheils, weil ich von allen Seiten dazu gedrängt wurde“ im Jahr darauf Vater. Als Taufpaten eingeladen, reisten Adolf Zehnder und drei weitere Aarauer Junggesellen nach Chur, um der jungen Familie zu gratulieren.\r"
    },
    {
        "DateinameBild": "May-Barbara_01.jpg",
        "Inventar Nr": "2005.03.14.S025",
        "Ref_ Nr_": "8.4.63; 121",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Miniatur-Renaissancebuffet",
        "Zusatz": "",
        "Datierung": "Mitte 17. Jh.",
        "Signatur": "",
        "Beschriftung": "Eingelegt: \"Barbara May zue Rueda, AD 21.III. geboren als man zählt 1641\"",
        "Material": "Verschiedene Hartlaubhölzer",
        "Systematik": "Behältnismöbel, Buffetminiatur, Miniaturbuffet, Spielzeug",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Schreinerarbeit mit Intarsien",
        "Massangaben": "75 x 72 x 22 cm Objektmass",
        "Beschreibung": "Das kleine Möbelstück zeigt die zierlichen Schmuckformen der Spätrenaissance. Oben drei Türen, auf der Anrichte fünf Schubladen, unten zwei Schubladen und drei Türen. Inschrift: \"Barbara May zue Rueda, AD 21.III. geboren als man zählt 1641\". In der oberen Tür ist das Familienwappen der von Mays zu sehen. Die von Mays bewohnten ursprünglich die beiden Schlösser Rued und Schöftland, waren aber seit 1575 auch in Aarau eingebürgert.  Dazu gehören drei Zinnkannen sowie ein zinnernes Wasser- und Waschbecken.",
        "Erwerbsart": "Ursprünglich Leihgabe von Samuel Janz, Suhr; ging am 29.12.1972 als Eigentum an das Museum über",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "77",
        "Person": "Barbara von May",
        "Lebensdaten": "1641-X",
        "Infotext": "Als einzige Tochter von Beat Ludwig II. und Susanna von Grafenried wuchs Barbara mit drei Brüdern auf Schloss Rued auf. Ihre Familie wanderte Ende des 14. Jahrhunderts aus Italien nach Bern ein und kam rasch zu Reichtum und Ansehen. Als Berner Patriziergeschlecht erwarben die von Mays im frühen 16. Jahrhundert Ländereien im heutigen Kanton Aargau, etwa in Schöftland und in der Herrschaft Rued. 1575 wurde die Familie in Aarau eingebürgert. Später heiratete Barbara in die Familie von Wattenwyl. Die kostbare Buffetminiatur bekam sie als Kind geschenkt. Das kleine Möbelstück zeigt die zierlichen Schmuckformen der Spätrenaissance. Die Inschrift lautet: „Barbara May zue Rueda, AD 21.III. geboren als man zählt 1641“. In der oberen Tür ist das Familienwappen der von Mays zu sehen.\r"
    },
    {
        "DateinameBild": "Rothpletz_Schultheiss_02.jpg",
        "Inventar Nr": "2005.03.14.S033",
        "Ref_ Nr_": "27.I.41; 29",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Familienkiste Rothpletz",
        "Zusatz": "",
        "Datierung": "16.Jh.",
        "Signatur": "",
        "Beschriftung": "Vorne: \"Kisten für die H. Rothpletz\"",
        "Material": "Holz, Eisen",
        "Systematik": "Behältnismöbel, Truhen",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "43 x 75 x 46 cm Objektmass",
        "Beschreibung": "Schwere Holzkiste mit Eisenbändern beschlagen, Ecken verstärkt, links und rechts Handgriffe. Vorne Schild mit Rothpletzwappen und Helmzier mit der Aufschrift: \"Kisten für die H. Rothpletz\". Im Deckel Schnappschloss, Schlüssellochdeckel kann nur geöffnet werden, wenn die linke der vier Rundkopfnieten nach vorn geschoben wird. Hängeschloss mit Schliessstange an der Vorderfront. Schlosskasten und Scharnier ziseliert.  Familienstiftungen oder «Familienkisten» dienten dazu, mittellosen Familienmitgliedern unter die Arme zu greifen. Auch die Familie Rothpletz von Aarau hatte eine solche Kiste. Ins Leben gerufen wurde sie 1749 von Elisabeth Rothpletz-Frank (1703-1754), die den Aarauer Schultheissen Johann Jakob Rothpletz geheiratet hatte.",
        "Erwerbsart": "Geschenk aus Legat Rothpletz",
        "Herkunft": "Stiftung Familie Rothpletz",
        "Raumverweis": "",
        "NummerObjektteppich": "45",
        "Person": "Elisabeth Rothpletz",
        "Lebensdaten": "1703-1754",
        "Infotext": "Die von Wattenwyls haben eine, die von Graffenrieds ebenfalls, genauso wie weitere Berner Patrizierfamilien. Die Rede ist von sogenannten Familienstiftungen oder ‚Familienkisten‘. Sie dienten dazu, mittellosen Familienmitgliedern unter die Arme zu greifen. Auch die Familie Rothpletz von Aarau hatte eine solche Kiste. Ins Leben gerufen wurde sie 1749 von Elisabeth Frank, die den Aarauer Schultheissen Johann Jakob Rothpletz geheiratet hatte. Testamentarisch liess sie ihr Erbe in einer „neuen hölzernen, mit eisernen Banden und zweien Maletschlösseren versehenen […] Kiste“ versorgen. Die Zinsen aus dem Kapital kamen den erstgeborenen männlichen Nachkommen zugute und denen, die „sonderliche Hoffnung und Gaben zu Erlernung der Wüssenschaften und Studiorum“ zeigten. Die Kiste ermöglichte vielen – männlichen und seit geraumer Zeit auch weiblichen – Familienmitgliedern das Studium. Ausserdem finanzierten die Rothpletz damit in jüngerer Zeit Projekte des Stadtmuseums oder die Aufarbeitung ihrer Familiengeschichte.\r"
    },
    {
        "DateinameBild": "Jenni-Kunz_Adolf.jpg",
        "Inventar Nr": "2005.03.31.S020",
        "Ref_ Nr_": "1994.05.10.V004",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Kommandolaterne des Feuerwehrkommandanten",
        "Zusatz": "",
        "Datierung": "1856",
        "Signatur": "",
        "Beschriftung": "\"No. 3\", Aarauer Wappen und Jahreszahl \"1856\"",
        "Material": "Holz, Leder, Eisendraht, Stoff",
        "Systematik": "Feuerwehr, Brandbekämpfung, Kommandolaterne",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Drahtkörper (kugelförmig), mit Stoffbespannung, Holz z.T. gedrechselt oder mit Leder bespannt",
        "Massangaben": "Höhe 205 cm Objektmass Durchmesser 46 cm (des Lampenkörpers) Objektmass Höhe 66 cm (des Lampenkörpers) Objektmass",
        "Beschreibung": "Die mit Kerzen betriebene Kommandolaterne der Feuerwehr Aarau markierte jeweils den Standort des Feuerwehrkommandanten auf dem Brandplatz.  Kugelförmiger Lampenkörper aus Draht mit Stoffbespannung. Inschrift No. 3, Aarauer Wappen, Jahrzahl 1856, oben und unten gedrechselte, teilweise lederbespannte Holzabschlüsse, der untere lässt sich zum Einsetzen der Kerze (für Ausstellung elektrifiziert) nach unten herausziehen, Lampenkörper beweglich in Eisenbügel auf einer hölzernen Tragstange gelagert. Stange mit schwarz-weissem Spiralenmuster gefasst.  Adolf Jenny (1851-1941) war Kommandant der Feuerwehr und Besitzer eines Färberei- und Druckgeschäfts in der Telli.",
        "Erwerbsart": "Schenkung",
        "Herkunft": "Stadt Aarau, Feuerwehr",
        "Raumverweis": "",
        "NummerObjektteppich": "20",
        "Person": "Adolf Jenny",
        "Lebensdaten": "1851-1941",
        "Infotext": "Der Bauernsohn aus Teufenthal erwarb in Aarau eine Stangengarnfärberei für Wolle und Baumwolle und war Grossrat. Als Kommandant der Feuerwehr Aarau machte er sich durch Löschaktionen verdient, etwa beim Klosterbrand in Muri 1889. Die Modernisierung der Aarauer Feuerwehr war Jenny ein Anliegen. Seine Überlegungen hielt er in der Schrift „Versicherung und Löschwesen im Kanton Aargau“ fest. Die mit Kerzen betriebene Kommandolaterne der Feuerwehr Aarau markierte jeweils den Standort des Feuerwehrkommandanten auf dem Brandplatz. Neben dem Feuerlöschwesen lagen Jenny-Kunz die städtischen Elektrizitätswerke am Herzen. Früh setzte er sich für die erste Telefonzentrale in Aarau ein. Seine Verdienste für die Stadt brachten ihm das Ehrenbürgerrecht ein.\r"
    },
    {
        "DateinameBild": "Hunziker_Johann-Georg_01.jpg",
        "Inventar Nr": "2005.03.31.S037",
        "Ref_ Nr_": "18.VIII.45",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Schwanenschlitten für Pferdezug, aus dem Besitz von General Rapp",
        "Zusatz": "Vgl. Inv.-Nr. 2005.08.22.S204",
        "Datierung": "19.Jh. 1.H.",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Eschen- und Lindenholz, Schmiedeisen, Textilien (Seidensamt), Pelze und Fell",
        "Systematik": "Schlitten",
        "Geogr_ Bezug": "",
        "Technik": "Wagnerarbeit in Eschenholz, Schwan vermutlich aus Lindenholz geschnitzt, Schmiedearbeit, Sattlerarbeit",
        "Massangaben": "Länge 270 cm (ohne Deichsel) Objektmass Breite 112 cm (Schlittenkasten) Objektmass Höhe 168 cm Objektmass Spurweite 102 cm (aussen Kufen) Objektmass Breite 180 cm mit Ortscheit Objektmass",
        "Beschreibung": "Phaëton-Schlitten mit 2-plätzigem Schlittenkasten in Form eines Schwanes mit Fahrer- bzw. Bockdienerpritsche hinten, Untergestell aus Holz mit oben zusammen laufenden Kufen mit stilisiertem Vogelkopf als Kufenbekrönung. Kasten weiss, schwarz und dunkelgrün gefasst, rotes Seidensamt mit spätbizarrem Blumenmuster (Lyon), Untergestell rot gefasst, schwarz beschnitten.  Zubehör: Einspännerscheren, 2-Spännerdeichsel und Ortscheiter für 1- bzw. 2-spänniges Fahren, Schlittenpelz aus Bärenfell, am Rand mit weissem Schaffell eingefasst.  Erster Besitzer war der napoleonische General Johann Rapp (1772-1821), der nach dem Zusammenbruch des französischen Kaiserreichs von 1816 bis 1819 auf Schloss Wildenstein lebte und ein gern gesehener Gast bei den führenden Aarauer Bürgerfamilien war. Nach seinem Wegzug gelangte der Schlitten an den Aarauer Textilfabrikanten Johann Georg Hunziker (1774 -1850), der auf Schloss Liebegg bei Gränichen einen Landsitz hatte.",
        "Erwerbsart": "Schenkung der Familie Hunziker auf Schloss Liebegg",
        "Herkunft": "Aus dem Besitz des franz. General Johann Rapp, Schloss Wildenstein",
        "Raumverweis": "",
        "NummerObjektteppich": "33",
        "Person": "Johann Georg Hunziker",
        "Lebensdaten": "1774-1850",
        "Infotext": "Nach einer kaufmännischen Ausbildung stieg Johann- Georg Hunziker in die Handelsfirma seines Onkels ein, zunächst als Gehilfe und ab 1813 als Leiter. Unter seiner Führung erlebte das Geschäft mit dem Handel von bunten Tüchern einen Aufschwung. Seine Beziehungen reichten bis nach Kleinasien, Ägypten und Südamerika. Nachdem er 1821 eine Konzession zur Nutzung der Wasserkraft des Stadtbachs erhalten hatte, richtete er ein neues Fabrikgebäude mit Spulerei, Zwirnerei und Zettlerei ein. Bekannt war Hunziker für seine Indiennes – mit indischen Motiven bedruckte Baumwollstoffe. Auch im öffentlichen Leben Aaraus engagierte sich Johann- Georg Hunziker von 1828-1831 als Stadtammann. Als vermögender Bürger gewährte er seiner Heimatstadt öfters Darlehen, so etwa für den Bau der Kettenbrücke 1848. Die Familie Hunziker hatte einen Sitz auf dem Schloss Liebegg bei Gränichen, wo der napoleonische General Johann Rapp (1772-1821) ein gern gesehener Gast war. Er vermachte der Familie Hunziker den pompösen Schwanenschlitten, der von Pferden gezogen wurde.\r"
    },
    {
        "DateinameBild": "Koller_Louis.jpg",
        "Inventar Nr": "2005.07.07.T042",
        "Ref_ Nr_": "",
        "Künstler": "Louis Koller (1904 - 1978)",
        "Bezeichnung": "Vogelschaukarte: Original, Vorlage zum Faltprospekt \"Jungfrau Region / Berner Oberland / Schweiz\"",
        "Zusatz": "Das Bild wurde aus dem Originalpackpapier ausgepackt und für die Ausstellung gerahmt.",
        "Datierung": "ca. 1963",
        "Signatur": "u. r. (Pinsel): \"L. Koller\"",
        "Beschriftung": "",
        "Material": "Farbe, Pavatex, Holz, Glas",
        "Systematik": "Tourismus, Manuskriptkarten, Vogelschaukarten, Panoramakarten",
        "Geogr_ Bezug": "Schweiz, Berner Oberland, Jungfrauregion",
        "Technik": "Acryl (?) auf Papier, aufgezogen auf Pavatex. Hinter Glas, in Zierrahmen aus Ahornholz",
        "Massangaben": "62 x 87 cm Zierrahmenmass 60 x 83 cm Darstellungsmass",
        "Beschreibung": "Ohne Beschriftung und Bergbahnen.  Louis Koller war Lithograf und Hausgrafiker bei Trüb. Kollers erfolgreichstes Produkt waren die Vogelschaukarten, die eine Spezialität der Firma wurden. Er malte Landschaftspanoramen und zeichnete je nach Auftrag weitere Informationen darauf, so etwa Bergbahnen. Diese Überblickskarten sind in Tourismusregionen heute noch beliebt.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "88",
        "Person": "Louis Koller",
        "Lebensdaten": "1904-1978",
        "Infotext": "Der in Lenzburg geborene Louis Koller machte bei Trüb & Co. in Aarau eine Lehre als Grafiker. Trüb war damals bekannt für den Plakatdruck. Viele Entwürfe entstanden im hauseigenen Grafikatelier. Kollers Lehrmeister war der Maler und Grafiker Otto Ernst. Nach einem Studienaufenthalt in Paris kam Louis Koller zu Trüb zurück und löste Ernst als Hausgrafiker ab. Kollers erfolgreichstes Produkt waren die Vogelschaukarten, die eine Spezialität der Firma wurden. Er malte Landschaftspanoramen und zeichnete je nach Auftrag weitere Informationen darauf, so etwa Bergbahnen. Diese Überblickskarten sind in Tourismusregionen heute noch beliebt. Louis Koller war mit Elsa Keller verheiratet und hatte zwei Kinder. Als seine Frau starb, heiratete er ein zweites Mal und zog mit über 70 nach Köln.\r"
    },
    {
        "DateinameBild": "Winteler_Jost.jpg",
        "Inventar Nr": "2005.08.29.S302",
        "Ref_ Nr_": "15.11.52; 134",
        "Künstler": "Emmi Roth",
        "Bezeichnung": "Portrait von Prof. Jost Winteler (1846-1929), posthum, ev. nach Fotografie?",
        "Zusatz": "Logisgeber von Albert Einstein, 1890er-Jahre",
        "Datierung": "20. Jh.",
        "Signatur": "",
        "Beschriftung": "Hinten o. r. (Bleistift): \"Prof. Winteler\". Hinten u. l. (Bleistift): \"E. Roth, frs. 180.-\". Hinten u. r. (Tinte): \"gemalt/von Frl. Roth/Aarau\". Hinten o. l. (Etikette): \"134/Stadt Aarau\" u. (Etikette): \"Buchbinderei/Papeterie/S. Schatzmann/Aarau\". Hinten o. r. (Tinte): \"15.XI.52\"",
        "Material": "Öl, Textil, Karton, Holz",
        "Systematik": "Männerportrait, Brustbildnis, Kunst, Malerei, Winteler, Einstein",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Öl auf Textilbildträger, auf Karton aufgezogen. Zierrahmen Holz, profiliert, reliefiert u. vergoldet.",
        "Massangaben": "38.6 x 31.2 cm Lichtmass 44.3 x 36.8 cm Zierrahmenmass",
        "Beschreibung": "Der Historiker und Linguist bot Albert Einstein während seiner Gymnasialzeit in Aarau Logis und wurde zu dessen Mentor.",
        "Erwerbsart": "Ankauf durch die Stadt Aarau",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "56",
        "Person": "Jost Winteler",
        "Lebensdaten": "1846-1929",
        "Infotext": "Jost Winteler promovierte mit einer sprachwissenschaftlichen Arbeit über die Kerenzer Mundart seines Heimatkantons Glarus. Zwischen 1884 und 1914 war er an der Kantonsschule in Aarau Lehrer für Latein, Geschichte und Religion. Zudem betätigte er sich als Ornithologe. Er hielt Vögel und veröffentlichte Abhandlungen über Singvögel in der von ihm gegründeten Zeitschrift „Die Tierwelt“. In Erinnerung geblieben ist Winteler insbesondere aufgrund seiner Beziehung zu Albert Einstein. 1895/96 logierte Einstein während seiner Aarauer Gymnasialzeit im Haus der elfköpfigen Familie Winteler. Positive Gefühle waren mit der Zeit bei „Papa Winteler“, wie er ihn in einem Brief an seine Schwester einst nannte, verbunden. In Wintelers Tochter Marie fand Einstein seine erste Liebe. Einsteins einzige Schwester Maja heiratete einen Sohn von Jost Winteler.\r"
    },
    {
        "DateinameBild": "Gysi_Friedrich.jpg",
        "Inventar Nr": "2005.09.05.F304",
        "Ref_ Nr_": "10.VI.95; 10.6.95",
        "Künstler": "Friedrich Gysi (Aarau 1796 - 1861 Aarau)",
        "Bezeichnung": "Fotografie: Portrait von Johann Rudolf Wehrli in Küttiger Männertracht",
        "Zusatz": "Gemeindeammann. Pflegevater von Herrn Dr. Heinrich Bircher (1850-1923). Der letzte Küttiger, der die Tracht trug.",
        "Datierung": "um 1860",
        "Signatur": "Vorne u. M. in Passepartout (Prägestempel): \"Photographie/Gysi & Co/Aarau\"",
        "Beschriftung": "Hinten (Bleistift): \"Küttiger Männertracht/Vater des Herrn Dr. Heinr. Bircher, der letzte/Küttiger, welcher die Tracht trug.\" u. \"Gesch. v. H. D. Bircher.\" u. \"10.VI.95\"",
        "Material": "Papier, Karton, Holz, Glas",
        "Systematik": "Männerportrait, Küttigen, Tracht, Mode, Bircher, Kunst, Foto",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Fotografie, s/w Abzug auf Papier. Zierrahmen Holz, profiliert, schwarz gefasst u. vergoldet. In Passepartout aus Karton",
        "Massangaben": "46.3 x 40 cm (oval) Zierrahmenmass 34.5 x 28.5 cm (oval) Lichtmass 24.7 x 19.8 cm (oval) Darstellungsmass",
        "Beschreibung": "Johann Rudolf Wehrli war Gemeindeammann, Pflegevater von Herrn Dr. Heinrich Bircher (1850-1923) und der letzte Küttiger, der die Tracht trug.  Friedrich Gysi war Reisszeugfabrikant und Aarauer Fotopionier. Nach dem Erlernen der Daguerreotypie-Technik eröffnete er ein Portrait-Studio.",
        "Erwerbsart": "Schenkung von Herrn D. Bircher",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "",
        "Person": "",
        "Lebensdaten": "",
        "Infotext": "\r"
    },
    {
        "DateinameBild": "Hassler_Ferdinand_Rudolf.jpg",
        "Inventar Nr": "2005.11.07.S307",
        "Ref_ Nr_": "13.05.03; Doll & Richards B 13252",
        "Künstler": "Charles Fenderich (Laufenburg (AG) 1805 - um 1887 San Francisco?)",
        "Bezeichnung": "Portrait von Ferdinand Rudolf Hassler (1770-1843)",
        "Zusatz": "Geograph. Vermesser der Ostküste der Vereinigten Staaten. Professor und Mitbegründer der Militärakademie Westpoint.",
        "Datierung": "1841",
        "Signatur": "Vorne u. l. (in Druck): \"From Life on Stone by Chs. Fenderich/1841\"",
        "Beschriftung": "Vorne u. M. (in Druck): \"F.R.HASSLER./Superintendent of the U.S.Coast Survey/born at Aarau in Switzerland.\" Hinten o. M. (Etikette): \"Doll & Richards. Inc./140 Newbury Street Boston. Mass./...No. B 13252\". Hinten u. l. (Etikette): \"13.05.03/Stadtmuseum Schlössli/Property of F. Homburger/5000 Aarau, via/Hist. Mus. St. Gallen, Schenkung\"",
        "Material": "Farbe, Papier, Holz, Glas",
        "Systematik": "Männerportrait, Brustbildnis, Hassler, Geographie, Kunst, Druckgraphik",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Druckgraphik. Lithographie auf Papier. Zierrahmen Holz, profiliert",
        "Massangaben": "45.7 x 39.4 cm Zierrahmenmass 40.8 x 34.5 cm Lichtmass 32.8 x 26.6 cm Darstellungsmass",
        "Beschreibung": "Geograf und Vermesser der Ostküste der Vereinigten Staaten. Er war Professor und Mitbegründer der Militärakademie Westpoint.",
        "Erwerbsart": "Schenkung via Historisches Museum St. Gallen",
        "Herkunft": "\"Property of F. Homburger/5000 Aarau, via/Hist. Mus. St. Gallen, Schenkung\"",
        "Raumverweis": "",
        "NummerObjektteppich": "90",
        "Person": "Ferdinand Rudolf Hassler",
        "Lebensdaten": "1770-1843",
        "Infotext": "Schon während des Studiums in Mathematik und Physik half Ferdinand Rudolf Hassler seinem Professor Johann Georg Tralles bei Vermessungen. 1798 bekam er von der Helvetischen Republik den Auftrag, die Schweiz zu vermessen. Er erstellte ein Verzeichnis mit 51 Koordinatenpunkten zwischen Yverdon und Schaffhausen. Als nach der Auflösung der Helvetischen Republik 1803 die Vermessung an französische Geografen übergeben wurde, sah sich Hassler in einer beruflichen Sackgasse. Er wanderte 1805 in die USA aus, um „nach mehr Zuverlässigkeit in einem friedlichen Land“ zu suchen. Der amerikanische Präsident Jefferson beauftragte ihn mit der Vermessung der Küsten. Hassler kam zunächst nur schleppend voran und wurde abgesetzt. Vierzehn Jahre später genehmigte der Kongress die Weiterführung. Endlich konnte Hassler sein Können unter Beweis stellen. Bis zu seinem Tod vermass Hassler 8000 Quadratmeilen Küste mit fünf Schiffen und 90 Mitarbeitern.\r"
    },
    {
        "DateinameBild": "Ruetschi_Jakob_Sebastian_02.jpg",
        "Inventar Nr": "2006.03.01.W028",
        "Ref_ Nr_": "6.X.49/1; 241; 2007.09.14.N313",
        "Künstler": "Jakob Rüetschi, Giesserei (Aarau)",
        "Bezeichnung": "Modell einer Vorderlader-Kanone mit Protze",
        "Zusatz": "",
        "Datierung": "1873",
        "Signatur": "",
        "Beschriftung": "unten auf Protze: \"Rothpletz. Aarau. 1873\"",
        "Material": "Messing, Holz",
        "Systematik": "Modellgeschütze, Kanonen",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "29 x 106 x 25 cm Objektmass Messingrohr 38 cm lang Objektmass Räder 28 cm Durchmesser",
        "Beschreibung": "Die Glockengiesserei in Aarau hat eine 700-jähriger Tradition. Jakob Rüetschi (1784-1851) aus Suhr übernahm gemeinsam mit seinem Bruder Sebastian 1824 die Giesserei am Rain, nach deren Familie die Giesserei noch heute benannt ist.  Ein bedeutender Geschäftszweig war im 19. Jahrhundert das Giessen von Kanonen für die Schweizer Armee, die mit Modellen wie der Vorderladerkanone angepriesen wurden. Das 1873 gefertigte Modell markiert allerdings eine Zäsur in der Geschichte der Giesserei. In jenem Jahr erfolgte die letzte Lieferung von Kanonen, denn diese fielen dem technischen Fortschritt zum Opfer. Nicht verkaufte Geschütze wurden eingeschmolzen und etwa für Denkmäler – dem neuen Geschäftszweig der Firma – verwendet.",
        "Erwerbsart": "Geschenk von Herrn Werner Rothpletz, Largitzenstrasse 51, Basel",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "30",
        "Person": "Jakob Rüetschi",
        "Lebensdaten": "1784-1851",
        "Infotext": "Jakob Rüetschi aus Suhr übernahm gemeinsam mit seinem Bruder Sebastian 1824 die Giesserei am Rain von Johann-Heinrich Baer. Der Betrieb wurde erfolgreich weitergeführt. Jakob und die folgenden zwei Rüetschi- Generationen belieferten Kirchen von St.Gallen bis Genf mit Glocken. Nach Jakobs Tod übernahm 1851 sein Sohn Emanuel den Betrieb. Ein bedeutender Geschäftszweig war im 19. Jahrhundert das Giessen von Kanonen für die Schweizer Armee, die mit Modellen wie der Vorderladerkanone angepriesen wurden. Das 1873 gefertigte Modell markiert allerdings eine Zäsur in der Geschichte der Giesserei. In jenem Jahr erfolgte die letzte Lieferung von Kanonen, denn diese fielen dem technischen Fortschritt zum Opfer. Nicht verkaufte Geschütze wurden eingeschmolzen und etwa für Denkmäler – dem neuen Geschäftszweig der Firma – verwendet. Die Firma Rüetschi produziert noch heute Glocken in Aarau.\r"
    },
    {
        "DateinameBild": "Frey_Robert.jpg",
        "Inventar Nr": "2006.04.05.T003",
        "Ref_ Nr_": "",
        "Künstler": "Müller & Trüb (Aarau 1884 - 1903)",
        "Bezeichnung": "Plakat: Swiss Milk Chocolate / R. & M. Frey / Aarau Switzerland; Chocolat Frey",
        "Zusatz": "",
        "Datierung": "um 1900",
        "Signatur": "u. M. \"Müller & Trüb, Aarau (Switzerland)\"",
        "Beschriftung": "",
        "Material": "Papier, Aluminium",
        "Systematik": "Plakate, Werbung, Nahrungsmittel, Süsswaren, Schokolade",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Farblithographie. In Wechselrahmen aus Aluminium.",
        "Massangaben": "50.8 x 30 cm Blattmass",
        "Beschreibung": "Robert Frey (1861-1940) gründete gemeinsam mit seinem Bruder Max die \"Chocolat Frey\". Ihre Swiss Milk Chocolate vertrieben die Brüder auch im Ausland. Da durften englische Werbeplakate nicht fehlen.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "38",
        "Person": "Robert Frey",
        "Lebensdaten": "1861-1940",
        "Infotext": "In Paris und Horgen erlernte der Aarauer das Handwerk des Chocolatiers, bevor er sich 1887 als selbstständiger Schokoladenhersteller in Aarau niederliess. Er quartierte sein Unternehmen in den leer stehenden Fabrikräumen der chemischen Fabrik und Weberei Frey & Comp. in der Telli ein. Mit seinen Mühlen und Maschinen stellte er Tafelschokolade, Schokoladenpulver, Suppen und Kraftnährmittel her. 1888 konnte Robert Frey seinen Bruder Max als Mitarbeiter gewinnen. Gemeinsam führten sie die Fabrik bis 1906 unter dem Namen „R. & M. Frey“, später als „Chocolat Frey AG“. Einmal pro Tag transportierte man die Schokoladentafeln im Pferdewagen von der Telli den Berg hinauf. Dort wurden sie in einem herrschaftlichen Haus am Balänenweg verpackt und per Bahn verschickt. Ihre „Swiss Milk Chocolate“ vertrieben die Brüder auch im Ausland. Da durften englische Werbeplakate nicht fehlen.\r"
    },
    {
        "DateinameBild": "Raeber_Jakob.jpg",
        "Inventar Nr": "2006.04.12.W026",
        "Ref_ Nr_": "",
        "Künstler": "Jakob Raeber (1590 - 1650)",
        "Bezeichnung": "Hakenbüchse oder Arkebuse",
        "Zusatz": "",
        "Datierung": "1610",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Stahl, Bein, Holz, Luntenschnur",
        "Systematik": "Schusswaffen, Luntenschloss, Arkebuse, Arquebuse, Gewehr, Prunkwaffen",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "Länge 166 cm Objektmass",
        "Beschreibung": "Jakob Raeber war einer der besten Aarauer Büchsenmacher seiner Zeit. Die prunkvolle Hakenbüchse aus dem Jahre 1610 wurde bei repräsentativen Anlässen wie Schützenfesten und Umzügen verwendet, während für die kriegerischen Auseinandersetzungen wesentlich schlichtere Varianten eingesetzt wurden.  Schwerer 8-kantiger Lauf mit Röhrenvisier mit längsrechteckigem Korn, schmalrechteckige Luntenschlossplatte mit gebogenem Luntenhahn in Elefantenkopfmanier, mit eingeklemmter Lunte und Schraubenzwinge. Verschliesbare Pulverpfanne mit vertikaler Sicherheitsplatte. Lauf auf Oberseite datiert 1610 mit Aarauerschlag. Hinten links und rechts Marke des Aarauer Büchsenschmiedes Jakob Raeber (I), erwähnt 1592 und 1656, (letzteres betrifft wohl seinen Sohn Jakob (II), der starb 1656 in der 1. Villmergerschlacht). Raebers Meisterschlag in Wappenform mit Sichel auf Dreipass mit den Buchstaben JR. Reiche Schaftverbeinung mit geometrischen Mustern, Mascarons und S-förmigen Voluten, stählerner Abzugbügel mit zusätzlichem rückwärtigem Bügel. Holzladestock vorhanden. Verbeinte Kolbenkappe. Aarauerbrand (Adler).",
        "Erwerbsart": "nicht bekannt",
        "Herkunft": "München/Sammler",
        "Raumverweis": "",
        "NummerObjektteppich": "41",
        "Person": "Jakob Raeber",
        "Lebensdaten": "ca. 1590-1650",
        "Infotext": "Jakob Raeber war einer der besten Aarauer Büchsenmacher seiner Zeit. Die prunkvolle Hakenbüchse aus dem Jahre 1610 wurde bei repräsentativen Anlässen wie Schützenfesten und Umzügen verwendet, während für die kriegerischen Auseinandersetzungen wesentlich schlichtere Varianten eingesetzt wurden. Durch die Kunst des Büchsenmachens waren Jakob Raeber und seine Familie allerdings nicht vor Kriegseinsätzen gefeit. Raebers Sohn, ein Wachtmeister, war einer von jenen 170 Aarauern, die 1655 im 1. Villmergerkrieg auf Begehren Berns mit der Aarauer Stadtfahne gegen die Innerschweizer ins Feld zogen. Er musste dort sein Leben lassen.\r"
    },
    {
        "DateinameBild": "Henz_Daniel_02.jpg",
        "Inventar Nr": "2006.06.27.S003",
        "Ref_ Nr_": "",
        "Künstler": "Daniel Henz (Aarau 1777 - 1841)",
        "Bezeichnung": "Obstmesser, Henz, in Schatulle",
        "Zusatz": "",
        "Datierung": "1800 - 1830",
        "Signatur": "",
        "Beschriftung": "auf Klinge: \"[Stempel] HENZ\". Innenseite Deckel, auf Seide aufgedruckt: \"E. GSCHWIND/MESSERSCHMIED/BERN.\"",
        "Material": "Stahl, Ebenholz, Holz, Papier",
        "Systematik": "Küche, Besteck, Messerschmiede, Henz, Dessertmesser",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "Schatulle: 3.8 x 22.8 x 22.8 cm Objektmass Messer: 19 cm Objektmass",
        "Beschreibung": "Messer mit Ebenholzgriffen. In Schatulle aus Holz, mit Papier bezogen, mit Velours und Seide ausgeschlagen.  Daniel Henz galt zu seiner Zeit als bester Messerschmied in Aarau.",
        "Erwerbsart": "Ankauf bei Martin Kiener, Neumarkt 23, 8001 Zürich, für Helvetik-Ausstellung",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "52",
        "Person": "Daniel Henz",
        "Lebensdaten": "1777-1841",
        "Infotext": "Daniel Henz gehörte der grössten Messerschmied-Dynastie in Aarau an. Achtzehn Männer der Familie Henz erlernten vom 17. bis zum 19. Jahrhundert das Handwerk. Auf dem Platz Aarau waren die Messer von Daniel Henz begehrt. So zählte etwa Heinrich Remigius Sauerländer zu seinen Kunden. Gemäss einer Rechnung von 1818 verlangte Henz für zwölf Tischmesser und Gabeln mit Ebenholzgriffen und Silbermanschetten 36 Franken. Henz bedauerte den Rückgang des Berufsstandes der Messerschmiede in Aarau, wie er in einem Antrag für ein Wasserrad beteuerte. Deshalb vermachte er den jährlichen Zins aus seinem Erbe den zwei oder drei bedürftigsten Messerschmiedemeistern von Aarau. Der andere Teil ging an die Waisenanstalt. Da Henz unverheiratet blieb, ging die Dynastie mit dem Tod seines Neffen unter.\r"
    },
    {
        "DateinameBild": "Bolens_Ernest.jpg",
        "Inventar Nr": "2006.06.28.S308",
        "Ref_ Nr_": "",
        "Künstler": "Ernest Albert Bolens (Müllheim (Thurgau) 1881 - 1959 Basel)",
        "Bezeichnung": "Portrait der jungen Agathe Bolens (*1915) in grünem Kleid",
        "Zusatz": "",
        "Datierung": "1928",
        "Signatur": "Vorne u. r. (Pinsel): \"Bolens./28\"",
        "Beschriftung": "Hinten Keilrahmen o. r. (Bleistift): \"Agathe * 1915\"; o. M. (Etikette): \" 68 Mädchenbildnis\"",
        "Material": "Oelfarbe, Leinwand, Holz",
        "Systematik": "Mädchenportrait, Halbfigurbildnis, Bolens, Kunst, Malerei",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Oel auf Leinwand. Keilrahmen Holz",
        "Massangaben": "65 x 54 cm Keilrahmenmass",
        "Beschreibung": "Das Portrait zeigt die Tochter des Künstlers.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "1",
        "Person": "Ernest Bolens",
        "Lebensdaten": "1881-1959",
        "Infotext": "An der Gewerbeschule in Aarau schloss Ernest Bolens eine Lehre als Dekorationsmaler ab. Als in den Vorkriegsjahren das Interesse an der im Jugendstil verhafteten Dekorationsmalerei abnahm, wandte sich Bolens der Kunstmalerei zu. Er malte in Öl, aquarellierte und zeichnete gegenständlich. Er wurde beeinflusst von Hodler, Amiet, den französischen Impressionisten und Cézanne. 1913 erhielt er das Bundesstipendium für einen Aufenthalt in Paris. Mit dem Ausbruch des Ersten Weltkrieges kehrte Ernest Bolens in die Schweiz zurück, liess sich in Basel und 1927 in Binningen nieder. Neben Landschaften und Stillleben zeichnen Porträts sein künstlerisches Werk aus. Eines der Porträts zeigt seine Tochter Agathe. Sie vermachte dem Stadtmuseum Aarau einen Grossteil ihres Vermögens, das für die Neueinrichtung der Dauerausstellung genutzt wurde.\r"
    },
    {
        "DateinameBild": "Rohner_Fredy.jpg",
        "Inventar Nr": "2006.07.03.S003",
        "Ref_ Nr_": "",
        "Künstler": "Fredy Rohner (1936 - 2014)",
        "Bezeichnung": "Videofilmkassette VHS: Das reale Leben der Telli-Hochhäuser",
        "Zusatz": "",
        "Datierung": "2003",
        "Signatur": "",
        "Beschriftung": "Klebeetikette: \"Das reale Leben der Telli-Hochhäuser/Fredy Rohner/2003/Herbst\"",
        "Material": "Kunststoff",
        "Systematik": "Video, Film, Telli",
        "Geogr_ Bezug": "Aarau",
        "Technik": "VHS Videofilm",
        "Massangaben": "",
        "Beschreibung": "Fredy Rohner fans sein Wohnquartier durch eine Dokumentation des Schweizer Fernsehens über die Telli falsch widergegeben. Er realisierte einen \"Gegenfilm\", der 2004 auf dem Telliplatz gezeigt und unter grossem Interesse im GZ Telli am 28.6.2006 nochmals vorgeführt wurde.",
        "Erwerbsart": "Kauf",
        "Herkunft": "Fredy Rohner, Rütmattstr. 13, Aarau",
        "Raumverweis": "",
        "NummerObjektteppich": "83",
        "Person": "Fredy Rohner",
        "Lebensdaten": "1936-2014",
        "Infotext": "Der KV-Angestellte aus Zurzach kam der Arbeit wegen nach Aarau. Ab 1977 lebte Fredy Rohner in der Telli. Und er liebte sein Quartier. Als das Schweizer Fernsehen 2003 einen Dokumentarfilm über die Telli ausstrahlte, erregten sich die Gemüter. Die anfängliche Freude schlug bei vielen in Enttäuschung um. Zu negativ, ja gar als Ghetto sei ihr Quartier dargestellt worden. So fasste der Amateurfilmer Fredy Rohner den Entschluss, einen Gegenfilm zu realisieren, der die Vielfalt der Telli dokumentieren sollte. In seinem filmischen Rundgang durch das Quartier zeigt er Orte, welche die hohe Lebensqualität der Telli sichern: das Gemeinschaftszentrum, der Kleintierzoo, Einkaufsläden, die Primarschule, das Zentrum für Behinderte und die Aare. 2004 wurde der private Gegenfilm auf dem Telliplatz gezeigt.\r"
    },
    {
        "DateinameBild": "Fisch_Hans_Ulrich_II.jpg",
        "Inventar Nr": "2006.08.07.S341",
        "Ref_ Nr_": "18",
        "Künstler": "Hans Ulrich Fisch II., zugeschrieben (Aarau 1613 - 1686 Aarau)",
        "Bezeichnung": "Plan des Stadtbachs von seinen Quellen bis gegen die Suhrer Kirche",
        "Zusatz": "Entstanden in Zusammenhang mit Wässerungsstreitigkeiten zwischen den Aarauern und denen von Suhr.",
        "Datierung": "1646",
        "Signatur": "Vorne M. (Feder): \"1646.\"",
        "Beschriftung": "Vorne o. r. (Feder): \"No 18. \"Hinten u. M. (Etikette): \"Werner Hiltbrunner/Aarau Rain 30\"",
        "Material": "Farbe, Papier, Karton, Holz, Leinwand",
        "Systematik": "Plan, Stadtbachquellen, Wehrbauten, Unterentfelden, Suur, Kunst, Zeichnung",
        "Geogr_ Bezug": "Aargau",
        "Technik": "Zeichnung. Feder auf Papier, blau u. rotbraun aquarelliert. Mehrmals gefaltet. Auf Leinwand aufgezogen. In Passepartout aus Karton. Zierrahmen Holz, profiliert u. gefasst",
        "Massangaben": "42.2 x 144.8 x 2.3 cm Rahmenmass 35.6 x 138.5 cm Lichtmass ca. 20.5 x 123.5 cm Blattmass",
        "Beschreibung": "Plan des Stadtbachs von seinen Quellen bis gegen die Suhrer Kirche, samt zugehörigen Wasserläufen und Wuhren. Links oben Häuser mit Beschriftung \"Nider/endtfelden\", rechts oben Kirche mit Beschriftung \"Die Surer Kilche\". Entstanden in Zusammenhang mit Wässerungsstreitigkeiten zwischen Aarau und Suhr.  Hans Ulrich Fisch II. war Glasmaler und Maler aus der Malerdynastie Fisch. Er und sein Vater zeichneten die ersten Ansichten der Stadt Aarau.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "22",
        "Person": "Hans Ulrich Fisch",
        "Lebensdaten": "1613-1686",
        "Infotext": "Mit seinem Vater und seinem Bruder stellte Hans Ulrich Fisch Wappenscheiben und Kirchenfenster her. Letztere sind bis heute in den Kirchen Aaraus und der Umgebung zu sehen. Vater und Sohn machten sich auch als Zeichner von Stadtansichten und Plänen einen Namen. Ihnen verdanken wir die ersten detaillierten Darstellungen der Stadt Aarau. 1646 erhielt Hans Ulrich Fisch den Auftrag, den Verlauf des Aarauer Stadtbachs bis nach Suhr aufzuzeichnen. Grund dafür war ein Wässerungsstreit, der für das Spätmittelalter und die frühe Neuzeit typisch war. Der Aarauer Stadtbach bezog sein Quellwasser aus dem Suhrer Gemeindegebiet. Nutzten die Suhrer das Quellwasser zur Bewässerung, floss weniger Wasser nach Aarau, was die Aarauer vor Wut kochen liess. Der Plan von Fisch sollte eine Grundlage bieten, den Rechtsstreit zu schlichten.\r"
    },
    {
        "DateinameBild": "Trefzger_Gustav_jun_01.jpg",
        "Inventar Nr": "SF 2010-0006",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Fotografie: Versuchsfärberei in der chem. Reinigungsanstalt Trefzger am Storchengässli 8",
        "Zusatz": "",
        "Datierung": "20.Jh. 2.H.",
        "Signatur": "",
        "Beschriftung": "Vorne (Druck): \"Versuchs Färberei\", Hinten (Etikette): \"Spezialgeschäft für Einrahmungen/Buchdruckerei - Buchbinderei/Eduard Käser ESKA Aarau AG./Bachstrasse 9 - Telefon (064) 2 42 84\"",
        "Material": "Papier, Karton, Holz, Glas",
        "Systematik": "Kunst, Fotografie, Gewerbe",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Fotografie, Silbergelatineentwicklungspapier. Zierrahmen Holz, vergoldet. Auf Karton geklebt.",
        "Massangaben": "28 x 32.9 cm Zierrahmenmass 26.6 x 30.7 cm Lichtmass 17.5 x 23.2 cm Bildmass",
        "Beschreibung": "Gustav Trefzger (1907-1991) betrieb zusammen mit seiner Frau am Färberplatz bis 1970 die letzte Färberei in Aarau. Da keine Nachfolge in Aussicht war, verkaufte Trefzger den Platz 1970 schweren Herzens an die Stadt.",
        "Erwerbsart": "Schenkung von Frau Annelies Müller-Trefzger, Kirchenfeldstrasse 44, 3005 Bern",
        "Herkunft": "Frau Müller ist die Tochter von Gustav Trefzger, dem Inhaber der Färberei, und ist in dem Haus am Storchengässli 8 aufgewachsen.",
        "Raumverweis": "",
        "NummerObjektteppich": "31",
        "Person": "Gustav Trefzger",
        "Lebensdaten": "1907-1991",
        "Infotext": "Schon während des Ersten Weltkrieges half Gustav Trefzger in der Chemischen Reinigung und Militärwäscherei seines Vaters. Er bildete sich zum Chemischen Reiniger und Färber aus und übernahm 1938 den Betrieb zwischen dem Färbergässli und dem Storchengässli. In den 1960er-Jahren zählte dieser 25 Angestellte. Seine zweite Frau Dora Trefzger-Türler übernahm die Buchhaltung und die Arbeit im Laden. Nicht selten arbeitete das Paar am Wochenende, etwa um für Beerdigungen Kleider schwarz einzufärben. Das Färben von Spitzenstoffen gehörte zu Trefzgers Spezialitäten. Er tüftelte an der perfekten Mischung, bis der Stoff die gewünschte Farbe hatte. Seine Passion reichte so weit, dass er seinen weissen Pudeln in der Fasnachtszeit jeweils einen bunten Pelz bescherte. Da keine Nachfolge in Aussicht war, verkaufte Trefzger den Platz 1970 schweren Herzens an die Stadt. Sie hegte bereits 1964 Pläne, auf dem heutigen Färberplatz eine verkehrsfreie Zone einzurichten.\r"
    },
    {
        "DateinameBild": "Reckziegel_Anton.jpg",
        "Inventar Nr": "SF 2010-0015",
        "Ref_ Nr_": "",
        "Künstler": "Anton Reckziegel (Gablonz 1865 - 1936 Mödling bei Wien) Gysi & Co. (Aarau 1889 - 1910 Aarau)",
        "Bezeichnung": "Mosaikfotografie: \"Nachtlager in Granada\"",
        "Zusatz": "vgl. SF 2010-0016",
        "Datierung": "1893 - 1898",
        "Signatur": "Vorne u. l.: \"Gemalt Anton Reckziegel\", vorne u. r.: \"Photoatelier Gysi & Co.\"",
        "Beschriftung": "",
        "Material": "Papier, Karton",
        "Systematik": "Kunst, Mosaikfotografie, Fotokomposition",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Fotografie, s/w Abzug auf Papier, übermalter Karton. In Zierrahmen aus Holz.",
        "Massangaben": "100 x 132 cm Bildmass",
        "Beschreibung": "Vereinsbild der Kreutzer-Oper \"Nachtlager in Granada\" des Cäcilienvereins. Die Oper wurde 1897 im Aarauer Saalbau aufgeführt. Der böhmische Lithograph Anton Reckziegel, der zwischen 1893 und 1898 bei Trüb arbeitete, verewigte die Akteure dieses spektakulären Kulturereignisses in einer südländischen Fantasielandschaft. Dafür montierte er Fotos von Gysi, der die Schauspieler zuvor zu Szenen gruppiert fotografiert hatte, und übermalte sie dann mit der Landschaft. Nur die Gesichter blieben als Original unverändert erhalten.",
        "Erwerbsart": "Ankauf von Heinz Fröhlich, Schulhausstrasse 18, 5116 Schinznach",
        "Herkunft": "Heinz Fröhlich hat die beiden Mosaikfotografien (SF 2010-0015 und SF 2010-0016) ca. 1995 auf einem Sammler- und Antiquitätenmarkt in Zürich erstanden. Vgl. Foto im Multimediamodul.",
        "Raumverweis": "",
        "NummerObjektteppich": "68",
        "Person": "Anton Reckziegel",
        "Lebensdaten": "1865-1936",
        "Infotext": "Der Lithograf Anton Reckziegel kam 1893 aus Wien nach Aarau. Bei der Kunstanstalt Müller & Cie spezialisierte er sich auf das Zeichnen von Plakaten und Postkarten. Als der Aarauer Cäcilienverein 1897 die Oper „Das Nachtlager von Granada“ aufführte, verewigte Reckziegel die Mitglieder des Vereins in einem Porträt. Er fertigte eine Collage mit den Fotografien der Darsteller in einer gemalten südländischen Landschaft an. Die Aufführung im grossen Konzertsaal des Aarauer Saalbaus mit 150 Mitwirkenden wurde als grosses Kulturereignis gefeiert. Anton Reckziegel zog 1902 nach Bern, wo er sich selbstständig machte. Er entwarf Plakate für die Jungfraubahn, das Berner Oberland oder Zermatt.\r"
    },
    {
        "DateinameBild": "Mueller_Edouard_01.jpg",
        "Inventar Nr": "SF 2011-0030",
        "Ref_ Nr_": "",
        "Künstler": "Eduard Müller, gehörte (1854 - 1915)",
        "Bezeichnung": "Laterna Magica \"Newton's Patent Lantern\", mit Transportbox",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Holz, Metall",
        "Systematik": "Laterna Magica, Projektionsgerät, Zauberlaterne",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "aussen 24 x 51.5 x 20 cm Objektmass",
        "Beschreibung": "Eduard Müller (1854-1915) war Schirmfabrikant mit Geschäft an der Rathausgasse und Amateurfotograf. Mit seiner Laterna Magica soll er Bilder an die Fassaden der Nachbarhäuser projiziert haben.",
        "Erwerbsart": "Schenkung von Martin Kundert (Urenkel von Eduard Müller), Oetlisbergstrasse 34, 8053 Zürich",
        "Herkunft": "Familiennachlass Eduard Müller",
        "Raumverweis": "4. OG",
        "NummerObjektteppich": "96",
        "Person": "Eduard Müller",
        "Lebensdaten": "1854-1915",
        "Infotext": "\r"
    },
    {
        "DateinameBild": "Frey-Herzog_August_01.jpg",
        "Inventar Nr": "SF 2014-0092",
        "Ref_ Nr_": "",
        "Künstler": "Frey & Co., Chemische Erzeugnisse (Aarau 1812 - 1932)",
        "Bezeichnung": "Reisekamera \"1172\", Frey & Co. Aarau",
        "Zusatz": "",
        "Datierung": "um 1880",
        "Signatur": "",
        "Beschriftung": "auf Kamera: \"Frey & Co./Aarau\" u. \"1172\". Auf Objektiv: \"E. Suter Basle/No. 5901\"",
        "Material": "Holz, Balgen aus Karton, Metall",
        "Systematik": "Fotoapparat, Reisekamera, Plattenkamera",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "offen: 30 x 18.5 x 51.5 cm Objektmass",
        "Beschreibung": "Reisekamera, Frey & Co., Aarau, mit drei Holzkassetten für Fotoplatten. Edelholzgehäuse mit Messingbeschlägen, Objektiv E. Suter, Basel, schwarzer quadratischer Balgen.  August Frey-Herzog (1837-1908) übernahm die chemische Fabrik und Weberei seines Grossvaters. 1883 liess er diese unter dem Namen „Firma Frey & Comp.“ ins Handelsregister eintragen. Er stattete Fotokameras mit Objektiven aus, stellte Fotobedarfsartikel und eigene Fotoapparate her.",
        "Erwerbsart": "nicht bekannt",
        "Herkunft": "unbekannt",
        "Raumverweis": "",
        "NummerObjektteppich": "37",
        "Person": "August Frey-Herzog",
        "Lebensdaten": "1837-1908",
        "Infotext": "Nach Abschluss der Gewerbeschule in Zürich und Studien in Chemie in Paris liess sich August Frey in seiner Heimatstadt Aarau nieder. Er übernahm die chemische Fabrik und die Weberei seines Grossvaters. 1883 liess er diese unter dem Namen „Firma Frey & Comp.“ ins Handelsregister eintragen. Schon bald reagierte Frey auf die Begeisterung für die aufkommende Fotografie. Er stattete Fotokameras mit Objektiven aus, stellte Fotobedarfsartikel und eigene Fotoapparate her. Die Aargauer Klientel war begeistert. Der Industrielle Hermann Kummler-Sauerländer liess während seiner Zeit in Brasilien zwei Kisten mit Fotoapparaturen einschiffen, um seinen Aufenthalt zu dokumentieren. Er war derart eingenommen, dass ihm, während er in der Dunkelkammer Bilder entwickelte, sein gesamtes Gepäck entwendet wurde.\r"
    },
    {
        "DateinameBild": "Rothpletz_Christian-Emil.jpg",
        "Inventar Nr": "SF 2014-0123",
        "Ref_ Nr_": "",
        "Künstler": "Gysi & Co. (Aarau 1889 - 1910 Aarau)",
        "Bezeichnung": "Fotoalbum aus der Familie Rothpletz",
        "Zusatz": "",
        "Datierung": "um 1900",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Karton, Papier, Leder, Metall",
        "Systematik": "Fotoalbum, Einsteckalbum, Rothpletz, Visitformat, Familienportraits",
        "Geogr_ Bezug": "Schweiz, Aarau",
        "Technik": "Fotografien, s/w Papierabzüge auf Karton, Carte de Visite, in Einsteckalbum",
        "Massangaben": "6.5 x 15.5 x 17 cm Objektmass",
        "Beschreibung": "Fotoalbum mit Familienportraits, in Leder gebunden, verziert, mit seitlicher Metallschliesse und Goldschnitt. Mit Einschubfächern für Fotografien im Carte-de-visite-Format.  Christian Emil Rothpletz (1824-1897) war Militärhistoriker, Maler, Jurist und erster Präsident des Schweizer Kunstvereins. Seine Kinder waren die letzten Besitzer des Schlössli, sie hinterliessen dem Museum zahlreiche Objekte, darunter auch dieses Fotoalbum.",
        "Erwerbsart": "nicht bekannt",
        "Herkunft": "unbekannt",
        "Raumverweis": "3. OG",
        "NummerObjektteppich": "42",
        "Person": "Christian Emil Rothpletz",
        "Lebensdaten": "1824-1897",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Wyss_Anna_02.jpg",
        "Inventar Nr": "SF 2014-0630",
        "Ref_ Nr_": "A 77",
        "Künstler": "Unbekannt",
        "Bezeichnung": "IBA-Film: \"Besuch des Bruders des Schahs von Persien\", 17. September 1957",
        "Zusatz": "",
        "Datierung": "1957",
        "Signatur": "",
        "Beschriftung": "Papieretikette auf Originaldose: \"Industr. Betriebe der Stadt Aarau/AA A 77/Spieldauer/6 Min./stumm/schwarzweiss/ AARAU: Besuch des Bruders vom/Herr Schah, Persien/September 1957\". Prägung auf Dose: \"Bell & Howell Co. Chicago/ [Firmenlogo]/Made in U.S.A.\"",
        "Material": "",
        "Systematik": "IBA-Filme, VIP",
        "Geogr_ Bezug": "Aarau",
        "Technik": "schwarz-weiss, stumm",
        "Massangaben": "16 mm Originalformat Objektmass Spieldauer 5:23 Min. Objektmass Dose: 2.4 cm Höhe Objektmass Dose: 18.5 cm Durchmesser",
        "Beschreibung": "Der Bruder des persischen Schah, Gholam-Reza Pahlavi, wird im Schlössli und zu militärischen Vorführungen im Schachen empfangen.  Anna Wiss (1926-2003) führte für die Elektrizitätswerke Aarau (heute IBA) Kochvorführungen durch. Daneben war sie das Herz des firmeneigenen Filmteams, das zu Werbezwecken über 400 Anlässe auf 16-mm-Film und Video in der ganzen Region dokumentierte.",
        "Erwerbsart": "Schenkung IBAarau AG, Obere Vorstadt 37, 5001 Aarau",
        "Herkunft": "Dieser Film wurde dank der Unterstützung von IBAAarau AG und Memoriav, Verein zur Erhaltung des audiovisuellen Kulturgutes der Schweiz, erhalten.",
        "Raumverweis": "",
        "NummerObjektteppich": "58",
        "Person": "Anna Wyss",
        "Lebensdaten": "1926-2003",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Franke_Hedwig.jpg",
        "Inventar Nr": "SF 2015-0004",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Fotoalbum des Francke-Gutes",
        "Zusatz": "",
        "Datierung": "1910 - 1920",
        "Signatur": "",
        "Beschriftung": "Auf hinterer Einbandseite innen u. r. (Klebeetikette): \"Kaiser & Co./Bern\"",
        "Material": "Karton, Papier, Textil",
        "Systematik": "Fotoalbum, Familienportraits, Gönhard Villen, Gönhardgüter, Francke-Gut, Franke-Gut",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Fotografien, s/w Abzüge auf Papier, eingeklebt in Album, teilweise beschriftet",
        "Massangaben": "geschlossen 1.5 x 31 x 22.5 cm Objektmass offen und gestützt 5 x 58.5 x 22.5 cm Objektmass",
        "Beschreibung": "Wilhelm Francke (1877-1935) gründete während des 2. Weltkrieges die Elfa (Elektrochemische Fabrik Francke Aarau), die das in der Stroh- und Textilindustrie verwendete Bleichmittel Wasserstoffperoxid herstellte. Dank dem Vermögen seiner Schwiegerfamilie bauten er und seine Frau Hedwig Zurlinden nach ihrer Hochzeit eine herrschaftliche Villa mit Park, die bis heute als Francke-Gut erhalten geblieben ist.",
        "Erwerbsart": "Übernahme vom Stadtbauamt Aarau, Andreas Jauch",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "13",
        "Person": "Wilhelm Francke",
        "Lebensdaten": "1877-1935",
        "Infotext": "Wilhelm Francke war Waisenkind und wuchs bei einer Tante in Aarau auf. Er begann ein Jus-Studium und verliebte sich in Hedwig Zurlinden, deren Vater die Jura- Cement-Fabriken gründete. Widerwillig stimmte der Schwiegervater 1902 der Heirat zu. Das Paar zog im Gönhard in eine Villa mit Schwimmteich und Tennisplatz, gebaut mit dem Geld der Zurlindens. Wilhelm trat eine Stelle in den Jura-Cement-Fabriken an, aber das Verhältnis zum Schwiegervater blieb schwierig. Seine Militärkarriere bei der Kavallerie bot ihm die Möglichkeit, dem Konflikt immer wieder für einige Wochen zu entfliehen. Mitten im Ersten Weltkrieg erwarb er eine Lizenz für ein neues Verfahren zur Herstellung von Wasserstoff- Peroxyd. Das Bleichmittel war in der Strohindustrie beliebt. Mit der Elfa (Elektrochemische Fabrik Francke Aarau) stand Wilhelm Francke geschäftlich endlich auf eigenen Beinen. Aus einem überlieferten Tagebuch wissen wir, dass Francke gesellige Runden bis in die frühen Morgenstunden liebte, sich für die ersten Automobile begeisterte und mit über vierzig noch Skifahren lernte.\r"
    },
    {
        "DateinameBild": "Erne_Werner_02.jpg",
        "Inventar Nr": "SF 2015-0006",
        "Ref_ Nr_": "",
        "Künstler": "Werner Erne (Zürich *1931)",
        "Bezeichnung": "Fotografie: Szene aus \"Das Paradies oder der Alte vom Berg\", Inszenierung der Aarauer Innerstadtbühne",
        "Zusatz": "",
        "Datierung": "1974",
        "Signatur": "",
        "Beschriftung": "Hinten u. l. (Klebeetikette): \"Digital/PRINTLINE /REPROGRAFIE/BERNER AG/ROHRERSTR. 33/5001 AARAU\"",
        "Material": "Papier, Aluminium",
        "Systematik": "Theaterszene, Innenstadtbühne",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Fotografie, Barytpapier, aufgezogen auf Aluminium",
        "Massangaben": "40.1 x 40.1 cm Bildmass",
        "Beschreibung": "Als Fotograf hielt Werner Erne die Entwicklung der Innerstadtbühne fest, deren Mitbegründer er war.",
        "Erwerbsart": "Fotografie zur Verfügung gestellt von Werner Erne",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "27",
        "Person": "Werner Erne",
        "Lebensdaten": "1931",
        "Infotext": "Nach seiner Ausbildung zum Fotografen zog Werner Erne zu seiner Frau nach Aarau. Er wurde Mitbegründer einer freien Gruppe, die unter der Leitung von Anton Krättli die Aarauer Innerstadtbühne bespielte. Im «Sommerstudio» organisierte Erne Gastspiele von freien Gruppen und schuf Raum für Experimente mit unbekannten Ausdrucksmitteln in Poesie, Theater, Film und Musik. Auf den Handzetteln, mit denen Erne warb, war zu lesen: «Kein Brett vor dem Kopf! Kritik und Diskussion! Aufgeschlossenheit für das Zukünftige in der Gegenwart. » Ernes Bühnenfotografien widerspiegeln die Entwicklung der Gruppe: Die frühe Inszenierung «Die stumme Schönheit» (1965) folgte den Sehgewohnheiten des bürgerlichen Publikums. In «Das Paradies oder der Alte vom Berg» (1974) befremdete ein ungewohnter Ansatz des späteren Leiters Peter Schweiger die Theaterbesucher. Erne blieb der Gruppe bis zur Auflösung 1980 als Fotograf und kritischer Beobachter treu.\r"
    },
    {
        "DateinameBild": "Zschokke_Helmut.jpg",
        "Inventar Nr": "SM 2008-0019-c",
        "Ref_ Nr_": "",
        "Künstler": "Helmut Zschokke (1908 - 1978 Aarau)",
        "Bezeichnung": "Indianer, Zelt, Büffel und Pferde; Spielzeug der Kinder Helmut Zschokkes",
        "Zusatz": "2 Schachteln voller Holzfiguren",
        "Datierung": "1943 - 1960",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Holz, Textil",
        "Systematik": "Spielzeug, Kind, Basteln, Indianer, Pferde",
        "Geogr_ Bezug": "Aarau",
        "Technik": "gesägt, geschnitzt, genagelt, bemalt, genäht",
        "Massangaben": "",
        "Beschreibung": "Der Kommunist, Germanist und Optiker Helmut Zschokke schnitzte für seine Kinder Kasperfiguren, aber auch Indianer mit Bisons oder eine Schafherde.  Ein Karton mit Aufschrift \"Knorritsch\" als Aufbewahrung (36 x 36 x 26 cm), verschiedene Lagen von Figuren, gepolstert mit Zeitungen aus den Jahren 1954 und 1956: \"Aargauer Tagblatt\", \"Naturfreund\" und \"Freier Aargauer\". Laut Aufzeichnung HZ, SM_2008_0019_e: \"Indianerzelt (Ruthli), Sept. 43 Indianer, Squaw, Winona, Minnehaha, Schenandoa, Kaga, Indianerreiter 2 Mustang, 3 Indianerhunde, 1 Büffeltier, 1 Büffelkuh, 1 Büffelkalb.\" \"Weihnachten 1945. Zu Zelt: Untschida - Red Jacket.\" Keine Aufzeichnung, wann die restlichen Figuren dazu kamen: 20 Pferde, sehr lebendig und individuell bemalt, in verschiedensten Haltungen (von 15 x 22 bis 10 x 12 cm) 30 Büffel (von 13.5 x 18 bis 9.5 x 12 cm) Besonders bei den Pferden: Rasen oder Büsche als Steh-Hilfe.  8 Indianerreiter, davon 2 Kinder und 1 Frau,  7 erwachsene Indianer, davon 3 Frauen (eine hat ihr Baby in einer Trage am Rücken), 1 weisser Trapper mit Gewehr, 5 Indianerkinder, davon 3 Mädchen, 2 Jungen (15 x 4 bis 5 x 2 cm)  Alle Figuren sehr sorgfältig ausgestattet mit Frisuren, Kostümen und \"Accessoires\" wie Bogen, Pfeife, Federn.  1 Zelt, stark verbleichte Bemalung: Büffel und Jäger mit Pfeil und Bogen, Sonne, verschiedene Zeichen in rot, gelb, braun. Sehr sorgfältig mit der Maschine genäht, mit Verschnürung und Rauchklappen. Die Zeltstangen fehlen.  Eine 2. Kiste, angeschrieben Cooper & Cos Tea Stores (35 x 28 x 28) hat noch 10 weitere Lagen Figuren zum Themenkreis Indianer. Diese sind lagenweise fotografiert. Das Rindenkanu zuoberst wurde laut Elisabeth Flück nie gewässert. Man spielte in der Stube.  Das Polstermaterial: \"Die Tat\" 1960, \"Freier Aargauer\" 1950/51, \"Avanti\" 1951, \"Aargauer Tagblatt\" 1959, \"Genossenschaftliches Volksblatt 1945\"  Machart aller Figuren wie die anderen unter SM_2008_0019.",
        "Erwerbsart": "Schenkung von Elisabeth Flück, Liebeggerweg 11a, 5000 Aarau, 062 824 14 95 (EF)",
        "Herkunft": "Nachlass Helmut Zschokke (HZ)",
        "Raumverweis": "3. OG",
        "NummerObjektteppich": "53",
        "Person": "Helmut Zschokke",
        "Lebensdaten": "1908-1978",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Glarner_Lili_01.jpg",
        "Inventar Nr": "SM 2008-0099",
        "Ref_ Nr_": "",
        "Künstler": "Lili Zschokke-Glarner (Wildegg 1908 - 1965 Aarau)",
        "Bezeichnung": "Bilderlotto von Lili Zschokke-Glarner",
        "Zusatz": "",
        "Datierung": "1939 - 1950",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Papier und Karton, bemalt",
        "Systematik": "Brettspiel, Bilderlotto, Kind, Zeichnung, Spielzeug, Basteln",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Tinte, aquarelliert",
        "Massangaben": "Kartonschachtel: 6.8 x 33 x 20 cm Objektmass Tafeln je 16.8 x 26.3 cm Kartonmass",
        "Beschreibung": "8 Tafeln mit je 5 x 3 Bildern (= 15 Bilder, 5 x 5 cm), jedes dieser Bilder als einzelnes Kärtchen. Einige Kärtchen fehlen. Ein geübter, professioneller Strich, ansprechende Farben. Ursprünglich aufbewahrt in Kartonschachtel, adressiert an Herrn H. Zschokke, Optiker, Kasinostr. 35, Aarau.  Lili Zschokke-Glarner war Kommunistin aus bürgerlichem Haus, studierte Innenarchitektur und wurde im nationalsozialistischen Deutschland wegen Hochverrat verhaftet. Sie heiratete 1938 Helmut Zschokke und hatte mit ihm fünf Töchter und einen Sohn. Das Bilderlotto zeichnete sie für ihre Kinder.",
        "Erwerbsart": "Schenkung von Elisabeth Flück, Liebeggerweg 11a, 5000 Aarau, 062 824 14 95 (EF)",
        "Herkunft": "Nachlass Helmut Zschokke (HZ)",
        "Raumverweis": "",
        "NummerObjektteppich": "97",
        "Person": "Lili Zschokke-Glarner",
        "Lebensdaten": "1908-1965",
        "Infotext": "Lili Glarner wuchs als Tochter eines Arztes in Wildegg auf. Sie schloss die Matura in Aarau ab und begann, in Frankfurt am Main Innenarchitektur zu studieren. Der aufkommende Nationalsozialismus bewog sie, Deutschland 1931 wieder zu verlassen. Sie war Kommunistin geworden - sehr zum Unverständnis ihrer Eltern. 1933 reiste sie zu ihrem damaligen Verlobten nach Berlin. Kurz nach Lilis Ankunft wurden beide verhaftet, die Anklage lautete auf Hochverrat. Nicht zuletzt dank Interventionen ihrer Familie beim Schweizer Botschafter wurde Lili nach einem Jahr Einzelhaft entlassen. Zurück in der Schweiz lernte sie Helmut Zschokke kennen, auch er als Kommunist politisch aktiv. Das Paar heiratete 1938 und bekam sechs Kinder. Lili korrigierte Texte, schrieb für Zeitschriften, arbeitete als Sekretärin und hielt so ihre Familie finanziell über Wasser. Helmut Zschokkes Optikergeschäft in Aarau hatte im antikommunistischen Klima der Nachkriegsjahre einen schweren Stand. Wenn Zeit blieb, sang und malte sie mit den Kindern. Aus jener Zeit stammt auch das selbstgemalte Bilder-Lotto. Lili bleibt zeitlebens politisch aktiv. In verschiedenen Gremien und Frauengruppen setzte sie sich für die Anliegen der Arbeiterbewegung ein.\r"
    },
    {
        "DateinameBild": "Guertler_Adelheid.jpg",
        "Inventar Nr": "SM 2008-0195",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Urkunde der Stadt Aarau für die Klosterfrauen von Schännis",
        "Zusatz": "",
        "Datierung": "1270",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Tinte, Pergament, Wachs, Schnur",
        "Systematik": "Klosterfrauen, Schännis, Frauenkloster, Urkunde, Politik, S",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Feder auf Pergament, Siegel an Schnur hängend",
        "Massangaben": "ca. 48 x 30 cm Objektmass",
        "Beschreibung": "Schultheiss, Rat und Gemeinde in Aarau nehmen die Klosterfrauen von Schännis in Aarau in ihren Schutz und gewähren ihnen Steuerfreiheit. Aarau, 25. März 1270.  In einem mit Siegel versehenen Schirmbrief der Stadt Aarau wurde den Schwestern im März 1270 Schutz zugesichert. Man gewährte ihnen das Bürgerrecht der Stadt und erlaubte ihnen den Zugang zu Wasser, die Benutzung von Wegen, Weiden und Wäldern. Adelheid Gürtler war die erste Priorin der Schwestern von Schännis im Frauenkloster St. Ursula in der Halde.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "72",
        "Person": "Adelheid Gürtler",
        "Lebensdaten": "Um 1270",
        "Infotext": "Adelheid Gürtler trat im Oktober 1270 als erste Einheimische dem neu gegründeten Frauenkonvent St.Ursula bei. Reiche Aarauer Familien waren aktiv an der Gründung des Klosters beteiligt und unterstützten die Gemeinschaft. Die Schwestern stammten demnach mehrheitlich aus wohlhabenden Familien. So auch Adelheid Gürtler, die bei ihrer Aufnahme ins Kloster ihr gesamtes Eigentum der Gemeinschaft übergab und als erste Priorin der rund fünf bis sechs Schwestern wirkte. In einem mit Siegel versehenen Schirmbrief der Stadt Aarau wurde den Schwestern im März 1270 Schutz zugesichert. Man gewährte ihnen das Bürgerrecht der Stadt und erlaubte ihnen den Zugang zu Wasser, die Benutzung von Wegen, Weiden und Wäldern.\r"
    },
    {
        "DateinameBild": "Landis_Julie.jpg",
        "Inventar Nr": "SM 2008-0232",
        "Ref_ Nr_": "",
        "Künstler": "Julie Landis-Sager (*1934)",
        "Bezeichnung": "Salzteigfiguren Maienzug",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Salzteig",
        "Systematik": "Kunsthandwerk, Maienzug",
        "Geogr_ Bezug": "Aarau",
        "Technik": "bemalt",
        "Massangaben": "32 x 57 cm Zierrahmenmass",
        "Beschreibung": "26 Salzteigfiguren (Mädchen und Knaben), bemalt  Bekannt wurde die Grafikerin Julie Landis mit ihren Salzteig-Figuren, später veröffentlichte sie aber auch erfolgreich Kochbücher.",
        "Erwerbsart": "Geschenk von Julie Landis, Renggerstrasse 60, 5000 Aarau",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "5",
        "Person": "Julie Landis-Sager",
        "Lebensdaten": "1934",
        "Infotext": "Nelly Sager wuchs mit elf Geschwistern in Gränichen auf. Nach Abbruch einer Lehre als Kauffrau hielt sie sich in Frankreich auf, was ihren Namenswechsel von Nelly zu Julie zur Folge hatte. Zurück in der Schweiz absolvierte sie in Aarau eine Lehre als Schriftenmalerin. Die Drucke und Logos, die sie ab 1957 mit ihrem Ehemann, dem Künstler Kaspar Landis entwarf, sind bis heute im Stadtbild von Aarau präsent. Nationale Bekanntheit erlangte sie jedoch mit verzierten Figuren aus Salzteig. Sie veröffentlichte Bücher und gab Kurse zur Herstellung von Salzteigfiguren. Damit traf sie in den 1970er-Jahren den Nerv der Zeit. Es lag im Trend, die freie Zeit „kreativ“ zu nutzen. Denn mit der Entwicklung der Dienstleistungsgesellschaft verkürzten sich die Arbeitszeiten, während der Wohlstand in der Mittelschicht anstieg. In Landis-Sagers Küche entstanden auch kulinarische Leckerbissen. 2004 wurde ihre Sammlung ursprünglicher Rezepte der südfranzösischen Hausfrauenküche – „La Cuisine du Soleil“ – zum besten im Ausland erschienenen französischen Kochbuch gekürt.\r"
    },
    {
        "DateinameBild": "Zschokke-Nuesperli_Nanny_02.jpg",
        "Inventar Nr": "SM 2009-0034",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Aufsatzsekretär von Anna Elisabeth, gen. Nanny Zschokke-Nüsperli (1785-1858)",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Holz, Messing, Leder",
        "Systematik": "Schreibmöbel, Aufsatzsekretär",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "134.5 x 90 x 44.5 cm Objektmass",
        "Beschreibung": "Sekretär mit senkrecht gestellter Platte, Unter- und Mittelbau; Schreibfläche mit eingelassenem dunkelbraunem Leder; Unterbau mit zwei Schubladen, eine davon mit Unterteilung; Mittelbau mit rechts und links je drei übereinander liegenden kleinen Schubladen mit Unterteilungen, dazwischen ein grosses Fach, darüber zwei Fächer über die gesamte Breite; ganz oben Schublade über die ganze Breite; Aufsatz. Beschläge Messing.  Die Pfarrerstochter Nanny Nüsperli aus Küttigen heiratete mit zwanzig Jahren den Schriftsteller und Politiker Heinrich Zschokke. Sie gebar zwölf Söhne und eine Tochter und kümmerte sich bis zur Selbstaufgabe um deren Erziehung.",
        "Erwerbsart": "Schenkung von Herrn Christian Zschokke, Tessinstrasse 34, 4054 Basel",
        "Herkunft": "Erbschaft Zschokke, in Gebrauch bei Nanny Zschokke-Nüsperli",
        "Raumverweis": "",
        "NummerObjektteppich": "100",
        "Person": "Nanny Zschokke-Nüsperli",
        "Lebensdaten": "1785-1858",
        "Infotext": "Die Pfarrerstochter aus Küttigen heiratete mit zwanzig Jahren den Schriftsteller und Politiker Heinrich Zschokke. Dieser verstand seine junge Frau als unbeschriebene Tafel, die er nach seinen Idealen erziehen konnte. Er verordnete ihnen beiden einen sparsamen und von der Gesellschaft zurückgezogenen Lebenswandel. Sie gebar zwölf Söhne und eine Tochter und kümmerte sich bis zur Selbstaufgabe um deren Erziehung. Nanny und Heinrich unterrichteten die Kinder zu Hause in der Blumenhalde. Sie lernte den Jüngeren Lesen, Schreiben und Französisch. Er übernahm die weiterführende Bildung. Nanny war ihrem Mann an Verstand ebenbürtig. Heinrich wusste das und schätzte sie als Vertraute und kritische Erstleserin seiner Manuskripte. Sie überwachte den Bau der Blumenhalde, kontrollierte die Finanzen der Familie und koordinierte die täglichen Besucher aus dem In- und Ausland. Die Familie dankte es ihr, indem sie ihren Geburtstag zum höchsten Feiertag auf der Blumenhalde erhoben.\r"
    },
    {
        "DateinameBild": "Egli_Johann_Heinrich.jpg",
        "Inventar Nr": "SM 2010-0013",
        "Ref_ Nr_": "",
        "Künstler": "Johann Heinrich Egli (1776 - 1852)",
        "Bezeichnung": "Ofenkachel",
        "Zusatz": "",
        "Datierung": "1847",
        "Signatur": "\"Egli\"",
        "Beschriftung": "\"Das die Erdapfel krank, in jedem Land; Gab Jammer und Noth, in jedem Stand!/1847\"",
        "Material": "",
        "Systematik": "Ofenkachel, Eckkachel",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "15 x 23.5 x 12 cm Objektmass",
        "Beschreibung": "Eckkachel. Scharffeuermalerei in Manganviolett: Vasenmotiv mit Blumengirlanden und Inschrift: \"Das die Erdapfel krank, in jedem Land; Gab Jammer und Noth, in jedem Stand!/1847\". Der Spruch ist als zeitgenössischer Hinweis auf die Grosse Hungersnot in Europa durch die Kartoffelfäule zwischen 1845 und 1850 zu verstehen.  Johann Heinrich Egli war der bevorzugte Glasurmaler der Aarauer Hafner.",
        "Erwerbsart": "Ersteigert bei Schuler Auktionen, Zürich",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "87",
        "Person": "Johann Heinrich Egli",
        "Lebensdaten": "1776-1852",
        "Infotext": "Der Glasurmaler Johann Heinrich Egli liess sich 1816 in Aarau nieder. Er war für seine Malereien an Ofenkacheln bekannt, die er für verschiedene Hafnereien in Aarau und Umgebung ausführte. Die flache, bemalte Ofenkachel löste ab dem 17. Jahrhundert die Reliefkachel ab, sodass die Ofenbauer auf begabte Maler angewiesen waren. Vor allem die Zusammenarbeit mit der Hafnerfamilie Andres steigerte Eglis Ansehen. Die Andres/ Egli-Öfen waren begehrt in Wirtsstuben, Pfarroder Bauernhäusern. Charakteristisch für Egli sind der rasche Pinselstrich in manganbraun und die Sprüche, die seine Kacheln zieren. Der Spruch auf der ausgestellten Kachel – „Das die Erdapfel krank, in jedem Land; Gab Jammer, und Noth, in jedem Stand“ – ist als zeitgenössischer Hinweis auf die Grosse Hungersnot in Europa durch die Kartoffelfäule zwischen 1845 und 1850 zu verstehen.\r"
    },
    {
        "DateinameBild": "Bonert_Karl_01.jpg",
        "Inventar Nr": "SM 2010-0032",
        "Ref_ Nr_": "",
        "Künstler": "H. Woodtly & Cie., Möbel (Aarau)",
        "Bezeichnung": "Armlehnsessel",
        "Zusatz": "",
        "Datierung": "1930er-Jahre",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Holz, Stoff, Metall",
        "Systematik": "Sitzmöbel, Armlehnsessel, Armlehnstuhl",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "84 x 62 x 66.5 cm Objektmass",
        "Beschreibung": "Armlehnsessel, Bezug aus grünem Stoff, mit Nieten aus dem Nachlass von Karl Bonert (1900-1995), Innenarchitekt und später Betriebsleiter der Möbelfirma Woodtly.",
        "Erwerbsart": "Geschenk von Niklaus Bonert, Heinerich Wirri-Strasse 17, 5000 Aarau",
        "Herkunft": "Aus dem Nachlass seines Vaters, der als Innenarchitekt bei Woodtly gearbeitet hat",
        "Raumverweis": "",
        "NummerObjektteppich": "18",
        "Person": "Karl Bonert",
        "Lebensdaten": "1900-1995",
        "Infotext": "In Zürich aufgewachsen, bildete sich der Schreiner Karl Bonert an der Kunstschule in Karlsruhe in Innenarchitektur und Möbelindustrie weiter und erhielt dort einen Lehrauftrag für Entwurf. 1928 begann er als Innenarchitekt bei der Firma Woodtly in Aarau. Seine avantgardistischen Entwürfe konnte er nach dem Zweiten Weltkrieg kaum umsetzen, da der Heimatstil die modernen Visionen ablöste. Der Sessel der Firma Woodtly stammt aus den 1930er-Jahren, aus genau jener Übergangszeit. In den 1940er-Jahren wurde Bonert Betriebsleiter der Woodtly AG, die er später übernahm. In Aarau stattete die Woodtly AG verschiedene Lokalitäten aus, so etwa das Café Brändli oder das Café Waldmeier. Die Qualitätsmöbel zierten aber auch den Nestlé-Hauptsitz in Vevey oder das Hotel Schweizerhof in der Lenzerheide. Karl Bonert war mit der Textilentwerferin Gisela Wiethüchter verheiratet. Das Paar hatte drei Kinder.\r"
    },
    {
        "DateinameBild": "Gysi_Alfred.jpg",
        "Inventar Nr": "SM 2011-0064",
        "Ref_ Nr_": "10.12.53/c",
        "Künstler": "Unbekannt",
        "Bezeichnung": "\"Perforierter\" Hundezahn, bearbeitet von Prof. Dr. h. c. Alfred Gysi, von Aarau in Zürich",
        "Zusatz": "Vgl. SM 2011-0065 u. SM 2011-0098",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "",
        "Systematik": "Hundezahn",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "Höhe 4 cm Objektmass",
        "Beschreibung": "Alfred Gysi (1865-1957) war Pionier der Zahnheilkunde und Mitbegründer des Zahnmedizinischen Instituts der Universität Zürich. Um seine Fingerfertigkeit zu trainieren, bearbeitete er mit einer zahnärztlichen Bohrmaschine einen Hundezahn und perforierte diesen mit 200 feinen Löchern.",
        "Erwerbsart": "Geschenk von Prof. Dr. h. c. A. Gysi, Zürich",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "95",
        "Person": "Alfred Gysi",
        "Lebensdaten": "1865-1957",
        "Infotext": "Mit 27 Jahren eröffnete der in Aarau geborene Alfred Gysi seine eigene Zahnarztpraxis in Zürich. Um seine Fingerfertigkeiten zu trainieren, bearbeitete er mit einer zahnärztlichen Bohrmaschine einen Hundezahn und perforierte diesen mit 200 feinen Löchern. Ein zweites Standbein baute sich Gysi am zahnärztlichen Institut der Universität Zürich auf, zu dessen Gründern er gehörte. Von 1895 bis 1930 war er als Dozent, später als Professor tätig. Bekannt wurde er mit seinen Untersuchungen zu Karies und zum Unterkiefer. Die „Gysische Methode“ zur Registrierung der individuellen Kieferbewegungsbahnen setzte sich international durch. Als Enkel des Fotopioniers Friedrich Gysi fertigte er auch Mikrofotografien an, von Zahngewebe, aber auch von Schneekristallen. Den Gegenpol dazu bilden die Versuche, mithilfe eines Fernrohrs die Unendlichkeit des Universums einzufangen.\r"
    },
    {
        "DateinameBild": "Rothpletz_Frieda_01.jpg",
        "Inventar Nr": "SM 2012-0028",
        "Ref_ Nr_": "2008.06.20.N001",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Mobiler Sekretär von Frida Rothpletz (1874-1961)",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Holz, Metall, Samt",
        "Systematik": "Schreibmöbel, Sekretär",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "geschlossen 11 x 33 x 28 cm Objektmass offen 17 x 33 x 50 cm Objektmass",
        "Beschreibung": "Mobiler Sekretär, Tischmodell, Holz, im Deckel Intarsien, aufklappbar (zwei Klappen), mit Schloss, Schreibfläche aus blauem Samt, 1 grosses Fach mit Deckel, sechs kleine Fächer, eines davon Geheimfach.  Frida Rothpletz und ihr Bruder Emil schenkten das Schlössli 1930 der Stadt Aarau. Frida behielt Wohnrecht bis kurz vor Ihrem Tod 1961.",
        "Erwerbsart": "",
        "Herkunft": "Gehörte Frida Rothpletz",
        "Raumverweis": "3. OG",
        "NummerObjektteppich": "47",
        "Person": "Frida Rothpletz",
        "Lebensdaten": "1874-1961",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Walther_Lisa.jpg",
        "Inventar Nr": "SM 2012-0054",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Brief von Albert Schweitzer an die Musikalienhandlung Walther",
        "Zusatz": "",
        "Datierung": "1936",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "",
        "Systematik": "Brief",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "12.5 x 20.1 cm Blattmass",
        "Beschreibung": "Lisa Walther (1901-1962) führte eine Musikalienhandlung an der Rathausgasse 6 in der Altstadt. Sie erhielt in diesem Brief, datiert 15.4.1936, von Albert Schweitzer die Erlaubnis auf ihre Anfrage, ein Bild von ihm an der Orgel ins Schaufenster zu stellen.",
        "Erwerbsart": "Geschenk von Maja Burlet, Schönenbergstrasse 62, 8820 Wädenswil",
        "Herkunft": "aus dem Nachlass der Lisa Walther-Popoff",
        "Raumverweis": "",
        "NummerObjektteppich": "80",
        "Person": "Lisa Walther",
        "Lebensdaten": "1901-1962",
        "Infotext": "Lisa Walther führte eine Musikalienhandlung an der Rathausgasse 6 in der Altstadt (heute: Möbelhaus Strebel). Sie organisierte Konzerte und spielte auch selbst Klavier. Im April 1936 bekam sie Post von Albert Schweitzer (1875-1965). Der Missionsarzt, Theologe und Friedensnobelpreisträger war nämlich auch ein begabter Organist. 1936 machte er auf einer Konzertreise in Aarau Halt. Er interpretierte vor allem Stücke von Johann Sebastian Bach. Schweitzer vermutete hinter dem Geschäftsnamen Walther einen Mann und schrieb Lisa Walther mit „Sehr geehrter Herr“ an. Dass eine Frau eine Musikalienhandlung führte, war in dieser Zeit unüblich. Sie führte das Geschäft bis in die 1950er-Jahre. Danach wanderte sie mit dem bulgarischen Diplomaten Nikolaus Popoff nach Brasilien aus, wo sie 1952 heirateten.\r"
    },
    {
        "DateinameBild": "Dreier_Hanna.jpg",
        "Inventar Nr": "SM 2012-0104",
        "Ref_ Nr_": "",
        "Künstler": "Sasha Morgenthaler-von Sinner (Bern 1893 - 1975 Zürich)",
        "Bezeichnung": "Sasha Morgenthaler-Puppe in Aargauer Festtagstracht, Sashapuppe",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "",
        "Systematik": "Trachtenpuppen",
        "Geogr_ Bezug": "Aarau, Aargau",
        "Technik": "",
        "Massangaben": "ca. 53 x 24 x 14 cm Objektmass",
        "Beschreibung": "Aargauer Festtagstracht mit Rock aus blauem Wollstoff, Mieder aus schwarzem Samt, Blumenstickereien auf Brustlatz und Göller, einer weissen Leinenbluse und geflochtenem Hut aus Florentiner Stroh mit Blumenkranz. Die Aargauer Tracht wurde erst 1925 entworfen. Zuvor war im Berner Aargau auch nach der Kantonsgründung 1803 die Berner Tracht verbreitet.  Die Schneiderin Hanna Dreier (1919-2012), aus deren Nachlass die Puppe stammt, nähte nicht nur Trachten für Erwachsene, sondern auch für diese eigens bei Sasha Morgenthaler in Auftrag gegebene Puppe.",
        "Erwerbsart": "Aus dem Nachlass von Hanna Dreier, geb. 9.4.1919, gestorben am 25.3.2012, wohnhaft gewesen Golatenmattgasse 37, 5000 Aarau.",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "86",
        "Person": "Hanna Dreier",
        "Lebensdaten": "1919-2012",
        "Infotext": "Hanna Dreier wurde 1919 in Aarau geboren. Nach einer Lehre als Damenschneiderin eröffnete sie ihr eigenes Damenmassatelier, das auch Lehrbetrieb war. Beliebt bei den Aarauerinnen waren ihre Nähkurse in der Freizeitwerkstatt. Hanna Dreier war auch Schneiderin des Trachtenverbands. Sie besass eine Trachtenpuppe der bekannten Puppenkünstlerin Sasha Morgenthaler. Hanna Dreier soll sie gebeten haben, den Teint der Puppe an den Armen etwas dunkler zu gestalten, da es sich um eine Frau vom Land handle. Die Kleider der Puppe nähte Hanna Dreier selbst. Sie trägt die Aargauer Festtagstracht mit Rock aus blauem Wollstoff, ein Mieder aus schwarzem Samt, Blumenstickereien auf Brustlatz und Göller, eine weisse Leinenbluse und einen geflochtenen Hut aus Florentiner Stroh mit Blumenkranz. Die Aargauer Tracht wurde erst 1925 entworfen. Zuvor war im Berner Aargau auch nach der Kantonsgründung 1803 die Berner Tracht verbreitet.\r"
    },
    {
        "DateinameBild": "Ruckstuhl_Margaritha_02.jpg",
        "Inventar Nr": "SM 2012-0146",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Registrierkasse \"National\" aus der Bäckerei Ruckstuhl, Zelgli",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Metall",
        "Systematik": "Registrierkassen",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "50 x 46 x 41.5 cm Objektmass",
        "Beschreibung": "Margaritha Ruckstuhl (1910-2012) führte zusammen mit ihrem Mann von 1952 bis 1971 eine Quartierbäckerei im Zelgli.",
        "Erwerbsart": "Geschenk von Alfons Ruckstuhl, Tannerstrasse 17, 5000 Aarau",
        "Herkunft": "Seine Mutter, Frau Ruckstuhl, die 2012 101-jährig gestorben ist, führte im Zelgli eine Bäckerei (vgl. Zelgli-Geschichten).",
        "Raumverweis": "",
        "NummerObjektteppich": "14",
        "Person": "Margaritha Ruckstuhl",
        "Lebensdaten": "1910-2012",
        "Infotext": "Margaritha Sommerhalder wuchs in einer Bauernfamilie im Fricktal auf und arbeitete als Schuhnäherin in der Bally-Fabrikationsstätte in Frick. Nach der Hochzeit mit dem Bäcker Alphons Ruckstuhl erwarben die beiden 1952 an der Tannerstrasse 19 in Aarau eine Bäckerei mit Lebensmittelgeschäft. Ob frisches Brot, ein Feierabendbier oder eine Air-Mail-Marke – im Quartierladen gab es alles, was das Herz der Bewohner und Bewohnerinnen im Zelgli begehrte. Ihre Kunden verabschiedete Margaritha Ruckstuhl jeweils mit Namen, nachdem sie die Preise der Waren in die grosse Registrierkasse getippt hatte. Als Verkäuferin und Hausfrau mit vier Kindern hatte sie alle Hände voll zu tun. Ohne ihren Ehemann wäre das Geschäft undenkbar gewesen. Er stand jede Nacht um 1 Uhr auf, um zu backen. Die Bäckerei der Familie Ruckstuhl bestand bis 1971.\r"
    },
    {
        "DateinameBild": "Sauerlaender_Remigius.jpg",
        "Inventar Nr": "SM 2013-0002",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Papier aus der Papiermühle Sauerländer, Benkerklus, Küttigen",
        "Zusatz": "",
        "Datierung": "1823 - 1848",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "",
        "Systematik": "Papierbogen",
        "Geogr_ Bezug": "Küttigen",
        "Technik": "gerollt",
        "Massangaben": "70 x 50 cm Blattmass",
        "Beschreibung": "Heinrich Remigius Sauerländer (1776-1847) gründete 1807 seinen Verlag und eine Buchdruckerei in Aarau. Dank seinem Hauptautor Heinrich Zschokke war er so erfolgreich, dass er zeitweise eine eigene Papiermühle in Küttigen betrieb.",
        "Erwerbsart": "",
        "Herkunft": "Aus dem Sauerländer-Archiv",
        "Raumverweis": "2. OG",
        "NummerObjektteppich": "28",
        "Person": "Heinrich Remigius Sauerländer",
        "Lebensdaten": "1776-1847",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Feer_Sulzer_Emil.jpg",
        "Inventar Nr": "SM 2013-0080",
        "Ref_ Nr_": "20.5.56/e",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Vogelpräparate auf Holzpodest, unter Glashaube",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "",
        "Systematik": "Vogelpräparate",
        "Geogr_ Bezug": "",
        "Technik": "",
        "Massangaben": "ca. 55 x 33 x 27 cm Objektmass",
        "Beschreibung": "Das Vogelpräparat stammt aus dem Nachlass von Emil Feer-Sulzer (1864-1955). Er eröffnete die erste auf Pädiatrie spezialisierte Praxis in der Schweiz und schrieb ein Standardwerk der Kinderheilkunde.",
        "Erwerbsart": "Schenkung der Erbschaft von Prof. Dr. med. Emil Feer-Sulzer (1864-1955)",
        "Herkunft": "Aus Nachlass von Prof. Dr. med. Emil Feer-Sulzer (1864-1955)",
        "Raumverweis": "",
        "NummerObjektteppich": "35",
        "Person": "Emil Feer",
        "Lebensdaten": "1864-1955",
        "Infotext": "Auf dem Feer-Gut in Aarau aufgewachsen, schloss Walter Emil Feer 1884 die Kantonsschule ab. Nach dem Medizinstudium in Basel, München und Heidelberg heiratete er 1891 Rosa Louise Sulzer aus Winterthur. Kurz darauf eröffnete er in Basel eine eigene Praxis. Er war der erste Arzt, der ausschliesslich auf Kinder spezialisiert war. 1905 folgte die Eröffnung eines Säuglingsheims. In dieser Zeit kamen auch die vier eigenen Kinder zur Welt. 1907 wurde Emil Feer Professor für Kinderheilkunde an der Universität Heidelberg. 1910 folgte der Ruf an die Universität Zürich. wo Feer bei der Schaffung einer Universitätskinderklinik mitwirkte. Sein «Lehrbuch der Kinderheilkunde» wurde zum Standardwerk und ist in 27 Auflagen erschienen. Nach ihm benannt ist auch eine Nervenerkrankung bei Kindern, die Feer’sche Krankheit. Über die kunstvoll arrangierten Vogelpräparate aus seinem Nachlass wissen wir wenig. Möglicherweise waren sie ein Geschenk seines Sohnes: Eduard Albert Feer war ab 1944 Botschafter in Südamerika, der Heimat der Kolibris.\r"
    },
    {
        "DateinameBild": "Neeser_Gottfried_Sen_02.jpg",
        "Inventar Nr": "SM 2013-0085-a-c",
        "Ref_ Nr_": "2008.12.05.N003",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Joghurtbecher \"Neea-Joghurt\", Milchhandlung Neeser",
        "Zusatz": "",
        "Datierung": "um 1960",
        "Signatur": "",
        "Beschriftung": "\"Neea-Joghurt/mit Früchten\" (rot); \"Neea-Joghurt/natur\" (blau)",
        "Material": "Karton, innen und aussen plastifiziert",
        "Systematik": "Gefässe, Joghurtbecher",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "Höhe 7.2 cm Objektmass oben 7.5 cm Durchmesser unten 6 cm Durchmesser",
        "Beschreibung": "Gottfried Neeser (1917-2001) führte die Milchhandlung der Familie weiter und produzierte als Innovation früh Joghurt mit frischen Früchten.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "69",
        "Person": "Gottfried Neeser",
        "Lebensdaten": "1917-2001",
        "Infotext": "Als Gottfried Neeser die Milchhandlung seiner Eltern übernahm, setzte er auf eine Innovation. Er produzierte Joghurt mit frischen Früchten und war damit in den 1940er-Jahren der Erste im Aargau. Im Sommer, in der Hochsaison für Joghurts, wurden pro Tag 12 Kannen à 40 Liter Milch zu Joghurt verarbeitet. Die Joghurts wurden im ganzen Kanton verkauft. An der Hotelgewerbeausstellung in Lausanne 1964 gewann das Neea-Joghurt gar eine Goldmedaille. Eine Herausforderung stellte die geringe Haltbarkeit – sowohl der Joghurts als auch des Fruchtmarks – dar. Als Gottfried Neeser aus dem Tessin einmal einen Eisenbahnwaggon voller Erdbeeren bestellt hatte, musste die Trachtengruppe eine Nacht lang Erdbeeren rüsten. Mit dem Aufkommen der industriellen Fertigung waren die Neea-Joghurts nicht mehr konkurrenzfähig. In seiner Milchhandlung am Holzmarkt bot Gottfried Neeser aber bis 1984 eine breite Käsepalette an.\r"
    },
    {
        "DateinameBild": "Roschi_Peter.jpg",
        "Inventar Nr": "SM 2013-0212",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Trompete, Hirsbrunner",
        "Zusatz": "",
        "Datierung": "1957",
        "Signatur": "Gravur: \"G. Hirsbrunner / AARAU.\" // \"1957/ 73\"",
        "Beschriftung": "",
        "Material": "Metall",
        "Systematik": "Blechblasinstrumente, Trompete, Kadettenmusik",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "ca. 52 x 13 x 19 cm Objektmass",
        "Beschreibung": "Die Trompete aus der Werkstatt des Instrumentenbauers Jakob Hirsbrunner stammt aus den Restanzen des Aarauer Kadettenkorps. Der Trompeter Peter Roschi (*1958) ist langjähriger Musiklehrer bei der Kadettenmusik Aarau.",
        "Erwerbsart": "Schenkung, Restanzen Kadettenwesen Stadt Aarau, überbracht von Peter Roschi, Kadettenmusik Aarau",
        "Herkunft": "Estrich Zelgli-Schulhaus",
        "Raumverweis": "",
        "NummerObjektteppich": "51",
        "Person": "Peter Roschi",
        "Lebensdaten": "1958",
        "Infotext": "Der Trompeter und Leiter der Turmbläser kam sozusagen über Nacht zur Kadettenmusik. Ein Musiklehrer rief an und bat ihn, per sofort seine Stunden an der Kadettenmusikschule zu übernehmen. Die Strukturen, die Peter Roschi 1983 bei der Kadettenmusik antraf, waren noch streng hierarchisch und traditionell. Trompete, Posaune und Horn wurden nur bei der Kadettenmusikschule unterrichtet und standen somit nur Jungen offen. Gespielt wurde Marschmusik in alten Militäruniformen. Peter Roschi hat in den vergangenen drei Jahrzehnten die Öffnung und Modernisierung der Kadettenmusik vorangetrieben. Es sei heute eher eine „Kadettenband“, Märsche werden aber am Maienzug und am Bachfischet immer noch gerne gespielt. Die Kadettenmusik ist tief im Aarauer Selbstverständnis verwurzelt. Wie gross auch die politische Rückendeckung ist, erlebt Peter Roschi als Einwohnerrat selber.\r"
    },
    {
        "DateinameBild": "Luescher_Gottlieb.jpg",
        "Inventar Nr": "SM 2013-0314",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Broschüre: Der Aarauer Aaresee",
        "Zusatz": "",
        "Datierung": "1920",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Papier",
        "Systematik": "Broschüre",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "26.8 x 18.3 cm Objektmass",
        "Beschreibung": "\"Der Aarauer Aaresee. Zukünftiger Hafen der Stadt Aarau. Stausee des Kraftwerkes Rupperswil der S.B.B. Von Dr. G. Lüscher, Ingenieur in Aarau.\" Buchdruckerei Neue Aargauer Zeitung, Aarau, 11 S.  Der Ingenieur Gottlieb Lüscher (1868-1949) verfolgte um 1920 das Projekt, die Aare zu stauen und den Schachen zum See umzufunktionieren. Er träumte von „tonnenschweren Schleppkähnen, sportlichen Ruderern und gemütlichen Fischern auf dem See vor den Toren der Stadt“.",
        "Erwerbsart": "nicht bekannt",
        "Herkunft": "unbekannt",
        "Raumverweis": "",
        "NummerObjektteppich": "71",
        "Person": "Gottlieb Lüscher",
        "Lebensdaten": "1868-1949",
        "Infotext": "Gottlieb Lüscher arbeitete sich vom Fabrikarbeiter zum promovierten Naturwissenschaftler hoch. Er wurde Gross- und Stadtrat und eröffnete 1909 ein Ingenieurbüro in Aarau. Lüscher zeichnete sich durch visionäre Projekte aus. Um das Aaregefälle für das Wasserkraftwerk in Rupperswil besser zu nutzen, schlug Lüscher 1920 der Bevölkerung einen Stausee vor. Diesen wollte er mit der Flutung des Schachengebiets zwischen Aarau und Rupperswil, Biberstein und Rohr erschaffen. Er träumte von „tonnenschweren Schleppkähnen, sportlichen Ruderern und gemütlichen Fischern auf dem See vor den Toren der Stadt“. Wie die Vision vom Aaresee blieben auch andere Ideen lediglich auf dem Papier, etwa die Schiffbarmachung der Aare für grössere Transportschiffe oder eine direkte Bahnverbindung zwischen dem Fricktal und der Region Aarau.\r"
    },
    {
        "DateinameBild": "Dietsch_Andreas.jpg",
        "Inventar Nr": "SM 2013-0316",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Buch: Dietsch, Andreas, Der Aarauer Bachfischet humoristisch dargestellt, Aarau 1844",
        "Zusatz": "",
        "Datierung": "1844",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Karton, Papier",
        "Systematik": "Buch, Feste, Brauch, Bräuche, Bachfischet, Andreas Dietsch",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "23 x 13.5 cm Objektmass",
        "Beschreibung": "1841 veröffentlichte Andreas Dietsch (1807-1841) ein Büchlein über den Aarauer Brauch des Bachabschlags. Erstmals sind dort die Reime „Fürio de Bach brönnt…“ dokumentiert, welche die Schulkinder bis heute am Bachfischet singen.  1844 führte Dietsch eine Auswanderergruppe nach \"Neu-Helvetien\" mit dem Ziel, eine sozialistische Gemeinschaft aufzubauen. Dietsch starb wenige Monate nach der Ankunft und sein Projekt scheiterte.",
        "Erwerbsart": "Übernahme aus der Rathausbibliothek der Stadt Aarau",
        "Herkunft": "Aus der Bibliothek des Stadtmuseums, vgl. Eintrag im Literaturmodul",
        "Raumverweis": "",
        "NummerObjektteppich": "94",
        "Person": "Andreas Dietsch",
        "Lebensdaten": "1807-1845",
        "Infotext": "Der Bürstenbinder Andreas Dietsch kam 1835 aus dem Elsass nach Aarau. Ab 1838 führte er ein eigenes Geschäft und schrieb daneben für die politische Wochenschrift „Das Posthörnchen“. 1841 veröffentlichte Dietsch ein Büchlein über den Aarauer Brauch des Bachabschlags. Erstmals sind dort die Reime „Fürio de Bach brönnt…“ dokumentiert, welche die Schulkinder bis heute an der Bachfischet singen. In einer weiteren Schrift entwarf Dietsch eine Utopie einer frühsozialistischen Gesellschaft. Diese wollte er mit der Gründung einer Kolonie in Missouri verwirklichen. Sechzig Gleichgesinnte aus verschiedenen Kantonen schlossen sich seiner Auswanderungsgesellschaft an. Im Sinne eines Handwerkerkommunismus wollten sie gemeinsam arbeiten und teilen. 1844 verliessen 43 Personen – 25 Erwachsene und 18 Kinder – Aarau. Nach der monatelangen Reise erreichte wegen Hunger und Krankheit lediglich knapp die Hälfte der Auswanderer Amerika. Für den Aufbau von „Neu Aarau“ waren nicht genügend Handwerker und Landwirte dabei. Andreas Dietsch starb wenige Monate nach der Ankunft in Amerika. Das Projekt war gescheitert.\r"
    },
    {
        "DateinameBild": "Luethi_Alfred.jpg",
        "Inventar Nr": "SM 2014-0001",
        "Ref_ Nr_": "20.12.57/b-f",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Spielsteine mit romanischen Verzierungen und Figuren, Fund bei der Ausgrabung der Burg Horen",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Hirschhorn",
        "Systematik": "Bodenfunde, Spielsteine, Spielfiguren, Rosenberg",
        "Geogr_ Bezug": "Aargau, Küttigen, Horen, Rosenegg",
        "Technik": "",
        "Massangaben": "bis zu 4 cm Durchmesser",
        "Beschreibung": "Alfred Lüthi (1918-2006), Präsident des historischen Vereins des Bezirks Aargau. Als Vereinspräsident übernahm er das Patronat der Ausgrabung der Burg Horen östlich von Küttigen, die Anfang des 12. Jahrhunderts erbaut wurde. Die Ausgrabungen im Jahre 1956, unterstützt von den Aarauer Pfadfindergruppen, förderten nicht nur die Ruinen der einstigen Burg, sondern auch zahlreiche Funde zutage, wie die romanischen Spielfiguren, ein Hufeisen oder zahlreiche Knochenfunde von Tieren.",
        "Erwerbsart": "Schenkung von Dr. Alfred Lüthi, Präsident des historischen Vereins des Bezirks Aarau",
        "Herkunft": "Bodenfunde bei der Ausgrabung der Burg Horen bei Küttigen im Jahre 1956",
        "Raumverweis": "",
        "NummerObjektteppich": "98",
        "Person": "Alfred Lüthi",
        "Lebensdaten": "1918-2006",
        "Infotext": "Alfred Lüthi studierte Geschichte und Geografie. Nach Abschluss seiner Doktorarbeit war er ab 1947 Bezirkslehrer in Aarau, ab 1965 Dozent an der Höheren Technischen Lehranstalt in Brugg-Windisch. Mehrere Jahre gehörte er dem Stadtrat von Aarau an. Sein Forschungsschwerpunkt lag in der Ur- und Frühgeschichte von der Stadt Aarau und Umgebung. Er bemühte sich intensiv um die Ausgrabung der Ruine der Burg Horen – im Volksmund Rosenberg genannt – oberhalb von Küttigen. 1956 hatten Aarauer Pfadfindergruppen im Frondienst mit der Ausgrabung begonnen. Die Arbeiten legten neben Überresten der Burg aus dem 12. Jahrhundert romanische Spielfiguren, Tierknochen, Reste von Töpfen sowie ein Hufeisen frei.\r"
    },
    {
        "DateinameBild": "Keller_Adolf.jpg",
        "Inventar Nr": "SM 2014-0056",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Diplom des Schweizerischen Arbeiter-Sängerverbandes für den Arbeiter-Sängerbund Aarau",
        "Zusatz": "II. Kategorie im 1.a Rang",
        "Datierung": "1927",
        "Signatur": "vorne u. l. (Druck): \"Hubacher A. G. Bern\", vorne u. r. (Druck): \"M. Annen, Schwyz\"",
        "Beschriftung": "hinten o. l. (Stempel): \"Otto Suter/ Bilderhandlung u. Einrahmungen/Aarau (Schweiz)\"",
        "Material": "Farbe, Papier, Holz, Glas",
        "Systematik": "Urkunden, Diplome, Gesangsfeste, Musikfeste, Musikgesellschaften, Männerchor, Arbeiter-Sängerbund",
        "Geogr_ Bezug": "Aarau, Birsfelden",
        "Technik": "Druckgraphik. Farbige Lithographie auf Papier. Zierrahmen Holz, profiliert, reliefiert u. vergoldet.",
        "Massangaben": "60.8 x 46 cm Zierrahmenmass 49.2 x 34.8 cm Lichtmass",
        "Beschreibung": "Dritter Kreis-Sängertag Birsfelden, 8. Mai 1927  Der Lehrer Adolf Keller (1884-1944) dirigierte den Arbeitersängerbund zwischen 1914 und 1938.",
        "Erwerbsart": "nicht bekannt",
        "Herkunft": "unbekannt",
        "Raumverweis": "",
        "NummerObjektteppich": "67",
        "Person": "Adolf Keller",
        "Lebensdaten": "1884-1944",
        "Infotext": "Zwischen 1914 und 1938 schwang Keller – von krankheitsbedingten Ausfällen unterbrochen – den Dirigentenstab des Aarauer Arbeitersängerbundes. Unter seiner Regie wurde der Sängerbund von einem geselligen Verein zu einem engagierten Arbeiterchor. Der Sängerbund politisierte zunehmend und verlieh mit seinen Arbeiterliedern den Forderungen der Arbeiterbewegung Ausdruck. Fiel eine Gesangsprobe aus, wurde sie durch ein politisches Referat eines Sängers ersetzt. Neben Konzerten und Vereinsausflügen nahm der Sängerbund auch an Schweizerischen Arbeitersängerfesten teil, wo das sozialistische Liedergut gepflegt wurde. Adolf Keller selber übte keinen klassischen Arbeiterberuf aus, sondern war Lehrer in Buchs und später in Aarau.\r"
    },
    {
        "DateinameBild": "Merz_Jean_01.jpg",
        "Inventar Nr": "SM 2014-0234",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Coiffeurstuhl von Jean Merz",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "Auf Rückenlehne u. (Metallplakette): \"Hermann Howe & Co./Nürnberg/Specialhaus für Friseurartikel\"",
        "Material": "Holz, Metall, Leder",
        "Systematik": "Sitzmöbel, Coiffeurstuhl, Jean Merz",
        "Geogr_ Bezug": "Aarau, Nürnberg",
        "Technik": "",
        "Massangaben": "96.3 x 63 x 73 cm Objektmass",
        "Beschreibung": "Stuhl aus dem Coiffeursalon von Jean Merz. Mit verstellbarer Rückenlehne und drehbarer Sitzfläche.  Jean Merz (1872-1954) betrieb ein Coiffeurgeschäft und eine Auswanderungsagentur. Als Amateurfotograf dokumentierte er sein Privat- und Familienleben.",
        "Erwerbsart": "Geschenk von Frau Ruth Valli, Rombach",
        "Herkunft": "Aus dem Coiffeursalon von Jean Merz. Der verstorbene Mann von Ruth Valli hat den Stuhl nach Geschäftsauflösung übernommen und als Bürostuhl benutzt.",
        "Raumverweis": "4. OG",
        "NummerObjektteppich": "99",
        "Person": "Jean Merz",
        "Lebensdaten": "1872-1954",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Fisch_Johann_Georg.jpg",
        "Inventar Nr": "SM 2014-0257",
        "Ref_ Nr_": "k 1283",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Buch: Denkschrift von Johann Georg Fisch (1758-1799), Pfarrer und Revolutionär",
        "Zusatz": "Johann Georg Fisch (1758-1799), Pfarrer",
        "Datierung": "1798 - 1798",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Papier, Karton",
        "Systematik": "Buch, Denkschrift",
        "Geogr_ Bezug": "Aarau",
        "Technik": "gebunden",
        "Massangaben": "1.6 x 10 x 16.1 cm Objektmass",
        "Beschreibung": "Basel, Wilhelm Haas, 78 S. Mit handschriftlichen Notizen.  Johann Georg Fisch (1758-1799) war zweiter Stadtpfarrer in Aarau und ein wichtiger Kopf in den Revolutionstagen in Aarau 1798. Dank seiner \"Denkschrift über die letzten Begebenheiten in der bernerischern Munizipalstadt Aarau im Aargau\" über die Revolution sind die Ereignisse detailliert überliefert.",
        "Erwerbsart": "Geschenk von Frau A. Rothpletz",
        "Herkunft": "",
        "Raumverweis": "3. OG",
        "NummerObjektteppich": "15",
        "Person": "Johann Georg Fisch",
        "Lebensdaten": "1758-1799",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Bettenmann_Jeanette.jpg",
        "Inventar Nr": "SM 2014-0386",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Kaffeemaschine Elcalor",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "",
        "Systematik": "Elektrogeräte, Kaffeemaschine",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "33 x 22 x 19 cm (ohne Kabel) Objektmass",
        "Beschreibung": "Gehört hat die Kaffemaschine Jeannette Bettenmann (1922-2014), legendäre Wirtin und Besitzerin des Chez Jeannette von 1961-1978.",
        "Erwerbsart": "Geschenk von René Bettenmann, Stritengässli 14, 5000 Aarau",
        "Herkunft": "Gehört hat die Kaffeemaschine der Mutter von René Bettenmann, Jeannette Bettenmann, General Guisan-Strasse 52, 5000 Aarau. Wirtin des Restaurants Chez Jeannette von 1961-1978, gestorben am 1. April 2014.",
        "Raumverweis": "",
        "NummerObjektteppich": "16",
        "Person": "Jeannette Bettenmann",
        "Lebensdaten": "1922-2014",
        "Infotext": "Nach einer Servicelehre in Fribourg und Saisonstellen in Engelberg, Davos und Lugano kam Jeannette Berset 1943 nach Aarau ins Hotel Löwen. Bald lernte sie Kari Bettenmann kennen und das Paar heiratete. 1954 übernahm sie am Graben 16 (heute Schweizer Heimatwerk) das Restaurant Grabenallee und brachte Aarau die französische Küche näher. Im ersten Stock brutzelte sie Steaks aux Morilles, schnetzelte Filet Tartar, bereitete Fondue Bourgignonne und Schnecken nach Kapuzinerart vor, im Parterre bediente sie die Gäste. Später richtete Jeannette Bettenmann an der Vorderen Vorstadt das «Chez Jeannette» ein - ein gediegenes Etablissement auf drei Stockwerken. Mit ihrem welschen Charme vermittelte sie über den «Röschtigraben» hinaus, war Zuhörerin, Ratgeberin und Unterhalterin zugleich.\r"
    },
    {
        "DateinameBild": "Erne_Carol_01.jpg",
        "Inventar Nr": "SM 2014-0432",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Einkaufswagen aus dem Konvolut Carole Erné (1937-2009)",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "",
        "Systematik": "Einkaufswagen",
        "Geogr_ Bezug": "Erlinsbach",
        "Technik": "",
        "Massangaben": "152 x 44 x 30 cm Objektmass",
        "Beschreibung": "Gerüst eines Einkaufswagens, verschraubt mit einem Regalelement, darauf drei mit Zeitungsauschnitten gefüllte und zugeklebte Denner-Papiersäcke, bezeichnet mit \"ADIOS 4.08\", \"ADIOS + UBS 4.08\", \"déj écx 07\", befestigt mit einem Spanngurt.  Objekt aus dem Konvolut von Carol Erné, Dolmetscherin. Sie sammelte Verpackungen und Zeitungsausschnitte, die sie akribisch sortierte und neu arrangierte.",
        "Erwerbsart": "Hausräumung in Erlinsbach, Nachlass von Carole Erné",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "25",
        "Person": "Carol Erné",
        "Lebensdaten": "1937-2009",
        "Infotext": "Carol Erné wuchs als einziges Kind des Lebensmittelhändlers Hubert Erné und seiner aus Genf stammenden Frau Renée in Aarau auf. Der grossbürgerlichen Atmosphäre des Elternhauses kehrte Carol den Rücken und zog in die Romandie. Als Dolmetscherin bereiste sie die Welt, bevor sie nach dem Tod der Eltern in einen abgelegenen Bauernhof bei Aarau zog. Hier begann sie Alltagsgegenstände zu sammeln. Verpackungen, Überreste aus dem Sperrgut, Bücher, Zeitungsausschnitte. Sie sammelte bis die riesige Scheune, die Garagen und auch ihr Wohnhaus überfüllt waren. In diesem scheinbaren Chaos herrschte akribische Ordnung. Die Verpackungen waren farblich sortiert und in einzelne Bestandteile zerlegt. Tausende von Zeitungsausschnitten wurden in Bündeln geordnet, beschlagwortet und in Papiertaschen eingeschlagen. Carol Erné gab Abfallprodukten neue Bedeutungen, wie der ausgestellte Handwagen zum Transport der Zeitungsbündel zeigt. Nach ihrem Tod wurde die Liegenschaft geräumt.\r"
    },
    {
        "DateinameBild": "Bertschi_Johann_Gottlieb_01.jpg",
        "Inventar Nr": "SM 2014-0433",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Zylinder von Johann Gottlieb Bertschi (1962-X), in Hutschachtel mit Griff",
        "Zusatz": "",
        "Datierung": "um 1900",
        "Signatur": "",
        "Beschriftung": "im Zylinder: \"GRANDE MANUFACTURE/ EXPRESSLY/Th. Sperlich/AARAU\" u. die Initialen \"J B\". Auf Schachteldeckel: \"CHAPELLERIE MODERNE.\" u. auf Schachtel: \"Theodor Sperlich/vormals E. Pfisterer/AARAU.\"",
        "Material": "Karton, Seide, Leder, langfloriger Samt",
        "Systematik": "Kopfbedeckung, Hut, Zylinder, Theodor Sperlich, Kürschner",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "Schachtel: 20.5 x 29 x 33.5 cm Objektmass Zylinder: 17.3 x 24 x 30.5 cm Objektmass",
        "Beschreibung": "Zylinder aus der Chapellerie von Theodor Sperlich an der Kronengasse 4 (heute Chocolaterie).  Die Initialen J. B. verweisen auf den Hutträger Johann Gottlieb Bertschi, geboren 1862, Grossvater der Donatorin Miria Bernasconi-Bertschi. Er stammte aus Dürrenäsch, liess sich mit seiner Familie - Ehefrau Elise Siegrist und fünf Kindern - in Aarau nieder, wo er in der Halde wohnte und später auf dem Gebiet der ehemaligen Pfrundgärten ein Haus baute. Als gelernter Gärtner kümmerte er sich um den grossen Flecken Land und baute zur Selbstversorgung allerhand an. Hauptberuflich arbeitete er als Briefträger bei der Post in Aarau. Den Zylinder trug der Pöstler mit grünem Daumen nach Aussagen seiner Enkelin ausschliesslich an Beerdigungen.",
        "Erwerbsart": "Geschenk von Miria Bernasconi-Bertschi",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "29",
        "Person": "Johann Gottlieb Bertschi",
        "Lebensdaten": "1862-X",
        "Infotext": "Johann Gottlieb Bertschi stammte ursprünglich aus Dürrenäsch und zog nach der Heirat mit Elise Siegrist nach Aarau. Zunächst in der Halde wohnhaft, konnte Johann Gottlieb Bertschi das Areal der ehemaligen Pfrundgärten erwerben und ein eigenes Haus am heutigen Pfrundweg bauen. Als gelernter Gärtner kümmerte er sich um den grossen Flecken Land und baute zur Selbstversorgung allerhand an. Um das Leben der Familie mit fünf Kindern zu finanzieren, arbeitete Johann Gottlieb Bertschi hauptberuflich jedoch als Postangestellter in Aarau. Den Zylinder aus der Chapellerie von Theodor Sperlich an der Kronengasse trug der Pöstler mit grünem Daumen nach Aussagen seiner Enkelin ausschliesslich an Beerdigungen.\r"
    },
    {
        "DateinameBild": "Oehler_Oskar_02.jpg",
        "Inventar Nr": "SM 2014-0436",
        "Ref_ Nr_": "18.IX.41",
        "Künstler": "Oskar Oehler (Aarau 1890 - 1958)",
        "Bezeichnung": "Modell der Katholischen Kirche mit Pfarrhaus, Aarau",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "Metallplakette auf Holzsockel: \"OSCAR OEHLER/Ingenieur/Relief- und Modellbau/ Aarau\"",
        "Material": "Holz, Metall, Glas",
        "Systematik": "Architektur, Katholische Kirche, Stadtentwicklung",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "37 x 36.5 x 40.5 cm Objektmass 1:200 Masstab",
        "Beschreibung": "1882 an der Kasinostrasse erbaut, 1940 abgerissen. Modell datiert 18.IX.41, erbaut von Oscar Oehler, Ingenieur, Relief- und Modellbau, Aarau.  \"Diese Kirche ist auf Veranlassung des Stadtrates aus dem grossen Stadtmodell von Ing. herausgenommen worden, weil sie mit ihrem «gotischen Stil» als Fremdkörper empfunden worden ist.\" Eintrag im Inventarheft 1941  Der Ingenieur Oskar Oehler betrieb ein Modellbauatelier in Aarau und baute im Auftrag der Stadt Aarau ein Modell, das die Stadt um 1880 zeigt. Ergänzend fertigte er das Modell der Katholischen Kirche an.",
        "Erwerbsart": "Gekauft von Herrn Ing. Oehler",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "40",
        "Person": "Oskar Oehler",
        "Lebensdaten": "1890-1958",
        "Infotext": "Mit dem Studium zum Elektroingenieur war der Weg ins Familienunternehmen Eisen- und Stahlwerke Oehler & Co. vorgespurt. Doch Oskar Oehlers Herz schlug für den Modellbau. Er richtete sich im herrschaftlichen Wohnhaus an der Buchserstrasse 27 ein Atelier ein und fertigte Modelle für Bauprojekte und Industrieanlagen oder Landschaftsreliefs an. In den 1930er-Jahren erhielt er von der Stadt Aarau den Auftrag, ein historisches Stadtmodell anzufertigen. Es zeigt die Stadt um 1880 im Massstab 1:200. Der Modellbauer verwendete darauf mehr als 6500 Arbeitsstunden. Jedes einzelne Haus schnitzte er massstabgetreu aus Lindenholz und bemalte es mit der Hilfe von Kunstmaler Fritz Brunnhofer. Seit 1941 ist Oehlers Werk ununterbrochen im Stadtmuseum ausgestellt. Das Modell der katholischen Kirche kam später als Ergänzung hinzu, wurde aber nie eingefügt.\r"
    },
    {
        "DateinameBild": "Wydler_Ferdinand_01.jpg",
        "Inventar Nr": "SM 2014-0437",
        "Ref_ Nr_": "4.XII.40",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Henkelgefäss \"Aqu. hypnotic.\" mit Deckel, aus der Apotheke Wydler",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "\"Aqu. hypnotic.\"",
        "Material": "Porzellan",
        "Systematik": "Apotheke, Gefässe, Wydler",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "21 x 17 x 12 cm Objektmass",
        "Beschreibung": "Franz Wilhelm Wydler (1818-1877) stammt aus einer Aarauer Apothekerdynastie. So wurde auch er Apotheker und übernahm das Geschäft. 1846 kaufte er von seinem Vater Ferdinand Wydler (1792-1854) das Haus, den Garten und einen Holzschopf für 37'650 Franken.  Die Familie Wydler verkaufte die Apotheke an die Familie Göldlin, welche sie bis heute an der Rathausgasse weiterführt.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "75",
        "Person": "Franz Wilhelm Wydler",
        "Lebensdaten": "1818-1877",
        "Infotext": "Franz Wilhelm Wydler stammt aus einer Aarauer Apothekerdynastie. So wurde auch er Apotheker und übernahm das Geschäft. 1846 kaufte er von seinem Vater Ferdinand Wydler das Haus, den Garten und einen Holzschopf für 37650 Franken. Wydler war aber auch Stadtrat. 1847 wurde er in den Gemeinderat der Stadt Aarau gewählt. Endlos lang scheint die Liste seiner Mitgliedschaften in Kommissionen, etwa in der Kadettenkommission, in der Forst- und Landkommission, in der Waisenkommission oder in der Baukommission. Interessanterweise bat Wydler 1854 um seine Entlassung aus dem Stadtrat. Ab 1861 war er jedoch wieder in etlichen Kommissionen aktiv und übernahm Vormundschaften. Nicht zuletzt war Wydler als Oberst auch militärisch erfolgreich. Er war verheiratet mit Elise Frey und scheint vermögend gestorben zu sein. Um die Kapitalanlage seines Erbes entflammte ein Streit zwischen Nachkommen und Fürsprech. Die Familie Wydler verkaufte die Apotheke an die Familie Göldlin, welche sie bis heute an der Rathausgasse weiterführt.\r"
    },
    {
        "DateinameBild": "Elsasser_Theo_01.jpg",
        "Inventar Nr": "SM 2014-0438",
        "Ref_ Nr_": "4.XII.40",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Inventarheft 1969 des Stadtmuseums Alt-Aarau",
        "Zusatz": "",
        "Datierung": "1969",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Papier",
        "Systematik": "Dokumente, Inventarheft, Inventare",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "22 x 17.5 cm Blattmass",
        "Beschreibung": "Theo Elsasser (1911-1998), Lehrer und Konservator des Stadtmuseums. Richtete in den 1960er-Jahren das Haus als Wohnmuseum ein. Während den neunzehn Jahren im Museum verzeichnete Theo Elsasser jedes neue Objekt in einem Inventarheft.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "43",
        "Person": "Theo Elsasser",
        "Lebensdaten": "1911-1998",
        "Infotext": "Theo Elsasser wuchs als Sohn eines Küfers in der Aarauer Altstadt auf. Er absolvierte das Lehrerseminar in Wettingen und unterrichtete ab 1932 in Hirschthal. Acht Jahre später wechselte er in seine Heimatstadt, wo er im Pestalozzischulhaus auf der Mittelstufe unterrichtete. Ab 1952 war Theo Elsasser im neu eingeweihten Gönhardschulhaus tätig. Nicht nur in der Schule lag ihm die Stadtgeschichte am Herzen. Bereits 1943 wurde Theo Elsasser in die Kommission Alt-Aarau gewählt, die sich um die historische Sammlung der Stadt kümmerte. 1961 übernahm er den Posten als Konservator im Museum. Er richtete ein Wohnmuseum über fünf Stockwerke ein, das fast unverändert fünfzig Jahre lang bestand. Während den neunzehn Jahren im Museum verzeichnete Theo Elsasser jedes neue Objekt im Inventarheft.\r"
    },
    {
        "DateinameBild": "Diemling_Susanna.jpg",
        "Inventar Nr": "SM 2014-0439",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Pinar",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Buchenholz",
        "Systematik": "Pinar, Hebamme",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "17.3 cm Objektmass 6 cm Durchmesser",
        "Beschreibung": "Die Hebamme Susanna Diemling (geb. 1958) begleitet Frauen bei Hausgeburten. Sie arbeitet dabei mit einfachen Hilfsmitteln wie dem Pinar, mit dem die Herztöne des Kindes geortet werden.  Leihgabe von Susanna Diemling",
        "Erwerbsart": "Leihgabe von Susanna Diemling, Juraweg 10, 5022 Rombach",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "89",
        "Person": "Susanna Diemling",
        "Lebensdaten": "1958",
        "Infotext": "Seit 1984 arbeitet die Hebamme Susanna Diemling in der Geburtsvorsorge und begleitet Frauen bei Hausgeburten. Aufgewachsen in der Ostschweiz, verbrachte sie als Kind manche Ferien in Lenzburg. Die „Grossstadt“ Aarau machte ihr Eindruck und sie kam für die Ausbildung zur Krankenschwester zurück. Hebamme zu sein, ist für Susanna Diemling Berufung. Mit dem Pinard-Rohr ortet sie die Herztöne des Kindes und bestimmt mit den Leopold’schen Handgriffen seine Lage im Mutterbauch. «Die Frauen schätzen es, dass jemand ihren Bauch berührt. Ärzte kümmern sich um Datenerhebung und berühren Schwangerenbäuche vor allem via Ultraschallkopf.» Eine Geburt bedeutet für Susanna Diemling gekonntes Abwarten, wozu profundes Wissen über den natürlichen Vorgang des Gebärens notwendig ist. Die Hebamme sieht sich als «Besenfrau beim Curling, die macht, dass es gut läuft». Wenn eine gesunde, glückliche Familie zurückbleibt, beschert ihr dies Glücksmomente.\r"
    },
    {
        "DateinameBild": "Wartmann_Gabi.jpg",
        "Inventar Nr": "SM 2014-0440",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Teller mit arabischer Inschrift",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "auf Boden: \"HandMade\"",
        "Material": "Porzellan",
        "Systematik": "Geschirr, Teller",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "Höhe 3.3 cm Objektmass 18 cm Durchmesser",
        "Beschreibung": "Gabi Wartmann (*1967), Pfarrerin, die sich in Marokko zum Studium der evangelischen Theologie entschied.  Im arabischen Segensspruch «Bismillah ir-Rahman ir-Rahim» (dt.: Im Namen Allahs, dem Gnädigen und Mitfühlenden) auf dem Keramikteller aus Palästina zeigt sich für Gabi Wartmann die tiefe Menschenfreundlichkeit des Islams.  Leihgabe von Gabi Wartmann",
        "Erwerbsart": "Leihgabe von Gabi Wartmann, Stöcklimattstrasse 26, 4512 Langendorf",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "12",
        "Person": "Gabi Wartmann",
        "Lebensdaten": "1967",
        "Infotext": "1989 folgte Gabi Wartmann ihrem damaligen Freund in seine Heimat Casablanca. Die Gastfreundschaft und der Alltag im muslimischen Marokko waren der jungen Schweizerin fremd und vertraut zugleich. Sie erkannte die eigene Verwurzelung in der christlichen Tradition und begann – zurück in der Schweiz – das Studium der reformierten Theologie. Sie fand im Studium Fragen nach der eigenen Herkunft und der abendländischen Tradition beantwortet. Nach der Geburt ihrer beiden Kinder wurde Gabi Wartmann mit 39 Jahren als reformierte Pfarrerin ordiniert und trat in Aarau an der Stadtkirche ihre erste Stelle an. Gabi Wartmann schätzt die Denkfreiheit in der reformierten Konfession. Am liebsten führt sie Tauffeiern durch. Im arabischen Segensspruch «Bismillah ir-Rahman ir-Rahim» (dt.: Im Namen Allahs, dem Gnädigen und Mitfühlenden) auf dem Keramikteller aus Palästina zeigt sich Gabi Wartmann die tiefe Menschenfreundlichkeit des Islams. Er macht für sie deutlich, dass Gott den Menschen in jeder Religion begegnet.\r"
    },
    {
        "DateinameBild": "Lienhard_Verena_01.jpg",
        "Inventar Nr": "SM 2014-0441",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "IBM-Festplatte, Modell DPEA-31080",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "",
        "Systematik": "Informatik, Computer, PC",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "2.5 x 14.2 x 10.1 cm Objektmass",
        "Beschreibung": "Verena Lienhard (geb. 1960) startete ihre IT-Laufbahn in den 1980er-Jahren als Programmiererin, baute sich aber bald ein zweites Standbein als Musikerin auf.  Leihgabe von Verena Lienhard",
        "Erwerbsart": "Leihgabe von Verena Lienhard",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "48",
        "Person": "Verena Lienhard",
        "Lebensdaten": "1960",
        "Infotext": "Seit den 1980er-Jahren hat Verena Lienhard PCs und ihre Entwicklung hautnah miterlebt. Sie begann in der aufstrebenden Branche als Programmiererin grosser IT-Projekte, später nutzte sie den Computer als Mitarbeiterin im Musikladen eines Instrumentenbauers. Heute vermittelt sie ihr Computerwissen. Im Wunsch nach Ganzheitlichkeit machte sie Mitte 30 eine Umschulung und war mehrere Jahre als Instrumentallehrerin tätig. 2007 gründete die Aarauerin ein Atelier für individuelle PC-Schulungen. Sie coacht Menschen, die sich in ihrem eigenen Tempo mit den persönlichen Bedürfnissen im Umgang mit dem PC befassen möchten. Daneben setzt sie sich intensiv mit Musik und Improvisation auseinander und spielt in verschiedenen Musikformationen. Die Suche nach Essenz, nach kreativem Tun und stimmigem Rhythmus ist für Verena Lienhard Antrieb und Herausforderung zugleich.\r"
    },
    {
        "DateinameBild": "Akyuez_Oemer.jpg",
        "Inventar Nr": "SM 2014-0442",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Türe eines Sicherungskastens, mit Aufschrift",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Kunststoff",
        "Systematik": "",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "2 x 29.6 x 27.5 cm Objektmass",
        "Beschreibung": "Mit Aufkleber eines Wappens der Schweiz und einem Bild des Rütlischwurs sowie Aufschrift \"1291 Gründung der/Eidgenossenschaft/auf dem Rütli/Arnold von Melchtal/Walter Fürst/Werner Stauffacher/aus Unterwalden\".  Persönlicher «Schweizer Altar» von Ömer Akyüz (*1935), der 1962 mit einer Arbeitsbewilligung der Aarauer Schreinerei Gysi aus der Türkei in die Schweiz einreiste. Seit 2006 brät der bekennende Aarauer täglich Bratwürste in seinem Imbisswagen am Graben.",
        "Erwerbsart": "Schenkung von Ömer Akyüz, Rosenweg 4, 5040 Schöftland",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "2",
        "Person": "Ömer Akyüz",
        "Lebensdaten": "1935",
        "Infotext": "1962 reiste Ömer Akyüz mit einer Arbeitsbewilligung der Aarauer Schreinerei Gysi aus der Türkei in die Schweiz ein. Bei einer Arbeit im Bündnerland lernte er seine Frau Ruth kennen. Auf Drängen seines alten Chefs Gysi kehrte die Familie Akyüz 1972 mit ihren beiden Kindern nach Aarau zurück. Das Paar führte verschiedene Gastrobetriebe. Zunächst war es Pächter der traditionsreichen «Zunftstube » an der Pelzgasse. 1993 eröffnete Akyüz «Ömer’s Imbiss » an der Ecke Rathausgasse/Kronengasse. Mit Kebab, Falafel und Dürüm brachte er eine neue Küche in die Kantonshauptstadt. Den Namen seines Lokals hat er schützen lassen. Seit 2006 brät der bekennende Aarauer täglich Bratwürste im Imbisswagen am Graben – begleitet von seinem persönlichen «Schweizer Altar» an der Wand.\r"
    },
    {
        "DateinameBild": "Hofmann_Urs_01.jpg",
        "Inventar Nr": "SM 2014-0443",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "\"Aquarium\" mit gefärbtem Wasser",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Kunststoff, Wasser, Farbe",
        "Systematik": "Gefässe, Aquarium",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "35.7 x 29.5 x 29.5 cm Objektmass",
        "Beschreibung": "Regierungsrat Urs Hofmann (*1956) wuchs in der noch fast ländlichen Telli auf. Eine prägende Kindheitserinnerung ist der von der Färberei Jenny rot gefärbte Sengelbach.  Schenkung von Urs Hofmann",
        "Erwerbsart": "Schenkung von Urs Hofmann",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "21",
        "Person": "Urs Hofmann",
        "Lebensdaten": "1956",
        "Infotext": "Täglich erkundete Urs Hofmann mit seinen Freunden aus der Umgebung die Aarauer Telli. In den 1960er-Jahren war sie ein überschaubares Quartier mit dörflichem Charakter. Unweit der Wohnung im dritten Block an der Maienzugstrasse floss der Sengelbach, ein Tummelplatz der Telli-Kinder. Das von der Färberei Jenny rot gefärbte Bachwasser bleibt Urs Hofmann bis heute in Erinnerung. Im Zoo des Restaurants Telli bestaunten die Kinder Affen, Vögel und Schlangen und erhielten vom Wirt Erdbeerglace aus der einzigen Tiefkühltruhe weit und breit. Dass man in den höher gelegenen Stadtquartieren die Telli mit ihren vielen Bähnlern und Pöstlern eher belächelte, realisierte Urs Hofmann erst als junger Erwachsener. Zwischenzeitlich hat ihn seine politische Karriere weitherum geführt. Aber auf seine ersten 29 Jahre in der Telli ist er bis heute stolz.\r"
    },
    {
        "DateinameBild": "Crivaro_Alex.jpg",
        "Inventar Nr": "SM 2014-0444",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Leuchtschrift \"uptown\"",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Metall",
        "Systematik": "Leuchtschrift, Flösserplatz",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "30.2 x 162.4 x 9.1 cm Objektmass",
        "Beschreibung": "Das Tanzlokal \"uptown\" im städtischen Jugendhaus Flösserplatz war in den 1990er-Jahren bekannt. Als Namensgeber fungierte die selbst gemachte Leuchtschrift eines Schreinerlehrlings.   Alex Crivaro (*1977) legte den Grundstein zu seiner Gastrokarriere schon als Jugendlicher, als er im Flössi die Bar organisierte.   Leihgabe von Alex Crivaro",
        "Erwerbsart": "Leihgabe von Alessandro Crivaro, Wynenmattestrasse 6, 5034 Suhr",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "60",
        "Person": "Alexx Crivaro",
        "Lebensdaten": "1977",
        "Infotext": "Mit 13 Jahren wurde Alex Crivaro Barkeeper im städtischen Jugendhaus Flösserplatz. Zwei Jahre später besass er einen Passepartout und betreute Bar und Küche. Crivaro zog im Haus die Fäden. Die Jugendarbeiter lenkten seine Energie in produktive Bahnen und vertrauten dem engagierten Jugendlichen. Er organisierte monatlich den «Hiphopjoint» und einmal im Jahr die «CHSpring Jam». Dabei kämpften Sprayer, Breaker, Rapper und DJs aus dem ganzen Mittelland um den Einzug ins Finale. Am Wochenende schnitt er für Fr. 5.- Haare. Das Tanzlokal «uptown» im Flössi war in den 1990er-Jahren bekannt. Als Namensgeber fungierte die selbst gemachte Leuchtschrift eines Schreinerlehrlings, der im Haus ein- und ausging. Crivaro liess sie bei seinem Abschied mitgehen – als Erinnerung an die guten Jahre. Sein Organisationstalent sichert ihm heute die Existenz: Er führt drei Gastronomiebetriebe in der Altstadt.\r"
    },
    {
        "DateinameBild": "Walther_Theres.jpg",
        "Inventar Nr": "SM 2014-0446",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Haustürschlüssel, Freihofweg 11, Aarau",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Metall",
        "Systematik": "Schlüssel",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "0.5 x 3.5 x 13.6 cm Objektmass",
        "Beschreibung": "Theres Walther (*1943) kaufte sich eine alte Täfeli-Fabrik am Freihofweg, die in den 1980er-Jahren zum alternativen Kulturtreffpunkt wurde.  Leihgabe von Theres Walther",
        "Erwerbsart": "Leihgabe von Theres Walther, Freihofweg 11, 5000 Aarau",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "93",
        "Person": "Theres Walther",
        "Lebensdaten": "1943",
        "Infotext": "1976 wurde die Täfelifabrik am Freihofweg zum Verkauf ausgeschrieben. Theres Walther und ihre Familie begeisterten sich für die Möglichkeit, die 1831 erbaute Textilfabrik in Wohnraum umzuwandeln. Im Architekturbüro Metron AG fanden sie experimentierfreudige Partner. Mit der Begründung, das Haus sei baurechtswidrig, zwangen die Aarauer Behörden die neuen Besitzer zu zwei Prozessen gegen die Stadtverwaltung. Während dieser Zeit war der Fabrikschlüssel Träger von Theres Walthers Hoffnungen. Schliesslich entschied das Verwaltungsgericht zu ihren Gunsten. 1979 zog die Familie in die Täfelifabrik. Ihre alternative Lebensform kannte keine abgeschlossenen Türen. Deshalb blieb der Hausschlüssel bis zur Auswechslung der alten Fabriktüre unbenutzt. Im Keller etablierte sich in den Achtzigern ein Treffpunkt der linken Kulturszene. Punkkonzerte, Theater, Filmvorführungen des Kinos Freier Film Aarau oder eine Vernissagefeier des Aarauer Kunsthauses fanden statt.\r"
    },
    {
        "DateinameBild": "Bruehwiler_Fridolin_01.jpg",
        "Inventar Nr": "SM 2014-0448",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Schale, handbemalt von Fridolin Brühwiler (1909-2003)",
        "Zusatz": "Vgl. 2003.05.20.S002",
        "Datierung": "1950",
        "Signatur": "\"Handgemalt/F. Brühwiler/Aarau\"",
        "Beschriftung": "auf Boden (Etikette): \"Handgemalt von/Fridolin A. Brühwiler/Rain 24/5000 Aarau/Tel. 064.22.15.36\", \"90.--\" u. eingebrannt \"111/2\"",
        "Material": "Porzellan, bemalt",
        "Systematik": "Geschirr, Gefäss, Schale",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "3.4 x 13.5 x 13.5 cm Objektmass",
        "Beschreibung": "Fridolin Brühwiler, Bankangestellter, bemalte nebenamtlich über Jahrzehnte Porzellan.",
        "Erwerbsart": "Nachlass von Fridolin Brühwiler, Graben, Aarau. Testamentsvollstrecker: Manfred Weibel, Trimbach, Tel. 079 356 48 05",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "76",
        "Person": "Fridolin Brühwiler",
        "Lebensdaten": "1909-2003",
        "Infotext": "Nach einer Banklehre in der Ostschweiz kam Fridolin Brühwiler 1930 nach Aarau. Bis zu seiner Pensionierung war er für die Schweizerische Bankgesellschaft tätig. Bereits in den Dreissigerjahren entdeckte er seine Leidenschaft für die Porzellanmalerei. Über die Jahre hinweg schuf er eine imposante Sammlung von rund 300 Stück bemaltem Porzellan. Noch mit über 90 Jahren kopierte Brühwiler Ornamente, entwarf eigene Designs für Vasen, Kuchenplatten, Duftfläschchen, Sahnekännchen, Zuckerdosen, Tassen und Teller aus dem 18. und 19. Jahrhundert. Kleinere Gegenstände brannte er im hauseigenen Ofen, grössere Stücke brachte er zu einem Fachmann in Zürich.\r"
    },
    {
        "DateinameBild": "Christen_Paul.jpg",
        "Inventar Nr": "SM 2014-0449",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Erste Autobahnvignette der Schweiz",
        "Zusatz": "",
        "Datierung": "1999",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Papier",
        "Systematik": "Autobahnvignette, Paul Christen",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "6.6 x 7 cm Blattmass",
        "Beschreibung": "Paul Christen (1947-2020) verantwortete als Produktionsleiter der Firma Trüb die Anfänge des Plastikdrucks.  Leihgabe der Firma Trüb AG",
        "Erwerbsart": "Leihgabe von Gemalto AG (ehemals Trüb AG), Stephan Lips, Hintere Bahnhofstr. 12, 5001 Aarau",
        "Herkunft": "",
        "Raumverweis": "4. OG",
        "NummerObjektteppich": "81",
        "Person": "Paul Christen",
        "Lebensdaten": "1947",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Dell-Aquila_Sylvia.jpg",
        "Inventar Nr": "SM 2014-0450",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Blinkendes Ansteckherz",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Kunststoff, Metall",
        "Systematik": "Ansteckherz, Blinkherz",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "2.2 x 3.6 x 3.6 cm Objektmass",
        "Beschreibung": "Silvia Dell'Aquila (geb. 1976) setzt sich für ein attraktives Nachtleben in Aarau ein. Die von ihr lancierte Partyreihe \"we love Aarau\" hatte als Erkennungsmerkmal ein blinkendes Ansteckherz.",
        "Erwerbsart": "Geschenk von Silvia Dell'Aquila",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "34",
        "Person": "Silvia Dell'Aquila",
        "Lebensdaten": "1976",
        "Infotext": "2008 entwickelte Silvia Dell’Aquila zusammen mit einem Freund für den Boiler Club ein neues Partylabel: «We Love Aarau» feiert die Stadt und steht schon bald für ein neues Selbstbewusstsein Aaraus. Im selben Jahr rief die Wahlaarauerin die gleichnamige Website ins Leben. Sie publiziert darauf aktuelle Veranstaltungen aller Art und informiert wöchentlich über 1200 Interessierte via Newsletter. Oft entscheidet sie sich für Nischenevents. Dass die Auswahl ihren eigenen Kulturgeschmack widerspiegelt, akzeptieren Veranstalterinnen und Veranstalter problemlos. Als aufmerksame Beobachterin der Szene registriert sie die neusten Trends und gestaltet als Einwohnerrätin kulturpolitische Entwicklungen mit. Das wachsende Selbstbewusstsein der Aarauerinnen und Aarauer erfüllt Silvia Dell’Aquila mit Stolz: «Wer nach Aarau zieht, der sucht diese Stadt. Aarau ist eine Entscheidung.»\r"
    },
    {
        "DateinameBild": "Gottwald_Reinhard.jpg",
        "Inventar Nr": "SM 2014-0451",
        "Ref_ Nr_": "",
        "Künstler": "KERN & Co. AG (Aarau)",
        "Bezeichnung": "Elektronischer Theodolit KERNSwiss E10 5, Prototyp",
        "Zusatz": "mit Transportbox",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Kunststoff, Metall, Glas",
        "Systematik": "Vermessungsinstrumente, Theodolit, Kern",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "47.5 x 40.2 x 24.2 cm (Transportbox) Objektmass",
        "Beschreibung": "Reinhard Gottwald (*1952) war letzter Entwicklungsleiter von Kern & Co. AG Aarau. Mit dem neuartigen Theodoliten E10 hofften er und sein Team, Kern im letzten Moment noch aus der Krise zu führen.  Mit der Übernahme von Kern durch WILD/Leitz im Jahre 1988/89 wurde das Projekt gestoppt. Einige Ideen, insbesondere das Bedienungskonzept, wurden jedoch in der WILD Baureihe TC1010/1610 verwirklicht.  Schenkung ETH Zürich, Institut für Geodäsie und Photogrammetrie",
        "Erwerbsart": "Schenkung der ETH Zürich, Institut für Geodäsie und Photogrammetrie",
        "Herkunft": "",
        "Raumverweis": "4. OG",
        "NummerObjektteppich": "66",
        "Person": "Reinhard Gottwald",
        "Lebensdaten": "1952",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Hoffman_Felix_01.jpg",
        "Inventar Nr": "SM 2014-0452",
        "Ref_ Nr_": "",
        "Künstler": "Felix Hoffmann (Aarau 1911 - 1975 Aarau)",
        "Bezeichnung": "Druckstock von Felix Hoffmann",
        "Zusatz": "",
        "Datierung": "1971",
        "Signatur": "",
        "Beschriftung": "\"18. April 1971\"",
        "Material": "Holz",
        "Systematik": "Holzschnitt, Kunst, Druckstock, Druckstöcke",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "2.5 x 12 x 15 cm Objektmass",
        "Beschreibung": "Felix Hoffmann war Künstler und Grafiker, der als Buchillustrator und Glasmaler bekannt wurde.",
        "Erwerbsart": "Schenkung der Erbengemeinschaft von Felix Hoffmann",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "6",
        "Person": "Felix Hoffmann",
        "Lebensdaten": "1911-1975",
        "Infotext": "Nach Studien in Basel, Karlsruhe und Berlin kehrte Felix Hoffmann 1935 in seine Geburtsstadt Aarau zurück. Hoffmann arbeitete als Grafiker, Buchillustrator und Glasmaler. Zu seinen Werken in Aarau gehören die Glasfenster der Stadtkirche und die Totentanzdarstellung am Obertorturm. Er fertigte aber auch Landschaftszeichnungen mit der Feder oder Farbholzschnitte an. Die Sujets schnitzte er in hölzerne Druckstöcke. Berühmt wurde Hoffmann durch die Illustration der Märchen der Gebrüder Grimm. Fortan genoss er in der Schweiz, in Amerika und Japan grosses Ansehen als Illustrator von Kinder- und Jugendbüchern. Er gewann unter anderem den Schweizer Jugendbuchpreis 1957 und den New York Herald Tribune Children’s Book Award 1962.\r"
    },
    {
        "DateinameBild": "Kuehn_Rolf.jpg",
        "Inventar Nr": "SM 2014-0454",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Tonbandkassette TDK SA-X90: Erinnerungen von Rolf Kühn (1919-2005)",
        "Zusatz": "",
        "Datierung": "1993",
        "Signatur": "",
        "Beschriftung": "Auf Klebeetikette (Kugelschreiber): \"KÜHN Rolf 13.11.1993/lebte 30er J. im Schlössli\"",
        "Material": "",
        "Systematik": "Tonbandkassette",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "1.4 x 10.8 x 6.8 cm Objektmass",
        "Beschreibung": "Rolf Kühn (1919-2005) verlebte einen Teil seiner Jugend im Schlössli, seine Familie hatte die Wohnung im ersten Stock gemietet. Kühns aufgezeichnete Erinnerungen von 1993 zeugen von viel Freiraum im weitläufigen Gebäude: Fahrradfahren auf den Gängen, Tischtennisspielen auf dem Estrich und ein Prachtausblick auf die Aare.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "2. OG",
        "NummerObjektteppich": "11",
        "Person": "Rolf Kühn",
        "Lebensdaten": "1919",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Mecki.jpg",
        "Inventar Nr": "SM 2014-0455",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Igelpuppe Frau Mecki",
        "Zusatz": "",
        "Datierung": "1950er-Jahre",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Kunststoff",
        "Systematik": "Puppen",
        "Geogr_ Bezug": "",
        "Technik": "",
        "Massangaben": "26 x 17 x 8 cm Objektmass",
        "Beschreibung": "Mecki war in den 1950er-Jahren das Maskottchen der Programmzeitschrift „Hörzu“ und äusserst beliebt.",
        "Erwerbsart": "Ankauf. Ersteigert bei Ebay ??",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "39",
        "Person": "Mecki",
        "Lebensdaten": "1955",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Neeser_Gody_02.jpg",
        "Inventar Nr": "SM 2014-0456",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Ehrengabe der Firma Kern für Godi Neeser (*1944)",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "auf Holzsockel: \"KERNSWISS\", auf Metallplakette: \"Betriebs/Kommissionen\"",
        "Material": "Glas?, Kunststoff?, Holz, Metall",
        "Systematik": "Ehrengabe",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "13.5 x 12 x 9.5 cm Objektmass",
        "Beschreibung": "Gody Neeser war Personalchef während der Schliessung der Firma Kern 1991.",
        "Erwerbsart": "Schenkung",
        "Herkunft": "Sammlung Kern, Stadtmuseum Aarau",
        "Raumverweis": "4. OG",
        "NummerObjektteppich": "73",
        "Person": "Godi Neeser",
        "Lebensdaten": "1944",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Sprecher_Carl.jpg",
        "Inventar Nr": "SM 2014-0457-a-d",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Heizspulen für Elektroofen Calenta der Firma Sprecher + Schuh",
        "Zusatz": "Eingewickelt in Packpapier",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "auf Packpapier (Kugelschreiber): \"4 Heizspuhlen für el. Oefen-Calenta/(von Papa)\"",
        "Material": "Keramik",
        "Systematik": "Heizspulen, Heizstäbe, Elektrotechnik, Industrie, Sprecher + Schuh, Kockel",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "Länge 20 cm Objektmass 5 cm Durchmesser",
        "Beschreibung": "Carl Sprecher (1868-1936), Besitzer von Sprecher + Schuh AG. Die Firma produzierte elektrische Geräte in allen Grössen. Neben Transformatoren für Eisenbahnen gehörten auch die kleinen Heizspulen für Elektroöfen zum Sortiment.",
        "Erwerbsart": "",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "64",
        "Person": "Carl Sprecher",
        "Lebensdaten": "1868-1938",
        "Infotext": "Nach dem Technikum in Winterthur wurde der gelernte Mechaniker Carl Sprecher 1896 Betriebsleiter des Elektrizitätswerks Aarau. 1900 übernahm er mit dem Aarauer Kaufmann Hans Fretz eine Firma für elektrische Apparate in Aarburg. Sie verlegten die Fabrik nach Aarau und produzierten hauptsächlich den Hörnerschalter (10 kV), eine Erfindung von Sprecher. Als die Energieproduktion nach 1900 in der Schweiz erheblich anstieg, hielt Sprecher mit der Entwicklung Schritt. Er fand im Apparatebauer Heinrich Schuh einen neuen technischen Leiter und Teilinhaber. Der Firmenname Sprecher & Schuh, der sich im Aarauer Volksmund als Schnörri & Schlarpi festsetzte, ging aus dieser Zusammenarbeit hervor. Die Firma produzierte elektrische Geräte in allen Grössen. Neben Transformatoren für Eisenbahnen gehörte auch die kleine Heizspule für Elektroöfen zum Sortiment. Bis 1914 wurden etwa 500 Stellen geschaffen. Im selben Jahr trat Sprecher als Leiter zurück, blieb aber bis zu seinem Tod 1938 im Verwaltungsrat. Nach Jahren der Hochkonjunktur wurde Sprecher & Schuh schrittweise an Unternehmen wie Alstom SA oder Rockwell Automation USA verkauft, bevor sie 1995 endgültig ihre Tore schloss. Das Hochhaus Sprecherhof im Torfeld Süd wurde als einstiges Wahrzeichen der Firma 2013 gesprengt.\r"
    },
    {
        "DateinameBild": "Hunziker_Samuel_02.jpg",
        "Inventar Nr": "SM 2014-0459",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Nagel aus der Fensterleibung des Westanbaus des Alten Turms",
        "Zusatz": "",
        "Datierung": "um 1760",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Eisen",
        "Systematik": "Nagel, Hunziker",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "0.3 x 6.5 x 2.5 cm Objektmass",
        "Beschreibung": "Der Textilfabrikant Samuel Hunziker (1713-1791) erweiterte 1761 das Schlössli um den Westanbau. Der Nagel stammt aus einer Fensterleibung, die durch den Erweiterungsbau 2013 entfernt wurde.",
        "Erwerbsart": "Fund",
        "Herkunft": "Der Nagel stammt aus einer Fensterleibung, die durch den Erweiterungsbau 2013 entfert wurde.",
        "Raumverweis": "2. OG",
        "NummerObjektteppich": "1",
        "Person": "Samuel Hunziker",
        "Lebensdaten": "1713-1791",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Zuercher_Walther.jpg",
        "Inventar Nr": "SM 2014-0460",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Reproduktion: Urkunde der NASA für Walter Zürcher vom 20. Juli 1969",
        "Zusatz": "",
        "Datierung": "1969",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Papier",
        "Systematik": "Urkunde",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Laserprint der Originalurkunde",
        "Massangaben": "20.3 x 26.7 cm Blattmass",
        "Beschreibung": "Walter Zürcher (1929-2018) war Leiter der Optikabteilung bei Kern. In seiner Zeit produzierte Kern die Linse für die Hasselblad-Kamera, mit der die Astronauten der Apollo 11 die erste Mondlandung dokumentierten. Für die gute Zusammenarbeit stellte ihm die Nasa diese Urkunde aus.",
        "Erwerbsart": "Geschenk von Walter Zürcher. Scan der Original-Urkunde",
        "Herkunft": "",
        "Raumverweis": "4. OG",
        "NummerObjektteppich": "62",
        "Person": "Walter Zürcher",
        "Lebensdaten": "1929",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Tschamper_Max_01.jpg",
        "Inventar Nr": "SM 2014-0461",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Medizinischer Gerätewagen PREXA aus der Praxis von Dr. Max Tschamper",
        "Zusatz": "",
        "Datierung": "1950er-Jahre",
        "Signatur": "",
        "Beschriftung": "l. o. (Metallplakette): \"Apparatebau Chirurgische Instrumente/PREXA/ WULLSCHLEGER & SCHWARZ BASEL/ Unterer Heuberg 2/Typ PREXA/A 2,5/V 220/10/ 4\"",
        "Material": "Metall, Glas, Gummi, Kunststoff, Holz",
        "Systematik": "Medizintechnik, Apparatebau, Chirurgische Instrumente",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "88 x 57 x 45 cm Objektmass",
        "Beschreibung": "Gerätewagen auf vier Rollen. Drei Schubladen, gefüllt mit medizinischem Instrumentarium, mit Generator.  Bestandteil der Praxiseinrichtung von Dr. Max Tschamper (1906-1992), der bis ins hohe Alter eine HNO-Praxis im Haus zum Schlossgarten führte und seine ganze Praxiseinrichtung dem Museum vermachte. Gemäss Ziffer 1.2. des Schenkungsvertrages \"zeigt dieses Zimmer eine Arztpraxis aus der Mitte des 20. Jahrhunderts und erinnert zugleich an die Persönlichkeit Dr. Tschampers, der über 5 Jahrzehnte in Aarau wirkte\".",
        "Erwerbsart": "Schenkung von Dr. M. Tschamper, Laurenzenvorstadt 3, 5000 Aarau",
        "Herkunft": "Bestandteil seines Praxiszimmers.",
        "Raumverweis": "",
        "NummerObjektteppich": "49",
        "Person": "May Tschamper",
        "Lebensdaten": "1906-1992",
        "Infotext": "Nach der Schulzeit in Aarau studierte Max Tschamper in Basel Medizin. Gemeinsam mit seiner Frau, der Krankenschwester Rose-Maria Brupbacher eröffnete er in der Villa Schlossgarten in Aarau eine Praxis für Hals-, Nasen- und Ohrenmedizin. Als Ausgleich pflegte der Naturfreund den weitläufigen Garten der Villa. Max Tschamper wirkte über fünf Jahrzehnte als Arzt. Er empfing noch mit über 80 Jahren Patienten in seiner Praxis. Als er 1989 aufhörte, schenkte er die gesamte Praxiseinrichtung dem Museum. Nasenscheren, Absaugschläuche und andere chirurgische Instrumente aus der Mitte des 20. Jahrhunderts, sogar Zangen für das Herausziehen von Zähnen finden sich in seiner Praxis. Vor allem in der Anfangszeit betätigte sich Max Tschamper zwischendurch auch als Zahnarzt.\r"
    },
    {
        "DateinameBild": "Mueller_Stefan.jpg",
        "Inventar Nr": "SM 2014-0462",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Anzug von Stephan Müller (*1965)",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Wolle, Polyester, Spandex. Futter: Viskose",
        "Systematik": "Herrenbekleidung, Herrenanzug, Sakko, Hose",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "Grösse 50 Objektmass",
        "Beschreibung": "Sakko mit drei Knöpfen und Hose, Marke ZARA, Made in Litauen.  Stefan Müller kandidierte 2001 fürs Stadtpräsidium und forderte seit über 100 Jahren zum ersten Mal einen amtieren Stadtammann heraus. Den Anzug fürs Werbefoto lieh er sich von einem Freund.",
        "Erwerbsart": "Ankauf von David Joël Preiswerk, Häsingerstrasse 36, 4055 Basel",
        "Herkunft": "David Joël Preiswerk hat Stephan Müller den Anzug für den Wahlkampf ausgeliehen.",
        "Raumverweis": "",
        "NummerObjektteppich": "46",
        "Person": "Stephan Müller",
        "Lebensdaten": "1965",
        "Infotext": "Beim Blick in die Akten des Aarauer Stadtrats fand Stephan Müller heraus, dass 1889 die letzte Kampfwahl ums Stadtpräsidium stattgefunden hatte. Dieses historische Faktum nahm der Aarauer zum Anlass, im Herbst 2001 als Parteiloser unter dem Motto «JETZT!» direkt für den Posten des Aarauer Stadtammanns zu kandidieren. Er erreichte auf Anhieb rund 24% der Stimmen als Stadtammann und über 42% als Stadtrat. Für einen Sitzgewinn in der städtischen Exekutive reichte es jedoch nicht. Vom Achtungserfolg beflügelt gründete er mit Gleichgesinnten die Partei JETZT!, welche von 2002 bis 2013 im Aarauer Einwohnerrat vertreten war und 2005 einen weiteren Angriff auf das Amt des Stadtammanns unterstützte. Bei der Wahl 2005 überraschte Stephan Müller mit einem Plakat, auf welchem er – entgegen seiner sonstigen Kleidergewohnheiten – in Hemd und geliehenem Anzug posierte und sich damit zum Stadtgespräch machte.\r"
    },
    {
        "DateinameBild": "Weiersmueller_Willy_02.jpg",
        "Inventar Nr": "SM 2014-0463",
        "Ref_ Nr_": "",
        "Künstler": "Paillard SA, Yverdon (Yverdon VD)",
        "Bezeichnung": "Mechanische Schreibmaschine Hermes von Willy Weiersmüller (1912-1985)",
        "Zusatz": "",
        "Datierung": "20.Jh.",
        "Signatur": "",
        "Beschriftung": "Vorne: \"HERMES\", \"paillard\" u. Firmenlogo. Hinten (Stempel): \"L. M. CAMPICHE/ REPRESENTANT GENERAL/LAUSANNE\"",
        "Material": "",
        "Systematik": "Schreibmaschine, Bürotechnik",
        "Geogr_ Bezug": "Schweiz",
        "Technik": "Kunststoff, Metall, Textil",
        "Massangaben": "25 x 45 x 33.5 cm Objektmass",
        "Beschreibung": "Der Kleinbauer aus Rohr reparierte für Remington im Aussendienst Schreibmaschinen und fuhr mit Velo und Zug zu seinen Kunden im ganzen Kanton.  Leihgabe von Hanni Weiersmüller",
        "Erwerbsart": "Leihgabe von Hanni Weiersmüller, Römerstrasse 14, 5032 Aarau Rohr",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "91",
        "Person": "Willi Weiersmüller",
        "Lebensdaten": "1912-1985",
        "Infotext": "„Lähsämis Willi“ wuchs in Rohr in einem Kleinbauernbetrieb mit vier Geschwistern auf. Pöstler wäre sein Traumberuf gewesen, aber die Bezirksschule durfte er wegen der knappen Familienfinanzen nicht besuchen. So lernte er Schreibmaschinenmechaniker und arbeitete bis zur Pensionierung bei der Firma Remington im Aussendienst. Mit Bahn und Militärfahrrad fuhr er zu den Kunden im ganzen Kanton. Daneben führte er den Bauernbetrieb weiter. Als sein Vater an den Folgen eines Unfalls 1947 starb, sorgten er und seine Schwester für die Mutter. Mit 21 Jahren lernte er seine Frau Helen kennen. Aus Existenzängsten heirateten sie erst sechzehn Jahre später. Mit ihren drei Kindern, der Grossmutter und der ledigen Tante lebten sie auf dem Bauernhof. In den 1960er-Jahren vermieteten sie Zimmer an italienische Gastarbeiter. Es entstanden freundschaftliche Kontakte am Esstisch; zur Freude der Kinder kamen neu auch Spaghetti auf den Tisch. 1962 gab ein Landverkauf der Familie erstmals ein finanzielles Polster. In einem mutigen Schritt kaufte sich Willi im Tessin ein Rustico mit Weinberg. Dieser Ort wurde für ihn und seine Frau zum „piccolo Paradiso“ bis ins Alter.\r"
    },
    {
        "DateinameBild": "Wyler_Betti.jpg",
        "Inventar Nr": "SM 2014-0464",
        "Ref_ Nr_": "",
        "Künstler": "Lotti Fellner (Aarau 1924 - 2018 Aarau)",
        "Bezeichnung": "Portrait von Betty Wyler-Jaeger (1890-1992), Mutter der Künstlerin",
        "Zusatz": "",
        "Datierung": "1976",
        "Signatur": "",
        "Beschriftung": "Hinten o. l. (Klebeetikette/Filzstift): \"Lotti Fellner/Mama 1976/für [unleserlich u. durgestrichen] Tommy\"",
        "Material": "",
        "Systematik": "Frauenportrait, Malerei",
        "Geogr_ Bezug": "Aarau",
        "Technik": "Gouache? Acryl? auf Pavatex, Zierrahmen aus Holz, profiliert und vergoldet",
        "Massangaben": "42.7 x 35.2 x 4 cm Rahmenmass 33 x 25.5 cm Plattenmass 31.3 x 23.9 cm Lichtmass",
        "Beschreibung": "Betty Wyler studierte Anfang des 20. Jahrhunderts als eine der ersten Schweizerinnen an der ETH Zürich. Die Biologin war die Tochter von Carl Jaeger und heiratete den Maler Otto Wyler.  Leihgabe von Lotti Fellner-Wyler",
        "Erwerbsart": "Leihgabe von Lotti Fellner-Wyler, Altersheim Golatti, 5000 Aarau, * 27.4.1924, † 16.2.2018",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "70",
        "Person": "Betty Wyler-Jaeger",
        "Lebensdaten": "1890-1992",
        "Infotext": "Betty durfte, was in ihrer Zeit sonst Buben vorbehalten blieb. Die Eltern förderten sie in den Naturwissenschaften, sie trug Hosen und machte Sport. In der alten Kantonsschule war sie fast das einzige Mädchen. 1912 erhielt sie als eine der ersten Frauen ein Diplom an der ETH Zürich. Sie verliebte sich in den Aarauer Maler Otto Wyler und zog mit ihm ins Engadin. Die vier Kinder kamen zur Welt und Betty war mit neuen Lebensumständen konfrontiert. Sie war ohne jegliche Geldsorgen mit Dienstboten aufgewachsen. Mit der ihr eigenen Wissbegier lernte sie nun Kochen und ökonomisches Haushalten. Nur falls im Familienalltag keine Zeit für die Lektüre der NZZ blieb, machte sie das unglücklich. Ab 1924 lebte die Familie wieder in Aarau. Wenn Otto Wyler zum Malen verreiste, begleitete sie ihn oft. Nach dem Tod ihres Mannes wohnte Sie in seinem Atelier. Ihr ausgezeichnetes Gedächtnis behielt sie bis ins hohe Alter. Noch an ihrem 100. Geburtstag waren ihr die lateinischen Namen vieler Blumen präsent.\r"
    },
    {
        "DateinameBild": "Wehrli_Werner.jpg",
        "Inventar Nr": "SM 2014-0465",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Notenheft: Von einer Wanderung. 22 kleine Klavierstücke von Werner Wehrli, Op. 17",
        "Zusatz": "",
        "Datierung": "1921",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Papier",
        "Systematik": "Notenheft, Musiknoten",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "30.9 x 22.9 cm Blattmass",
        "Beschreibung": "Eigentum und Verlag von Hug & Co., Zürich  Werner Wehrli (1892-1944) studierte in Zürich, Berlin und Frankfurt Komposition. Er schloss 1918 in Basel ab und liess sich anschliessend in seiner Geburtsstadt Aarau nieder. Neben dem Komponieren und der Lehrtätigkeit am Lehrerinnenseminar war er Glockenexperte für die Aarauer Giesserei Rüetschi, sammelte Volksliedgut und war Leiter des Cäcilienvereins.",
        "Erwerbsart": "Ankauf Buch- und Musikalienhandlung Schlöhlein GmbH, Schützenmattstr. 15, Postfach 623, 4051 Basel",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "4",
        "Person": "Werner Wehrli",
        "Lebensdaten": "1892-1944",
        "Infotext": "Werner Wehrli studierte in Zürich, Berlin und Frankfurt Komposition und schloss 1918 in Basel ab. Insbesondere der Aufenthalt in Frankfurt prägte den jungen Komponisten. Er studierte gemeinsam mit Paul Hindemith, gewann 1914 den Mozart-Preis und lernte seine spätere Frau, die Sängerin Irma Bartholomae, kennen. 1918 liess sich das Ehepaar in Wehrlis Geburtsstadt Aarau nieder. Er trat eine Stelle als Musiklehrer am Lehrerinnenseminar an, die er bis zu seinem Tod innehatte. Wehrli komponierte Liedzyklen und Kammermusikwerke. Ausnahmen bilden die Festspielmusik zum Eidgenössischen Schützenfest in Aarau 1924 und das Aargauer Spiel an der Landesausstellung 1939. Neben dem Komponieren und der Lehrtätigkeit war er Glockenexperte für die Aarauer Giesserei Rüetschi, sammelte Volksliedgut und war Leiter des Cäcilienvereins.\r"
    },
    {
        "DateinameBild": "Notter_Bruno.jpg",
        "Inventar Nr": "SM 2014-0466",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Aufnäher Gemeindepolizei Rohr von Bruno Notter (*1941)",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "Schrift: \"GEMEINDE/POLIZEI/ROHR\" u. Wappen von Rohr",
        "Material": "Textil",
        "Systematik": "Polizeiuniform, Uniformbestandteile",
        "Geogr_ Bezug": "Aarau Rohr",
        "Technik": "maschinell bestickt",
        "Massangaben": "7.6 x 5.2 cm Objektmass",
        "Beschreibung": "Bruno Notter war der letzte Dorfpolizist von Rohr, bevor Rohr 2010 zu einem Stadtteil von Aarau wurde.",
        "Erwerbsart": "Schenkung von Bruno Notter, Griederweg 1, 5032 Aarau Rohr",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "79",
        "Person": "Bruno Notter",
        "Lebensdaten": "1941",
        "Infotext": "An sein Elternhaus grenzt die Bremgarten-Dietikon- Bahn. Vielleicht rührt daher die Begeisterung Bruno Notters für Eisenbahnen. So trat er seine erste Stelle als Monteur auch bei der SBB an. Als 29-Jähriger liess er sich zum Polizisten ausbilden. 17 Jahre wirkte er in Bremgarten, bevor er Dorfpolizist von Rohr wurde. Die grosse Verbrecherjagd gehörte nicht zu seinem Berufsalltag. Mit dem Dienstvelo stellte er Zahlungsbefehle zu, machte Lebensmittelkontrollen und verteilte Abstimmungsunterlagen. Er verkaufte Hundemarken und Velovignetten, bewilligte Werbetafeln und schrieb Rapporte über Velodiebstähle. Die liebste Aufgabe war ihm der Verkehrsunterricht für die Kindergärtner. Als sich die Polizei von Rohr und Aarau zusammenschloss, war die Ära der Dorfpolizisten zu Ende. Bruno Notter begann bei der Stadtpolizei Aarau im Verkehrsdienst. Seit Jahren trägt er Wissen über Eisenbahnen zusammen. Sein Archiv über die Bremgarten-Dietikon-Bahn, die Wynental-Suhrentalbahn oder die Aarauer Busbetriebe füllt mehrere Schränke.\r"
    },
    {
        "DateinameBild": "Hunziker_Germaine.jpg",
        "Inventar Nr": "SM 2014-0467",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Pfannendeckel zu Spielzeug-Pfanne",
        "Zusatz": "Gehört zu Spielzeug-Kochherd, Inv.-Nr. 2004.10.24.S033",
        "Datierung": "1929",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Blech",
        "Systematik": "Blechspielzeug, Kochherd, Pfannendeckel",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "9.4 cm Durchmesser Höhe: 1.5 cm Objektmass",
        "Beschreibung": "Der Pfannendeckel gehört zum Spielzeug-Kochhherd, Inv. Nr. 2004.10.24.S033.  Die Donatorin Germaine Hunziker-Bühler (1923-2012) hat die funktionierende originalgetreue Miniatur des ersten Elektrokochherdes 1929 von ihrem Vater zu Weihnachten erhalten.",
        "Erwerbsart": "Geschenk von Germaine Hunziker-Bühler, Zentenarweg 3, Aarau",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "36",
        "Person": "Germaine Hunziker-Bühler",
        "Lebensdaten": "1923-2012",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Frey_Charlotte.jpg",
        "Inventar Nr": "SM 2014-0468",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Mieder für die hölzerne Gliederpuppe in ehemaliger Augsburger Tracht",
        "Zusatz": "Die Puppe (Inventar-Nr. 2009-0094) gehörte Charlotte Frey-Frey (1784-1858)",
        "Datierung": "ca. 1792",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Holz",
        "Systematik": "Gliederpuppe, Puppenkleider, Mieder",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "geschnürt ca. 16 x 9 x 6 cm Objektmass geöffnet ca. 3 x 17 x 19 cm Objektmass",
        "Beschreibung": "Die Puppe (s. Inv. Nr. SM 2014-0094) von Charlotte Frey (1784-1858) wurde über Generationen in der Familie weitergegeben, bevor sie in die Sammlung des Stadtmuseum kam.",
        "Erwerbsart": "Schenkung von Heidi Vogel-Sauerländer, Eichstrasse 1, 8142 Uitikon-Waldegg, überbracht von Dominik Sauerländer, Aarau.",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "32",
        "Person": "Charlotte Frey",
        "Lebensdaten": "1784-1858",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Coronet_Giulia_01.jpg",
        "Inventar Nr": "SM 2015-0042",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Koffer von Giulia Mettauer-Coronet (*1928)",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "oben (Metalletikette): \"Fibre véritable\"",
        "Material": "Kunststoff, Leder (Gurt und Griff), Metall (Schliessen)",
        "Systematik": "Behältnisse, Koffer",
        "Geogr_ Bezug": "Schweiz",
        "Technik": "",
        "Massangaben": "mit Griff 50 x 80 x 24 cm Objektmass",
        "Beschreibung": "Giulia Coronet verliess als 18-Jährige mit diesem Koffer ihre Heimat Italien, um in Aarau eine Stelle anzutreten.",
        "Erwerbsart": "Leihgabe von Giulia Mettauer-Coronet",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "57",
        "Person": "Giulia Mettauer-Coronet",
        "Lebensdaten": "1928",
        "Infotext": "Giulia Coronet kam in der Nähe von Bologna in einer Bauarbeiterbaracke zur Welt. Vater Vittorio war Tunnelbauer mit eigenem Geschäft; die Familie lebte da, wo gebaut wurde. Fünf Jahre später kehrten sie in ihr Heimatdorf im Veneto zurück. Als Zwölfjährige trat Giulia ihre erste Stelle an. Um der hohen Arbeitslosigkeit nach dem Krieg entgegenzuwirken, vermittelte der italienische Staat Auswanderungswilligen Arbeitsplätze. So kam Giulia mit neunzehn nach Aarau. Sie erinnert sich: «An der Grenze mussten wir duschen, weil sie dachten, wir bringen Krankheiten aus Italien mit. Unsere Kartonkoffer mitsamt Inhalt wurden desinfiziert.» Giulia arbeitete zunächst als Dienstmädchen, ab 1950 war sie eine von 3000 italienischen Arbeiterinnen in der Schuhfabrik Bally in Schönenwerd. In dieser Zeit lernte sie beim Tanzen einen jungen Erlinsbacher kennen. Zu ihrer Freude konnte Ernesto Italienisch, da seine Mutter aus dem Bergell stammte. Zwei Söhne kamen zur Welt und Giulia arbeitete als Hauswartin im Kindergarten Goldern. Zwar hat sie das Heimatdorf nach dem Tod der Mutter nie mehr besucht, aber sie telefoniert jede Woche mit den Schwestern.\r"
    },
    {
        "DateinameBild": "Thaler_Thomas.jpg",
        "Inventar Nr": "SM 2015-0043",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Smartphone SAMSUNG S3 mini",
        "Zusatz": "",
        "Datierung": "",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Glas, Metalle",
        "Systematik": "Mobiltelefon, Smartphone, Handy, NFC-Technologie",
        "Geogr_ Bezug": "Schweiz",
        "Technik": "",
        "Massangaben": "1 x 7 x 13.6 cm Objektmass",
        "Beschreibung": "Gerät, welches die NFC-Technologie (Near Field Communication) unterstützt. Mit diesen Geräten können die neuen mobilen Applikationen genutzt werden, u. a. die Kredit-/Debitkarten.  Thomas Thaler (*1965), CTO von Trüb AG, treibt den Wandel der Firma von der Druckerei zur Softwareherstellerin voran.",
        "Erwerbsart": "Geschenk vom Handy-Doktor in Luzern, überbracht von Susanne Mangold",
        "Herkunft": "",
        "Raumverweis": "4. OG",
        "NummerObjektteppich": "85",
        "Person": "Thomas Thaler",
        "Lebensdaten": "1965",
        "Infotext": "Hier kommt noch Text.\r"
    },
    {
        "DateinameBild": "Fuernkranz_Irene_01.jpg",
        "Inventar Nr": "SM 2015-0051",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Deutscher Reisepass von Irene Fürnkranz (1904-1996); ausgestellt am 15.11.1938",
        "Zusatz": "",
        "Datierung": "1938",
        "Signatur": "",
        "Beschriftung": "",
        "Material": "Papier",
        "Systematik": "Reisepass",
        "Geogr_ Bezug": "",
        "Technik": "",
        "Massangaben": "",
        "Beschreibung": "Irene Fürnkranz kam als Flüchtlingskind nach dem ersten Weltkrieg nach Aarau und arbeitete als Sekretärin in mehreren Ländern. Sie liess sich in Aarau einbürgern, bevor sie Nonne im Kloster Bethanien in Kerns wurde.  Leihgabe Staatsarchiv Aargau",
        "Erwerbsart": "Leihgabe Staatsarchiv Aargau",
        "Herkunft": "",
        "Raumverweis": "",
        "NummerObjektteppich": "82",
        "Person": "Irene Fürnkranz",
        "Lebensdaten": "1904-1996",
        "Infotext": "In Italien geboren, kam die Österreicherin als Kind nach Aarau. Kurz darauf siedelte die Familie nach Frankreich um, wo sie im Ersten Weltkrieg interniert wurde. Aus dem Lager entlassen, kam Irene Fürnkranz mit Mutter und Schwester nach Aarau zurück. Sie bezogen eine Wohnung im Schlössli. Nach der Bezirksschule brach Irene nach Irland auf, um Englisch zu lernen. Es folgten Jahre in Budapest, bevor sie schliesslich wieder in Aarau heimisch wurde. Nach der Besetzung Österreichs durch Hitler beantragte Irene Fürnkranz erfolgreich das Schweizer Bürgerrecht. Kurz darauf wurde aus der fünfsprachigen Sekretärin eine Nonne: Irene trat ins Kloster Bethanien in Kerns ein. Auch als Schwester Johanna-Dominik führte sie kein zurückgezogenes Leben. Im Auftrag des Ordens reiste sie als Seelsorgerin in die Frauengefängnisse Europas. Mit ihrer herzlichen, vorurteilsfreien Art fand sie leicht den Zugang zu den Gefangenen.\r"
    },
    {
        "DateinameBild": "Trueb_Hans_01.jpg",
        "Inventar Nr": "ST 2014-0016",
        "Ref_ Nr_": "",
        "Künstler": "Unbekannt",
        "Bezeichnung": "Musterbuch für Wertpapierdruck aus der Firma Trüb",
        "Zusatz": "",
        "Datierung": "1891 - 1908",
        "Signatur": "",
        "Beschriftung": "auf Titelblatt: \"Wertpapierdruck/I\"",
        "Material": "Karton, Papier",
        "Systematik": "Trüb, Guillochen-Sicherheitsvordrucke, Musterbuch, Wertpapiere",
        "Geogr_ Bezug": "Aarau",
        "Technik": "",
        "Massangaben": "2.3 x 25.1 x 31.2 cm Objektmass",
        "Beschreibung": "Auf den Wertpapieren sind diverse Jahreszahlen mit Bleistift notiert. Im Musterbuch liegt eine Pergaminhülle mit Druckfilmen für Wertpapiere.  Hans Trüb (1925-2016), ehemaliger Patron der Firma Trüb. Als der Hauptbetriebszweig des Wertpapierdrucks zusammenbrach, fand er im völlig neuen Bereich der Plastikkarten eine Zukunft.",
        "Erwerbsart": "Schenkung von Hans Trüb",
        "Herkunft": "Trüb & Cie., Aarau",
        "Raumverweis": "4. OG",
        "NummerObjektteppich": "78",
        "Person": "Hans Trüb",
        "Lebensdaten": "1925",
        "Infotext": "Hier kommt noch Text."
    }
]